import Parse from "parse";


export const DataWebPages = async (token) => {
  try {
    return await Parse.Cloud.run("urlViewMyPages", {key: token});
  } catch (err) {
    console.log("[debug] Data Web Pages error : ", err);
  }
};

export const ConnectedDomains = async (token) => {
  try {
    return await Parse.Cloud.run("connectedDomains", {key: token});
  } catch (err) {
    console.log("[debug] Data Web Pages error : ", err);
  }
}

export const UpdateWebPages = async (sendPkg) => {
  try {
    return await Parse.Cloud.run("urlViewMyPages", sendPkg);
  } catch (err) {
    console.log("[debug] Update Web Pages error : ", err);
  }
};