export const getYYMMDD = (timestamp) => {
  const dateObj = new Date(timestamp);
  const year = dateObj.getFullYear().toString().slice(2);
  const month = dateObj.getMonth() + 1;
  const monthStr = month > 9 ? month : '0' + month;
  const date = dateObj.getDate();
  const dateStr = date > 9 ? date : '0' + date;
  return year + '-' + monthStr + '-' + dateStr;
}

export const getDateDisplay = (dateObj) => {
  const year = dateObj.getFullYear().toString().slice(2);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Map', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = dateObj.getMonth();
  const monthStr = months[month];
  const date = dateObj.getDate();
  const dateStr = date > 9 ? date : '0' + date;
  return monthStr + '-' + dateStr + '-' + year;
}