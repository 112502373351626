const init_state = {
    user: null,
    sessionToken: null,
    profile: null,
    profileImage: null,
    displayName: null,
    userId: null,
    roles: null
};

export const userReducer = (state = init_state, action = {}) => {
    switch (action.type) {
        case "LOGIN":
            return {
                ...state,
                user: action.payload.user,
                sessionToken: action.payload.sessionToken,
                profile: action.payload.profile,
                profileImage: action.payload.profileImage,
                displayName: action.payload.displayName,
                userId: action.payload.userId,
                roles: action.payload.roles
            };
        case "LOGOUT":
            return {
                ...state,
                user: null,
                sessionToken: null,
                profile: null,
                profileImage: null,
                displayName: null,
                userId: null,
                roles: null
            };
        default:
            return state;
    }
};
