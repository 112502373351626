import {
    Route,
    Redirect
} from 'react-router-dom';
import {useSelector} from "react-redux";

function PrivateRoute({ children, ...rest }) {
    const hasUserData = useSelector((state) => state.user.user);
    return (
        <Route
        {...rest}
        render={
            ({ location }) => (
                hasUserData
                ? (
                children
                ) : (
                <Redirect
                    to={"/"}
                />
                ))
        }
        />
    );
}
  
  export default PrivateRoute;

