import navRolesBase from "../../components/sidebar/nav-roles/nav-roles-base";

const init_state = {
  showError: false,
  errorMessage: '',
  navRoles: navRolesBase,
  activeNav: navRolesBase[0].path,
  accountRole: 'userRole'
};

export const UIReducer = (state = init_state, action = {}) => {
  switch (action.type) {
      case "SHOWERROR":
          return {
              ...state,
              showError: true,
              errorMessage: action.payload
          };
      case "HIDEERROR":
          return {
              ...state,
              showError: false,
              errorMessage: ''
          };
        case "SETNAVROLES":
            return {
                ...state,
                navRoles: action.payload
            }
        case "ACTIVENAV":
            return {
                ...state,
                activeNav: action.payload
            }
        case "ACCOUNTROLE":
            return {
                ...state,
                accountRole: action.payload
            }
      default:
          return state;
  }
};