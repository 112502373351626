/**
 * <h4>LoadScript</h4>
 * Dynamically loads JavaScript files with a callback when finished.
 * @param url The src of the script file
 * @param callback The function to handle the details of the call
 */
const loadScript = (url, callback) => {
    // Create a new js script to fill
    let script = document.createElement('script');
    script.type = 'text/javascript';

    // Check that the script is ready and loaded or complete - then call the callback
    if (script.readyState) {
        script.readyState = function () {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        // Keep trying to load the script until it is ready
        script.onload = () => callback();
    }

    // load the script by url, and attach it to the page
    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
}

export default loadScript;
