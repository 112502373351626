/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from "react";
import {FormControl, FormLabel, InputGroup} from "react-bootstrap";
import Autocomplete, {usePlacesWidget} from "react-google-autocomplete";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";


const AddressAutoInput = (props) => {
  const [valid, setValid] = useState(true);
  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey:process.env.REACT_APP_GOOGLE_API_KEY,
    options: {types: ["address"], strictBounds: false},
    onPlaceSelected: (place) => {
      console.log("Props Selected ==== ", place)
      checkAddressStr(place.formatted_address);
    }
  });

  const checkAddressStr = (value) => {
    if (props.onChange) {
      props.onChange(value);
    }
  }

  return (
    <FormControl
        type={"text"}
        className={"no-border fs-3"}
        name={"address"}
        ref={ref}
        placeholder={""}
        defaultValue={props.default}
        onChange={e => checkAddressStr(e.target.value)}
    />
  )
}

export default AddressAutoInput