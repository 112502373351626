import React, {useEffect, useRef, useState} from "react";
import { Container, Modal, Row, Col } from "react-bootstrap";
import { DataAffiliate } from './affiliates-util';
import {useSelector} from 'react-redux';
import Header from "../../header/header";
import { DateRangePicker } from 'react-date-range';
import {addDays} from 'date-fns';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './affiliates.css';
import { getYYMMDD, getDateDisplay } from '../../../utils/time-change';

const changeNumberString = (num) => {
  if (isNaN(num)) return '';
  if (num === Math.floor(num)) return num + '.00';
  if (num !== Math.floor(num)) return num + '';
}

const Affiliate = () => {
  const userData = useSelector((state) => state.user.user);
  const [showRange, setShowRange] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const [data, setData] = useState({});

  useEffect(() => {
    loadData();
  }, []);

  const updateDateRange = () => {
    const startDate = dateRange[0].startDate.getTime();
    const endDate = dateRange[0].endDate.getTime();
    loadData(startDate, endDate)
  }

  const loadData = (startDate, endDate) => {
    let param = {
      key: userData.sessionToken
    }
    if (startDate && endDate) {
      param = {
        ...param,
        startDate,
        endDate,
      }
    }
    DataAffiliate(param).then(r => {
      console.log("Result ==== ", r);
      setData(r);
      setDateRange([
        {
          startDate: new Date(r.startDate),
          endDate: new Date(r.endDate),
          key: 'selection'
        }
      ])
    })
  }
  return (
    <Container fluid className={"vh-100 max-limited-page"}>
      <Header roleShow/>
      <div className={"page-content-with-header"}>
        <Row className="p-3 mx-4">
          <Col>
            <div className="referrals-header">
              <div className="referrals-cell">DIRECT REFERRALS</div>
            </div>
            <div className="ref-body mt-3 d-flex flex-row">
              <div className="d-flex flex-column justify-content-center align-items-center referrals-cell">
                <div className="ref-value">{data?.direct?.referrals || 0}</div>
                <div className="ref-desc">REFERRALS</div>                
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center referrals-cell">
                <div className="ref-value">${changeNumberString(data?.direct?.revenue || 0)}</div>
                <div className="ref-desc">TOTAL REVENUE</div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center referrals-cell">
                <div className="ref-value">${changeNumberString(data?.direct?.commission || 0)}</div>
                <div className="ref-desc">MY COMMISSION</div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="referrals-header">
              <div className="referrals-cell">INDIRECT REFERRALS</div>
            </div>
            <div className="ref-body mt-3 d-flex flex-row">
              <div className="d-flex flex-column justify-content-center align-items-center referrals-cell">
                <div className="ref-value">{data?.indirect?.referrals || 0}</div>
                <div className="ref-desc">REFERRALS</div>                
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center referrals-cell">
                <div className="ref-value">${changeNumberString(data?.indirect?.revenue || 0)}</div>
                <div className="ref-desc">TOTAL REVENUE</div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center referrals-cell">
                <div className="ref-value">${changeNumberString(data?.indirect?.commission || 0)}</div>
                <div className="ref-desc">MY COMMISSION</div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="p-3 mx-4">
          <div className="referrals-header">
              <div className="referrals-cell">DATE</div>
              <div className="referrals-cell">REFERRAL ID</div>
              <div className="referrals-cell">REVENUE</div>
              <div className="referrals-cell">DESCRIPTION</div>
            </div>
        </Row>
        {
          data.referrals && data.referrals.length > 0 ?
          data.referrals.map((referral) => <Row>
            <Col className="table-cell"> {getYYMMDD(referral.date)}</Col>
            <Col className="table-cell"> {referral.id}</Col>
            <Col className="table-cell"> ${changeNumberString(referral.revenue)}</Col>
            <Col className="table-cell"> {referral.description}</Col>
          </Row>)
          :
          <Row>
            <Col className="table-cell text-center mt-3">No referrals yet</Col>
          </Row>
        }
      </div>
      <Modal
        show={showRange}
        centered
        onHide={() => {
          updateDateRange();
          setShowRange(false)
        }}
        size="lg"
      >
        <DateRangePicker
          onChange={item => {
            console.log("Date Range ==== ", item.selection.startDate);
            setDateRange([item.selection])
          }}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={dateRange}
          direction="horizontal"
        />;
      </Modal>
    </Container>
  )
}

export default Affiliate;