import React from 'react';

const Organization = () => {
  return (
    <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.1378 7.6468C18.5328 7.4508 19.6068 6.2548 19.6098 4.8058C19.6098 3.3778 18.5688 2.1938 17.2038 1.9698M18.9786 11.0004C20.3296 11.2024 21.2726 11.6754 21.2726 12.6504C21.2726 13.3214 20.8286 13.7574 20.1106 14.0314M5.1349 7.6468C3.7389 7.4508 2.6659 6.2548 2.6629 4.8058C2.6629 3.3778 3.7039 2.1938 5.0689 1.9698M3.294 11.0004C1.943 11.2024 1 11.6754 1 12.6504C1 13.3214 1.444 13.7574 2.162 14.0314M11.1368 11.4139C7.9228 11.4139 5.1778 11.9009 5.1778 13.8459C5.1778 15.7899 7.9058 16.2909 11.1368 16.2909C14.3508 16.2909 17.0948 15.8089 17.0948 13.8629C17.0948 11.9169 14.3678 11.4139 11.1368 11.4139ZM11.1368 8.638C13.2458 8.638 14.9558 6.929 14.9558 4.819C14.9558 2.71 13.2458 1 11.1368 1C9.0278 1 7.3178 2.71 7.3178 4.819C7.3098 6.921 9.0068 8.631 11.1088 8.638H11.1368Z" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default Organization;