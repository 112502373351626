import React from 'react';

class BusinessPage extends React.Component {
    render() {
        return (
            <div>
                <h1>Business Page</h1>
                <p>Main Dashboard for Businesses</p>
            </div>
        )
    }

}

export default BusinessPage;
