import Parse from "parse";
import {S3Upload} from "../../../utils/s3-upload";

const RegisterOrganizationUtil = async (data) => {
    try {
        const payload = {
            key: data.key,
            registeredBy: {
                workEmail: data.workEmail,
                workPhone: data.workPhone,
                title: Number(data.role) + 1,
            },
            registeredName: data.registerName,
            dbaName: data.doingBusinessAs,
            type: Number(data.typeOfOrganization) + 1,
            industry: Number(data.industry) + 1,
            websites: [data.websiteUrl],
            mainPhone: data.mainNumber,
            mainEmail: data.mainCompanyEmail,
            mailingAddress: {
                address1: data.mailingAddressFirst,
                address2: data.mailingAddressSecond,
                city: data.mailingAddressCity,
                state: data.mailingAddressState,
                zip: data.mailingAddressZip,
                country: data.mailingCountry,
            },
            billingAddress: {
                address1: data.physicalAddressFirst,
                address2: data.physicalAddressSecond,
                city: data.physicalAddressCity,
                state: data.physicalAddressState,
                zip: data.physicalAddressZip,
                country: data.physicalCountry,
            },
            annualRevenue: Number(data.annualRevenue) + 1,
            numEmployees: Number(data.numberOfEmployees) + 1,
            numCustomers: Number(data.numberOfUsers) + 1,
            numLocations: Number(data.numberOfPhysicalLocation) + 1,
            comments: data.comment,
        };
        //Registration Organization using Filed data
        const registration = await Parse.Cloud.run("registerOrganization", payload, data);

        const orgId = registration.orgId;

        //Uploading Images
        const names = data.uploadFiles.map((item) => item.name);
        const urls = await Parse.Cloud.run("requestUploadKeyOrg", {
            orgId: orgId,
            credential: [...names],
            key: data.key
            // "profile": ["testdocument.jpg", "testDoc2.png", "testDoc3.pdf"]
        });

        // eslint-disable-next-line no-unused-vars
        const {credential, profile} = urls;
        const temp = credential.map(async (item, key) => {
            const res = await S3Upload(item, data.uploadFiles[key]);
            if (res.success) {
                return 1;
            }
        });
        function checkTempMatch() {
            if (temp.length !== credential.length) {
                throw new Error("File Upload Error");
            }
        }
        checkTempMatch();
        const signedUrls = credential.map((item) => {
            return {
                signedUrl: item,
            };
        });

        const registers3UploadResult = await Parse.Cloud.run(
            "registerS3UploadResultsOrg",
            {
                orgId: orgId,
                urls: [...signedUrls],
                key: data.key
            }
        );
        if (registers3UploadResult.success) {
            alert("Registration Successful!");
        } else {
            alert("Something went wrong please try again later!");
        }
    } catch (err) {
        throw err;
    }
};

export default RegisterOrganizationUtil;
