
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router";

const Tab = (component) => {
    let tab = <component/>
    return (
        tab
    )
}

function UserAdminPage() {
    // eslint-disable-next-line no-unused-vars
    const userData = useSelector((state) => state.user.user);
    const [selectedTab, setTab] = useState(<p>Default Window</p>);
    // eslint-disable-next-line no-unused-vars
    const BusySpinnerState = useSelector((state) => state.BusySpinner.value);
    const history = useHistory();


    return (

        <div className="container-fluid fullPage">
            <div className={"mgs_logo-container"}><h1 style={{color: "white"}}>Admin Dashboard</h1></div>
            <button className={"btn btn-outline-success add-employee-btn m-auto btn-lg"} onClick={e => setTab("VerifyIDPage")}><span className={"fs-3 align-self-center"} onClick={e => history.push('/verify-ids')}>Verify ID's</span></button>
            <button className={"btn btn-outline-success add-employee-btn m-auto btn-lg"} onClick={e => setTab("A")}><span className={"fs-3 align-self-center"}>A</span></button>
            <button className={"btn btn-outline-success add-employee-btn m-auto btn-lg"}><span className={"fs-3 align-self-center"}>B</span></button>
            <button className={"btn btn-outline-success add-employee-btn m-auto btn-lg"}><span className={"fs-3 align-self-center"}>C</span></button>
            <button className={"btn btn-outline-success add-employee-btn m-auto btn-lg"}><span className={"fs-3 align-self-center"}>D</span></button>
            <button className={"btn btn-outline-success add-employee-btn m-auto btn-lg"}><span className={"fs-3 align-self-center"}>E</span></button>
            <Tab component={selectedTab.value}/>
        </div>
    );
}

export default UserAdminPage;
