import React, {useEffect, useRef, useState} from "react";
import Parse from 'parse';
import {useDispatch, useSelector} from "react-redux";
import RegistrationOrganizationUtil from "./company-profile-util";
import {HideBusy, ShowBusy} from "../../../redux/busySpinner/spinner-actions";
import { Container } from "react-bootstrap";
import "./company-profile.css";
import PhoneInput from 'react-phone-input-2';
import ServerImg from "../../common/server-img";
import Header from "../../header/header";


const CompanyProfilePage = () => {
    // eslint-disable-next-line no-unused-vars
    const [user, setUser] = useState(useSelector((state) => state.user.user));
    const [Industry, setIndustry] = useState({});
    const [NumOfUsers, setNumOfUsers] = useState({});
    const [NumOfEmployees, setNumOfEmployees] = useState({});
    const [AnnualRevenue, setAnnualRevenue] = useState({});
    const [NumOfPhysicalLocation, setNumOfPhysicalLocation] = useState({});
    const [OrgFileTypes, setOrgFileTypes] = useState({});
    const [Roles, setRoles] = useState({});
    const [OrganizationTypes, setOrganizationTypes] = useState({});
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const logoRef = useRef(null);
    const [logoFile, setLogoFile] = useState();
    const [logoPreview, setLogoPreview] = useState()


    const [values, setValues] = useState({
        userName: "",
        workEmail: "",
        role: "",
        registerName: "",
        doingBusinessAs: "",
        typeOfOrganization: "",
        industry: "",
        websiteUrl: "",

        workPhone: "",
        logo: "",

        sameAsMailingAddress: false,
        mainNumber: "",
        mainCompanyEmail: "",
        mailingAddressFirst: "",
        mailingAddressSecond: "",
        mailingAddressCity: "",
        mailingAddressZip: "",
        mailingAddressState: "",
        mailingCountry: "",

        physicalAddressFirst: "",
        physicalAddressSecond: "",
        physicalAddressCity: "",
        physicalAddressZip: "",
        physicalAddressState: "",
        physicalCountry: "",

        annualRevenue: "",
        numberOfEmployees: "",
        numberOfUsers: "",
        numberOfPhysicalLocation: "",
        comment: "",

        uploadFiles: [],
    });

/////////////////////////////////
// add state for error handling
/////////////////////////////////
    const [error, setError] = useState({

        workEmail: "",
        workPhone: "",
        role: "",
        registerName: "",
        doingBusinessAs: "",
        typeOfOrganization: "",
        industry: "",
        websiteUrl: "",
        mainNumber: "",
        mainCompanyEmail: "",
        mailingAddressFirst: "",
        mailingAddressSecond: "",
        mailingCountry: "",
        mailingAddressCity: "",
        mailingAddressState: "",
        mailingAddressZip: "",
        physicalAddressFirst: "",
        physicalAddressSecond: "",
        physicalCountry: "",
        physicalAddressCity: "",
        physicalAddressState: "",
        physicalAddressZip: "",
        annualRevenue: "",
        numberOfEmployees: "",
        numberOfUsers: "",
        numberOfPhysicalLocation: "",
        uploadFiles: "",
    });

    useEffect(() => {
        setValues({
            ...values,
            industry: Object.keys(Industry).length ? "0" : undefined,
            annualRevenue: Object.keys(Industry).length ? "0" : undefined,
            numberOfEmployees: Object.keys(Industry).length ? "0" : undefined,
            numberOfPhysicalLocation: Object.keys(Industry).length ? "0" : undefined,
            typeOfOrganization: Object.keys(Industry).length ? "0" : undefined,
            numberOfUsers: Object.keys(Industry).length ? "0" : undefined,
            role: Object.keys(Roles).length ? "0" : undefined,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Industry, NumOfUsers, NumOfEmployees, AnnualRevenue, NumOfPhysicalLocation, OrgFileTypes, Roles, OrganizationTypes]);

    /**
     *
     * Failed to load resource: the server responded with a status of 400 (Bad Request) at getTextLists async
     * TODO: [debug] Data Populating error -
     Error: Cannot read property 'success' of undefined at handleError (http://localhost:3000/static/js/vendors~main.chunk.js:75060:17) at async DataPopulating (http://localhost:3000/static/js/main.chunk.js:1871:19) {message: "Cannot read property 'success' of undefined", code: 141, stack: "Error: Cannot read property 'success' of undefined…//localhost:3000/static/js/main.chunk.js:1871:19)"}
     message = "Cannot read property 'success' of undefined"
     code = 141
     stack = "Error: Cannot read property 'success' of undefined\n at handleError (http://localhost:3000/static/js/vendors~main.chunk.js:75060:17)\n at async DataPopulating (http://localhost:3000/static/js/main.chunk.js:1871:19)"
     */
    const DataPopulating = async () => {
        try {
            const res = await Parse.Cloud.run("getTextLists", {
                list_names: [
                    "OrgIndustries",
                    "OrgNumOfCustomers",
                    "OrgNumOfEmployees",
                    "OrgRevenueTypes",
                    "OrgNumOfLocations",
                    "OrgFileTypes",
                    "OrgTypes",
                    "OrgRoleTypes",
                ], key: user.sessionToken,
            }, {});
            setIndustry({...res.OrgIndustries});
            setNumOfUsers({...res.OrgNumOfCustomers});
            setNumOfEmployees(res.OrgNumOfEmployees);
            setAnnualRevenue({...res.OrgRevenueTypes});
            setNumOfPhysicalLocation(res.OrgNumOfLocations);
            setOrgFileTypes({...res.OrgFileTypes});
            setOrganizationTypes({...res.OrgTypes});
            setRoles({...res.OrgRoleTypes});
            //Updating values
            setLoading(false);
        } catch (err) {
            console.log("[debug] Data Populating error : ", err);
        }
    };

    const handleChange = (e) => {
        if (e.preventDefault) {
            e.preventDefault();
        }
        setValues({...values, [e.target.name]: e.target.value});
        setError({...error, [e.target.name]: ""});  // set error state empty when onchange trigger on that field
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // adding validations
        if (values.workEmail === "") {
            setError((pre) => ({...pre, workEmail: 'Please enter work email!'}))
        } else if (!/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(values.workEmail)) {
            setError((pre) => ({...pre, workEmail: "Please enter valid email!"}))
        } else if (values.workPhone === "") {
            setError((pre) => ({...pre, workPhone: "Please enter work phone!"}))
        } else if (values.role === "") {
            setError((pre) => ({...pre, role: "Please select role!"}))
        } else if (values.registerName === "") {
            setError((pre) => ({...pre, registerName: "Please enter register name!"}))
        } else if (values.doingBusinessAs === "") {
            setError((pre) => ({...pre, doingBusinessAs: "Please enter doing business as!"}))
        } else if (values.typeOfOrganization === "") {
            setError((pre) => ({...pre, typeOfOrganization: "Please select type of organization!"}))
        } else if (values.industry === "") {
            setError((pre) => ({...pre, industry: "Please select industry!"}))
        } else if (values.websiteUrl === "") {
            setError((pre) => ({...pre, websiteUrl: "Please enter website url!"}))
        } else if (values.mainNumber === "") {
            setError((pre) => ({...pre, mainNumber: "Please enter main number!"}))
        } else if (values.mainCompanyEmail === "") {
            setError((pre) => ({...pre, mainCompanyEmail: "Please enter main company mail!"}))
        } else if (values.mailingAddressFirst === "") {
            setError((pre) => ({...pre, mailingAddressFirst: "Please enter address!"}))
        } else if (values.mailingAddressSecond === "") {
            setError((pre) => ({...pre, mailingAddressSecond: "Please enter address!"}))
        } else if (values.mailingCountry === "") {
            setError((pre) => ({...pre, mailingCountry: "Please enter country!"}))
        } else if (values.mailingAddressCity === "") {
            setError((pre) => ({...pre, mailingAddressCity: "Please enter city!"}))
        } else if (values.mailingAddressState === "") {
            setError((pre) => ({...pre, mailingAddressState: "Please enter state!"}))
        } else if (values.mailingAddressZip === "") {
            setError((pre) => ({...pre, mailingAddressZip: "Please enter zipcode!"}))
        } else if (values.physicalAddressFirst === "") {
            setError((pre) => ({...pre, physicalAddressFirst: "Please enter address!"}))
        } else if (values.physicalAddressSecond === "") {
            setError((pre) => ({...pre, physicalAddressSecond: "Please enter address!"}))
        } else if (values.physicalCountry === "") {
            setError((pre) => ({...pre, physicalCountry: "Please enter country!"}))
        } else if (values.physicalAddressCity === "") {
            setError((pre) => ({...pre, physicalAddressCity: "Please enter city!"}))
        } else if (values.physicalAddressState === "") {
            setError((pre) => ({...pre, physicalAddressState: "Please enter state!"}))
        } else if (values.physicalAddressZip === "") {
            setError((pre) => ({...pre, physicalAddressZip: "Please enter zipcode!"}))
        } else if (values.annualRevenue === "") {
            setError((pre) => ({...pre, annualRevenue: "Please enter select annual revenue!"}))
        } else if (values.numberOfEmployees === "") {
            setError((pre) => ({...pre, numberOfEmployees: "Please select number of employees!"}))
        } else if (values.numberOfUsers === "") {
            setError((pre) => ({...pre, numberOfUsers: "Please select number of users!"}))
        } else if (values.numberOfPhysicalLocation === "") {
            setError((pre) => ({...pre, numberOfPhysicalLocation: "Please select no. of locations!"}))
        } else if (values.uploadFiles.length < 3) {
            setError((pre) => ({...pre, uploadFiles: "Please upload at least three document!"}))
        } else {
            RegistrationOrganizationUtil({
                ...values, key: user.sessionToken}).then(r => {
            });
        }
    };

    // Form Validation
    // eslint-disable-next-line no-unused-vars
    const phoneValidation = (e) => {
        e.preventDefault();
        const phone = e.target.value;
        if (e.key === 8) {
            return;
        } else if (!Number(phone) && String(phone) && !phone.includes("-")) {
            return;
        }
        if (e.target.value.length === 3 || e.target.value.length === 7) {
            setValues({...values, [e.target.name]: (e.target.value += "-")});
        } else {
            setValues({...values, [e.target.name]: e.target.value});
        }
    };

    const zipCodeValidation = (e) => {
        const phone = e.target.value;
        if (e.keyCode === 8) {
            return false;
        } else if (!Number(phone)) {

        } else {
            e.preventDefault();
            setValues({...values, [e.target.name]: e.target.value});
            setError({...error, [e.target.name]: ""});  // set error state empty when onchange trigger on that field

        }
    };

    const duplicateMailingAddress = () => {
        setValues({
            ...values,
            physicalAddressFirst: values.mailingAddressFirst,
            physicalAddressSecond: values.mailingAddressSecond,
            physicalAddressCity: values.mailingAddressCity,
            physicalAddressState: values.mailingAddressState,
            physicalAddressZip: values.mailingAddressZip,
            physicalCountry: values.mailingCountry,
        });
    };

    const handleSameAsMailingAddress = (e) => {
        setValues({...values, sameAsMailingAddress: e.target.checked});
    };

    const handleFileUploads = (e) => {
        e.preventDefault();
        setError((...pre) => ({...pre, uploadFiles: ""}));  // set error state empty when onchange trigger on that field
        const initialFilesLength = values.uploadFiles.length;
        let filesLength = e.target.files.length;
        if (filesLength + initialFilesLength > 5) {
            alert("Cannot upload more than 5 file");
        } else {
            setValues({
                ...values,
                uploadFiles: [...values.uploadFiles, ...e.target.files],
            });

            /*  Object.keys(e.target.files).map((item) => {
               setValues({ ...values, uploadFiles: [...values.uploadFiles, e.target.files[item] ] });
             }); */
        }
    };
    const handleFileDelete = (fileName) => {
        setValues({
            ...values,
            uploadFiles: values.uploadFiles.filter((item) => item.name !== fileName),
        });
    };


    useEffect(() => {
        DataPopulating().then(r => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (values.sameAsMailingAddress) {
            duplicateMailingAddress();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        values.mailingAddressFirst,
        values.mailingAddressSecond,
        values.mailingAddressCity,
        values.mailingAddressState,
        values.mailingAddressZip,
        values.sameAsMailingAddress]);

    useEffect(() => {
        if (values.sameAsMailingAddress) {
            duplicateMailingAddress();
        } else {
            setValues({
                ...values,
                physicalAddressFirst: "",
                physicalAddressSecond: "",
                physicalAddressCity: "",
                physicalAddressState: "",
                physicalAddressZip: "",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.sameAsMailingAddress]);

    useEffect(() => {
        if (!logoFile) {
            setLogoPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(logoFile)
        setLogoPreview(objectUrl)
        setValues({
            ...values,
            logo: objectUrl
        });
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoFile]);

    const handleLogoUpload = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setLogoFile(undefined)
            return
        }
        setLogoFile(e.target.files[0])
    }

    return (
        <Container fluid className={"vh-100 max-limited-page"}>
            <Header roleShow />
            <div className="page-content-with-header">
                <div className="page-title">
                    Register an organization
                </div>
                <form onSubmit={handleSubmit} className="form-info">
                    <div className="d-flex flex-row">
                        <article className="flex-1">
                            <h2 className="subtitle-1">What is your role in organization?</h2>
                            <div className="your-role__info">
                                <div className="d-flex flex-row justify-content-start align-items-center">
                                    <div className="your-role__info-img d-flex flex-column align-items-start">
                                        {/*TODO: Show alternate png if user.selfie fails to show*/}
                                        <ServerImg style={{
                                                height: "95px",
                                                objectFit: "contain"
                                            }}
                                            src={
                                                // user.selfie && user.profileImage.length
                                                user.selfie
                                                    ? user.selfie
                                                    : "https://via.placeholder.com/95x95.png?"
                                            }
                                            alt={user.name}
                                            cacheKey="selfie-img"
                                        />
                                        <span><i>{user.name}</i></span>
                                    </div>
                                    <div className="your-role__info-inputs ms-4">
                                        <h4 className="mt-3">
                                            <b>Role:</b>
                                            

                                        </h4>
                                        <select
                                            className="form-control data-input input-control"
                                            name="role"
                                            id="role-in-organization__select"
                                            onChange={handleChange}
                                            // value={Roles[values.role]}
                                        >
                                            <option disabled defaultValue={true}>
                                                Select Role
                                            </option>
                                            {Object.keys(Roles).map((item, key) => (
                                                <option value={key} key={key}>
                                                    {Roles[item]}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="error-text">{error.role}</span>
                                        <h4 className="mt-3">
                                            <b>Work Email:</b>
                                        </h4>
                                        <div className="form-group mb-2">
                                            <input
                                                type="email"
                                                name="workEmail"
                                                onChange={handleChange}
                                                value={values.workEmail}
                                                className="form-control data-input input-control"
                                                placeholder="Work Email (if different)"
                                            />
                                            <span className="error-text">{error.workEmail}</span>
                                        </div>
                                        <h4 className="mt-3">
                                            <b>Work Phone Number:</b>
                                        </h4>
                                        <div className="form-group">
                                            <PhoneInput 
                                                country={'us'}
                                                value={values.workPhone}
                                                placeholder="Work Phone: XXX - XXX - XXXX"
                                                onChange={(val) => {
                                                    console.log("Phone Input change ==== ", val);
                                                    handleChange({
                                                        target: {
                                                            name: 'workPhone',
                                                            value: '+' + val.toString(),
                                                        }
                                                    })
                                                }}
                                                containerClass={'data-input'}
                                                inputClass={'phone-input cp-phone-input'}
                                            />
                                            {/* <input
                                                type="text"
                                                name="workPhone"
                                                onChange={handleChange}
                                                value={values.workPhone}
                                                className="form-control data-input"
                                                minLength="12"
                                                maxLength="12"
                                                placeholder="Work Phone: XXX - XXX - XXXX"
                                                //reuired
                                            /> */}
                                            <span className="error-text">{error.workPhone}</span>
                                        </div>

                                        
                                    </div>
                                </div>
                                
                                
                            </div>
                        </article>
                        <article className="flex-1">
                            <h2 className="subtitle-1">Upload your Business Logo</h2>
                            <div className="your-role__info mt-5">
                                <div className="d-flex flex-row justify-content-start align-items-center">
                                    <div className="your-role__info-img d-flex flex-column align-items-start">
                                        {/*TODO: Show alternate png if user.selfie fails to show*/}
                                        <img
                                            style={{
                                                height: "95px",
                                                objectFit: "contain"
                                            }}
                                            src={
                                                // user.selfie && user.profileImage.length
                                                logoPreview
                                                    ? logoPreview
                                                    : "https://via.placeholder.com/95x95.png?"
                                            }
                                            alt={user.name}
                                        />
                                    </div>
                                    <div className="your-role__info-inputs ms-5">
                                        <div className="upload-files">
                                            <input
                                                id="selectedLogo"
                                                type="file"
                                                onChange={handleLogoUpload}
                                                style={{display: 'none'}}
                                            />
                                            <input type="button" value="Choose Logo..." onClick={() => {document.getElementById('selectedLogo').click()}}/>
                                        </div>
                                    </div>
                                </div>
                                
                                
                            </div>
                        </article>
                    </div>

                    <article className="organization-credentials pt-5">
                        <h2 className="subtitle-1">Organization's Credentials</h2>
                        <div className="row">
                            <div className="col-12">
                                <label className="group-input-item">
                                    <span className="group-input-title">Registered Name:</span>
                                    <input
                                        type="text"
                                        placeholder="Registered Name"
                                        className="form-control input-control"
                                        name="registerName"
                                        maxLength="25"
                                        onChange={handleChange}
                                        value={values.registerName}
                                    />
                                    <span className="error-text">{error.registerName}</span>
                                </label>

                                <label className="group-input-item">
                                    <span className="group-input-title">Doing Business As <i>(optional)</i></span>
                                    <input
                                        type="text"
                                        className="form-control input-control"
                                        name="doingBusinessAs"
                                        onChange={handleChange}
                                        value={values.doingBusinessAs}
                                        maxLength="25"
                                    />
                                    <span className="error-text">{error.doingBusinessAs}</span>

                                </label>

                                <label className="group-input-item">
                                    <span className="group-input-title">Type of Organization:</span>
                                    <select
                                        name="typeOfOrganization"
                                        id="type-of-organization__select"
                                        className="form-control input-control"
                                        value={values.typeOfOrganization}
                                        onChange={handleChange}
                                    >

                                        {Object.keys(OrganizationTypes).map((item, key) => (
                                            <option key={key} value={item}>{OrganizationTypes[item]}</option>
                                        ))}
                                    </select>
                                    <span className="error-text">{error.typeOfOrganization}</span>

                                </label>
                                <label className="group-input-item">
                                    <span className="group-input-title">Industry:</span>
                                    <select
                                        name="industry"
                                        id="industry__select"
                                        className="form-control input-control"
                                        onChange={handleChange}
                                        value={values.industry}
                                    >

                                        {Object.keys(Industry).map((item, key) => (
                                            <option key={key} value={item}>{Industry[item]}</option>
                                        ))}
                                    </select>
                                    <span className="error-text">{error.industry}</span>

                                </label>
                                <label className="group-input-item">
                                    <span className="group-input-title">Website URL:</span>
                                    <input
                                        type="text"
                                        className="form-control input-control"
                                        name="websiteUrl"
                                        placeholder="example.com"
                                        onChange={handleChange}
                                        value={values.websiteUrl}
                                    />
                                    <span className="error-text">{error.websiteUrl}</span>

                                </label>
                                <label className="group-input-item">
                                    <span className="group-input-title">Main Phone Number:</span>
                                    <PhoneInput 
                                        country={'us'}
                                        value={values.mainNumber}
                                        placeholder="Main Phone: XXX - XXX - XXXX"
                                        onChange={(val) => {
                                            handleChange({
                                                target: {
                                                    name: 'mainNumber',
                                                    value: '+' + val.toString(),
                                                }
                                            })
                                        }}
                                        containerClass={'data-input'}
                                        inputClass={'phone-input group-phone-input'}
                                    />
                                    {/* <input
                                        type="text"
                                        className="form-control"
                                        name="mainNumber"
                                        placeholder="Main Phone: XXX - XXX - XXXX"
                                        minLength="12"
                                        onChange={handleChange}
                                        value={values.mainNumber}
                                        maxLength="12"
                                    /> */}
                                    <span className="error-text">{error.mainNumber}</span>

                                </label>
                                <label className="group-input-item">
                                    <span className="group-input-title">Main Company Email:</span>
                                    <input
                                        type="email"
                                        className="form-control input-control"
                                        name="mainCompanyEmail"
                                        placeholder="example@gmail.com"
                                        maxLength="25"
                                        onChange={handleChange}
                                        value={values.mainCompanyEmail}
                                    />
                                    <span className="error-text">{error.mainCompanyEmail}</span>

                                </label>
                            </div>
                            {/* mailing address */}
                            <div className="col-6 pt-5">
                                <h2 className="subtitle-1">Mailing Address</h2>
                                <div className="mailing-address__inputs form-card">
                                    <div className="address__inputs-wrap">
                                        <label className="group-input-item">
                                            <input
                                                type="text"
                                                className="form-control input-control"
                                                name="mailingAddressFirst"
                                                placeholder="Address 1"
                                                onChange={handleChange}
                                                value={values.mailingAddressFirst}
                                            />
                                            <span className="error-text">{error.mailingAddressFirst}</span>
                                        </label>
                                        <label className="group-input-item">
                                            <input
                                                type="text"
                                                className="form-control input-control"
                                                name="mailingAddressSecond"
                                                onChange={handleChange}
                                                value={values.mailingAddressSecond}
                                                placeholder="Address 2"
                                            />
                                            <span className="error-text">{error.mailingAddressSecond}</span>

                                        </label>
                                        <label className="group-input-item">
                                                <input
                                                    type="text"
                                                    className="form-control input-control"
                                                    placeholder="City"
                                                    name="mailingAddressCity"
                                                    onChange={handleChange}
                                                    value={values.mailingAddressCity}
                                                />
                                                <span className="error-text">{error.mailingAddressCity}</span>

                                            </label>
                                        <div className="d-flex inputs-row">
                                            

                                            <label className="group-input-item">
                                                <input
                                                    type="text"
                                                    className="form-control zip-number-input input-control"
                                                    placeholder="State"
                                                    maxLength="6"
                                                    name="mailingAddressState"
                                                    onChange={handleChange}
                                                    value={values.mailingAddressState}
                                                />
                                                <span className="error-text">{error.mailingAddressState}</span>
                                            </label>


                                            <label className="group-input-item">
                                                <input
                                                    maxLength="6"
                                                    type="text"
                                                    name="mailingAddressZip"
                                                    className="form-control zip-number-input input-control"
                                                    placeholder="Zip"
                                                    onChange={zipCodeValidation}
                                                    value={values.mailingAddressZip}
                                                />
                                                <span className="error-text">{error.mailingAddressZip}</span>

                                            </label>
                                            <label className="group-input-item">
                                                <input
                                                    type="text"
                                                    className="form-control input-control"
                                                    name="mailingCountry"
                                                    onChange={handleChange}
                                                    value={values.mailingCountry}
                                                    placeholder="country"
                                                />
                                                <span className="error-text">{error.mailingCountry}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* physical address */}
                            <div className="col-6 pt-5">
                                <div className="physical-address__inputs form-card" style={{display: "inline-block"}}>
                                    <div className="d-flex flex-row justify-content-between">
                                        <h2 className="subtitle-1">
                                            Physical Address:
                                        </h2>
                                        
                                        <label className="mb-0 same-as-emailing-address d-flex flex-row align-items-center">
                                            <span>Same as Mailing Address</span>
                                            <input
                                                type="checkbox"
                                                name="sameAsMailingAddress"
                                                className="ms-2"
                                                onChange={handleSameAsMailingAddress}
                                                value={values.sameAsMailingAddress}
                                            />
                                        </label>
                                    </div>
                                    <div className="address__inputs-wrap">
                                        <label className="group-input-item">
                                            <input
                                                type="text"
                                                className="form-control input-control"
                                                name="physicalAddressFirst"
                                                onChange={handleChange}
                                                value={values.physicalAddressFirst}
                                                disabled={values.sameAsMailingAddress}
                                                placeholder="Address 1"
                                            />
                                            <span className="error-text">{error.physicalAddressFirst}</span>

                                        </label>
                                        <label className="group-input-item">
                                            <input
                                                type="text"
                                                className="form-control input-control"
                                                name="physicalAddressSecond"
                                                placeholder="Address 2"
                                                onChange={handleChange}
                                                disabled={values.sameAsMailingAddress}
                                                value={values.physicalAddressSecond}
                                            />
                                            <span className="error-text">{error.physicalAddressSecond}</span>

                                        </label>
                                        <label className="group-input-item">
                                            <input
                                                type="text"
                                                className="form-control input-control"
                                                placeholder="City"
                                                name="physicalAddressCity"
                                                onChange={handleChange}
                                                disabled={values.sameAsMailingAddress}
                                                value={values.physicalAddressCity}
                                            />
                                            <span className="error-text">{error.physicalAddressCity}</span>

                                        </label>
                                        
                                        <div className="d-flex inputs-row">
                                            
                                            <label className="group-input-item">
                                                <input
                                                    type="text"
                                                    className="form-control zip-number-input input-control"
                                                    placeholder="State"
                                                    maxLength="6"
                                                    name="physicalAddressState"
                                                    onChange={handleChange}
                                                    disabled={values.sameAsMailingAddress}
                                                    value={values.physicalAddressState}
                                                />
                                                <span className="error-text">{error.physicalAddressState}</span>

                                            </label>
                                            <label className="group-input-item">
                                                <input
                                                    type="text"
                                                    name="physicalAddressZip"
                                                    className="form-control zip-number-input input-control"
                                                    placeholder="Zip"
                                                    maxLength="6"
                                                    onChange={zipCodeValidation}
                                                    value={values.physicalAddressZip}
                                                    disabled={values.sameAsMailingAddress}
                                                />
                                                <span className="error-text">{error.physicalAddressZip}</span>

                                            </label>
                                            <label className="group-input-item">
                                                <input
                                                    type="text"
                                                    className="form-control input-control"
                                                    name="physicalCountry"
                                                    onChange={handleChange}
                                                    disabled={values.sameAsMailingAddress}
                                                    value={values.physicalCountry}
                                                    placeholder="country"
                                                />
                                                <span className="error-text">{error.physicalCountry}</span>

                                            </label>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>

                        <article className="organization-operations pt-5">
                            <h2 className="subtitle-1">Organization's Operations</h2>
                            <div className="organization-operations__inputs form-card">
                                <div className="inputs-column">
                                    <label className="group-input-item">
                                        <span className="group-input-title">Annual Revenue:</span>
                                        <select
                                            name="annualRevenue"
                                            id="annual-revenue__select"
                                            className="form-control input-control"
                                            onChange={handleChange}
                                            value={values.annualRevenue}
                                        >
                                            {Object.keys(AnnualRevenue).map((item, key) => (
                                                <option key={key} value={item}>
                                                    {AnnualRevenue[item]}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="error-text">{error.annualRevenue}</span>

                                    </label>
                                    <label className="group-input-item">
                                        <span className="group-input-title">Number of Employees:</span>
                                        <select
                                            name="numberOfEmployees"
                                            id="number-of-employees__select"
                                            className="form-control input-control"
                                            onChange={handleChange}
                                            value={values.numberOfEmployees}
                                        >
                                            {Object.keys(NumOfEmployees).map((item, key) => (
                                                <option key={key} value={item}>
                                                    {NumOfEmployees[item]}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="error-text">{error.numberOfEmployees}</span>

                                    </label>
                                    <label className="group-input-item">
                                        <span className="group-input-title">Number of Customers/Users:</span>
                                        <select
                                            name="numberOfUsers"
                                            id="number-of-customers__select"
                                            className="form-control input-control"
                                            onChange={handleChange}
                                            value={values.numberOfUsers}
                                        >
                                            {Object.keys(NumOfUsers).map((item, key) => (
                                                <option key={key} value={item}>
                                                    {NumOfUsers[item]}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="error-text">{error.numberOfUsers}</span>

                                    </label>
                                    <label className="group-input-item">
                                        <span className="group-input-title">Number of Physical Locations:</span>
                                        <select
                                            name="numberOfPhysicalLocation"
                                            id="number-of-physical-locations__select"
                                            className="form-control input-control"
                                            onChange={handleChange}
                                            value={values.numberOfPhysicalLocation}
                                        >
                                            {Object.keys(NumOfPhysicalLocation).map((item, key) => (
                                                <option key={key} value={item}>
                                                    {NumOfPhysicalLocation[item]}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="error-text">{error.numberOfPhysicalLocation}</span>

                                    </label>
                                </div>
                            </div>
                        </article>

                        <article className="verify-your-organization pt-5">
                            <h2 className="subtitle-1">Verify Your Organization</h2>
                            <div className="form-card">
                                <div className="row">
                                    <div className="col-6" style={{fontSize: '15px'}}>
                                        <b>
                                            To verify your organization's authenticity, please upload
                                            <br/> <u>at least 3</u> of the following documents:
                                        </b>
                                        <ul className="//reuired-files-list">

                                            {Object.keys(OrgFileTypes).map((item, key) => (
                                                <li key={key}>{OrgFileTypes[item]}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="col-6">
                                        <div className="upload-files">
                                            <div className="upload-area">
                                                <input
                                                    id="selectedFile"
                                                    type="file"
                                                    multiple
                                                    onChange={handleFileUploads}
                                                    style={{display: 'none'}}
                                                />
                                                <input type="button" value="Choose Files..." onClick={() => {document.getElementById('selectedFile').click()}}/>
                                            </div>
                                        </div>
                                        <div className="uploaded-files">
                                            <div className="d-flex uploaded-files__heading">
                                                <span>These files are queued for upload:</span>
                                                {/* <span>Delete</span> */}
                                            </div>
                                            <div className="uploaded-files__list">
                                                {values.uploadFiles.length
                                                    ? values.uploadFiles.map((item, key) => (
                                                        <li onClick={() => handleFileDelete(item.name)}
                                                            className="uploaded-files__file" key={key}>
                                                            <span className="uploaded-files__file-name">
                                                                {item.name}
                                                            </span>
                                                            <div className="uploaded-files__file-delete-btn">
                                                            </div>
                                                        </li>
                                                    ))
                                                    : null}
                                            </div>
                                        </div>
                                        <span className="error-text">{error.uploadFiles}</span>

                                    </div>
                                </div>
                            </div>
                        </article>

                        <div className="d-flex justify-content-start mt-5">
                            <button className="btn btn-success submit-btn page-submit">
                                Update Profile
                            </button>
                        </div>
                </form>
            </div>
            
        </Container>
    );
};

export default CompanyProfilePage;
