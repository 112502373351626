import { Container } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import Header from "../../header/header";
import "./business-applications.css"
import Parse from 'parse';
import { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {HideBusy, ShowBusy} from "../../../redux/busySpinner/spinner-actions";
import { getDateDisplay } from '../../../utils/time-change'
import {useHistory} from 'react-router-dom';

const BusinessApplication = () => {
  const userData = useSelector((state) => state.user.user);
  const [applicationData, setApplicationData] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const getApplications = async () => {
    dispatch(ShowBusy())
    try {
      const res = await Parse.Cloud.run("getOrgApplicationStatus", {key: userData.sessionToken}, {});
      if (res.success === 1) {
        setApplicationData(res.data)
      }
    } catch {
    }
    dispatch(HideBusy())
  }

  useEffect(() => {
    getApplications()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getStatusString = (status) => {
    switch (status) {
      case 'submitted':
        return 'Submitted';
      case 'inReview':
          return 'In Review';
      case 'approved':
          return 'Approved';
      default:
        return 'Submitted';
    }
  }

  const navigateToRegister = () => {
    history.push('/register/business')
  }

  return (
    <Container fluid className={"vh-100"}>
      <Header title="testHeader" roleShow/>
      <div className="pt-80">
        <div className="bapp-content-wrapper">
          <div className="bapp-page-title">Applications</div>
          <div className="bapp-table-wrapper">
            { applicationData.length > 0 && (
              <Table borderless hover size="sm">
                <thead>
                  <tr className="bapp-table-header">
                    <th>Emails</th>
                    <th>DATE</th>
                    <th>STATUS</th>
                    <th>&#8212;</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    applicationData.map((data) => (
                      <tr className="bapp-table-body-row">
                        <td>
                          <img className="bapp-logo" src={data.orgLogo} alt="hello" onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src="https://grape-public-files.s3.us-west-2.amazonaws.com/images/default-logo-2.png"
                          }}/>
                          <span className="bapp-orgname">{data.orgName}</span>
                        </td>
                        <td>{getDateDisplay(new Date(data.applicationDate))}</td>
                        <td><div className={`status ${data.status}`}>{getStatusString(data.status)}</div></td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            )}
          </div>
          <div className="bapp-button-wrapper">
            <div className="bapp-button" onClick={navigateToRegister}>Register Business</div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default BusinessApplication