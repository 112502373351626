import React, { useState } from "react";
import navRolesBase from "../../sidebar/nav-roles/nav-roles-base";
import navRolesBusiness from "../../sidebar/nav-roles/nav-roles-business";
import navRolesAdmin from "../../sidebar/nav-roles/nav-roles-admin";
import {useSelector} from "react-redux";
import "./accountRole.css";
import { useDispatch } from "react-redux";
import { SetAccountRole, SetActiveNav, SetNavRoles } from "../../../redux/ui/ui-actions";
import {useHistory} from 'react-router-dom';


const AccountRole = (props) => {
  const userData = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const accountRole = useSelector((state) => state.ui.accountRole)

  return (
    <select className={"form-control data-input input-control account-role-selector"} value={accountRole} name={"roleOptions"} onChange={e => {
        switch (e.target.value) {
            case "companyRole":
                dispatch(SetAccountRole("companyRole"));
                if (e.target.selectedOptions[0].dataset.admin) {
                    // setRole([...navRolesBusiness, ...navRolesAdmin]);
                    dispatch(SetNavRoles([...navRolesBusiness, ...navRolesAdmin]))
                } else {
                    // setRole(navRolesBusiness)
                    dispatch(SetNavRoles(navRolesBusiness))
                }
                dispatch(SetActiveNav(navRolesBusiness[0].path))
                history.push(navRolesBusiness[0].path)
                break;
            default:
                dispatch(SetAccountRole("userRole"));
                dispatch(SetNavRoles(navRolesBase))
                dispatch(SetActiveNav(navRolesBase[0].path))
                history.push(navRolesBase[0].path)
                break;
        }
    }}>
        <option className={"mgs_optionDropdown"} value={"userRole"}>Personal Account</option>
        {userData.roles.map((companyRole, key) => {
            return <option key={key} className={"mgs_optionDropdown"} value={"companyRole"}
                          data-admin={(companyRole.roleName === "admin")}>
                {companyRole.orgName}
            </option>
        })}
    </select>
  )
};

export default AccountRole;