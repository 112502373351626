import React from 'react';

const Verify = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.6895 10.25L9.0635 12.623L13.8095 7.877M14.584 1H5.915C2.894 1 1 3.139 1 6.166V14.334C1 17.361 2.885 19.5 5.915 19.5H14.583C17.614 19.5 19.5 17.361 19.5 14.334V6.166C19.5 3.139 17.614 1 14.584 1Z" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default Verify;