/* eslint-disable no-unused-vars */
import {BrowserRouter, Redirect, Route, Switch, useLocation} from "react-router-dom";
import LoginPage from "../components/login/login-page";
import UserPublicCardPage from "../components/users/user-public/user-public-page";
import React, { useEffect } from "react";
import PrivateRoute from './private-route';
import LoginPageTelegram from "../components/login/login-telegram-page";
import {GroupRoute} from "./group-route";
import {PrivateDashboard} from "./dashboard-router";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../custom.css"
import BusySpinner from "../utils/busy-spinner-modal";

const AppRouter = () => {

    const location = useLocation();
    let baseURL = "https://my.grapeid.com";
    if (process.env.REACT_APP_ENVIRONMENT == "development") baseURL = "https://mydev.grapeid.com";
    useEffect(() => {
        // In production, redirect HTTP --> HTTPS
        if (process.env.REACT_APP_ENVIRONMENT === "production") {
            if (window.location.protocol === 'http:') {

                console.log("you are accessing us via "
                    +  "an insecure protocol (HTTP). "
                    + "Redirecting you to HTTPS.");

                window.location.href =
                    window.location.href.replace(
                            'http:', 'https:');
            }   else if (window.location.protocol === "https:") {
                console.log("you are accessing us via"
                    + " our secure HTTPS protocol.");
            }
        }

        const urlString = location.search.split('?')[1];
        if (urlString && urlString.length > 0 &&  urlString.indexOf('k12') > -1) {
                window.open(`https://grapeid.page.link/?link=${baseURL}?${urlString}&apn=com.grapeid.hybrid&isi=1587046966&ibi=com.grapeid.hybrid`, "_self");
        }


    }, [location]);
    return (
            <Switch>
                <Route path={"/"} component={LoginPage} exact={true}/>
                <GroupRoute path={"/telegram"} component={LoginPageTelegram}/>
                <Route path={"/public-card"}  component={UserPublicCardPage} />
                <PrivateRoute>
                    <PrivateDashboard />
                </PrivateRoute>
            </Switch>
    )
}

export default AppRouter;
