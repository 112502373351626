import React, {useState} from "react";
import {FormControl} from 'react-bootstrap';
import {AiOutlineClose} from "react-icons/ai";
import {FaSearch} from "react-icons/fa";
import "./search-bar.css";

const SearchBar = ({initValue, onChange}) => {
  const [focus, setFocus] = useState(false);
  const [close, setClose] = useState(false);
  const [value, setValue] = useState(initValue ? initValue : '');
  const changeText = (text) => {
    setValue(text);
    if (onChange) onChange(text);
    if (text.length > 0) setClose(true);
    else setClose(false);
  }
  return (
    <div className={"d-flex flex-row justify-content-center align-items-center px-2 flex-1"}>
        <FormControl
          placeholder="Search..."
          value={value}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={(ev) => changeText(ev.target.value)}
          className="search-form"
        ></FormControl>
        {
          close &&
          <AiOutlineClose size="20" style={{cursor: 'pointer'}} onClick={() => changeText('')}/>
        }
        <FaSearch size="20" color="#897B8A"/>
    </div>
  )
}

export default SearchBar