import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Container, Row, Col, Dropdown, DropdownButton, Toast, ToastContainer, Spinner} from 'react-bootstrap';
import VerifyProcessPage from "./verify-process-page";
import {DataVerifying, DataProfiling} from "./verify-data-util";
import Header from "../../../../header/header";
import VerifyUserList from "./verify-user-list";
import './../admin-verify.css';
import {HideBusy, ShowBusy} from "../../../../../redux/busySpinner/spinner-actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";

const statusConvert = (str) => {
    switch (str) {
        case 'incomplete':
            return 'Needs Review';
        case 'rejected':
            return 'Auto-Rejected'; 
        case 'autoVerified':
            return 'Auto-Accepted';
        default:
            return 'Needs Review';
    }
}

const VerifyDashboardPage = () => {
    const userData = useSelector((state) => state.user.user);

    // Contains objects of: userId, status, and date
    const [Profiles, setProfiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [dataSentToServer, setDataSentToServer] = useState(false);
    const [startProfiles, setStartProfiles] = useState([]);
    const [time,setTime] = useState();
    const dispatch = useDispatch();

    // Values to be used and updated
    const [values, setValues] = useState({
        seen: false,
        autoVerified: '',
        incompleteVerification: '',
        rejected: '',
        totalProfilesToReview: '',
        profilesToCheckout: 25,
        typeOfStatusCheckout: "incomplete",
    });

    /*
    Retrieves a list of profiles available for checkout
     */
    useEffect(() => {
        loadData();
    }, []);

    // TODO: Have this make a new server call to update existing checked out profiles.
    useEffect(() => {
        if (Profiles.length > 0) {
            const interval = setInterval(() => setTime(Date.now()), 600000);
            return () => {
                clearInterval(interval);
                console.log("Refreshing Profiles")
            }
        }
    });

    // Event fired values update
    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
        console.log(e.target, e.target.value);
    }

    const loadData = (refresh = false) => {
        if (refresh) setRefreshing(true);
        DataVerifying(userData.sessionToken).then((r) => { 
            console.log("Data Verifying Users === ", r);
            if (r.data) {
                console.log("Profiles === ", r.data.profiles);
                setProfiles([...r.data.profiles]);
                setValues({
                    ...values,
                    autoVerified: r.data.stats.autoVerified,
                    incompleteVerification: r.data.stats.incompleteVerification,
                    rejected: r.data.stats.rejected,
                    totalProfilesToReview: r.data.stats.totalProfilesToReview
                })
            }
            setLoading(false);
            if (refresh) setRefreshing(false);
        });
    }

    const handleParamChange = (field, value) => {
        setValues({
            ...values,
            [field]: value
        })
    }

    const startVerify = () => {
        dispatch(ShowBusy())
        DataProfiling({
            qty: values.profilesToCheckout, status: values.typeOfStatusCheckout, key:
            userData.sessionToken
        }).then(r => {
            console.log(r);
            if (!r || !r.profiles || r.profiles.length === 0) {
                window.confirm("No profiles in that category to review")
                dispatch(HideBusy());
            } else {
                setStartProfiles(r.profiles);
                dispatch(HideBusy());
                setModalShow(true);
                setTime(Date.now());
            }
        })
    }

    const hideModal = () => {
        setModalShow(false)
        loadData();
    }

    // useEffect(() => {
    //     loading ? dispatch(HideBusy()) : dispatch(ShowBusy());
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
        <Container fluid className={"vh-100 max-limited-page"}>
            {/* <ToastContainer position={"top-center"}>
                <Toast
                    onClose={()=> setDataSentToServer(false)}
                    show={dataSentToServer} delay={3000}
                    autohide
                    bg={"light"}
                    className={"text-center m-4 border-success border-1 w-100"}
                >
                    <Toast.Body className={"border-dark"} closeButton={false}>
                        <strong className="me-auto fs-3" style={{color: "green"}}>
                            Batch Closed & Sent to Server
                        </strong>
                    </Toast.Body>
                </Toast>
            </ToastContainer> */}
            <Header  roleShow />
            <Container className="page-content-with-header">
                <div className="mb-3" style={{ display: "flex", width: "100%", justifyContent: "start" }}>
                    <div style={{ marginRight: "2em" }}>
                        <div className="d-flex flex-fill flex-row typeBreakdown">
                            <div className="flex-fill d-flex flex-column font-18">
                                <div className="text-end">Needs Review</div>
                            </div>
                            <div className="flex-fill d-flex flex-column font-18">
                                <div className="ps-4 fw-bold">{values.incompleteVerification | 0}</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginRight: "2em" }}>
                        <div className="d-flex flex-fill flex-row typeBreakdown">
                            <div className="flex-fill d-flex flex-column font-18">
                                <div className="text-end">Auto-Rejected</div>
                            </div>
                            <div className="flex-fill d-flex flex-column font-18">
                                <div className="ps-4 fw-bold">{values.rejected | 0}</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginRight: "2em" }}>
                        <div className="d-flex flex-fill flex-row typeBreakdown">
                            <div className="flex-fill d-flex flex-column font-18">
                                <div className="text-end">Auto-Accepted</div>
                            </div>
                            <div className="flex-fill d-flex flex-column font-18">
                                <div className="ps-4 fw-bold">{values.autoVerified | 0}</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginRight: "2em" }}>
                        <div className="d-flex flex-fill flex-row typeBreakdown">
                            <div className="flex-fill d-flex fledivumn font-18">
                                <div className="text-end">Total</div>
                            </div>
                            <div className="flex-fill d-flex flex-column font-18">
                                <div className="ps-4 fw-bold">{values.totalProfilesToReview | 0}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", width: "100%", justifyContent: "start", alignItems: "center", marginBottom: "50px" }}>
                    <div className="ver-comp flex align-items-center" style={{ marginRight: "10px" }}>
                        <div className="font-2 ps-2 mr-2">Check out:</div>
                        <DropdownButton id="dropdown-basic-button" className="" title={`${values.profilesToCheckout} profiles`} bsPrefix="trans-dropdown">
                            <Dropdown.Item bsPrefix="trans-dropdown-item" as="div" onClick={() => handleParamChange('profilesToCheckout', 25)}>25 Profiles</Dropdown.Item>
                            <Dropdown.Item bsPrefix="trans-dropdown-item" as="div" onClick={() => handleParamChange('profilesToCheckout', 50)}>50 Profiles</Dropdown.Item>
                            <Dropdown.Item bsPrefix="trans-dropdown-item" as="div" onClick={() => handleParamChange('profilesToCheckout', 100)}>100 Profiles</Dropdown.Item>
                        </DropdownButton>
                        <div className="font-2 ps-2">From:</div>
                        <DropdownButton id="dropdown-basic-button" className="p-2" title={statusConvert(values.typeOfStatusCheckout)} bsPrefix="trans-dropdown">
                            <Dropdown.Item bsPrefix="trans-dropdown-item" as="div" onClick={() => handleParamChange('typeOfStatusCheckout', "incomplete")}>Needs Review</Dropdown.Item>
                            <Dropdown.Item bsPrefix="trans-dropdown-item" as="div" onClick={() => handleParamChange('typeOfStatusCheckout', "rejected")}>Auto-Rejected</Dropdown.Item>
                            <Dropdown.Item bsPrefix="trans-dropdown-item" as="div" onClick={() => handleParamChange('typeOfStatusCheckout', "autoVerified")}>Auto-Accepted</Dropdown.Item>
                        </DropdownButton>
                    </div>
                    <div className="ver-comp flex-row">
                        <button 
                            style={{ 
                                fontSize: "1.5em", 
                                fontWeight: "bold", 
                                backgroundColor: "#DCDCDC", 
                                border: "none", 
                                paddingTop: "5px", 
                                paddingBottom: "5px",
                                paddingLeft: "8px",
                                paddingRight: "8px",
                                borderRadius: "10%"
                            }}
                            name={"seen"}
                            value={true}
                            onClick={() => startVerify()}
                        >
                            Begin Batch
                        </button>
                        {
                            !refreshing ?
                            <FontAwesomeIcon
                                title="Refresh"
                                icon={faRedoAlt}
                                size={"3x"}
                                className={"filter-icon ms-4"}
                                style={{ cursor: "pointer" }}
                                onClick={() => loadData(true)}
                            />
                            :
                            <Spinner className={"refresh-icon ms-4"} animation="border" size="3x" />
                        }
                    </div>
                </div>
                
            </Container>
            <Container className="mt-4">
                <VerifyUserList  profiles={Profiles}/>
            </Container>
            <VerifyProcessPage
                data={startProfiles}
                show={modalShow}
                onHide={() => hideModal()}
                dataSent={() => setDataSentToServer(true)}
            />
        </Container>
    )
}
export default VerifyDashboardPage;
