export const USER_PROFILE_UPDATE_ACTION = {
  DISPLAY_NAME: 'displayName',
  DELETE_ACCOUNT: 'deleteAccount',
  ADD: 'add',
  UPDATE: 'update',
  DELETE: 'delete',
  UPDATE_VISIBILITY: 'updateVisibility',
}

export const RADIOS = [
  { name: 'Private', value: 'private' },
  { name: 'Grape Contacts', value: 'contactsOnly' },
  { name: 'Digital Contact Card', value: 'publicCard' },
];