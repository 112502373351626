import '../../../App.css';
import '../../../custom.css'
import React, {useEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Accordion,
    Button,
    Container,
    FormControl,
    InputGroup,
    Row,
    Toast,
    ToastContainer,
    ToggleButtonGroup,
    ToggleButton,
    Dropdown, DropdownButton, ButtonGroup, SplitButton
} from "react-bootstrap";
import {DataProfile, DataSubmitting} from "./profile-data-util";
import {cloneDeep} from "lodash";
import {
    faEye,
    faTimes,
    faUser,
    faUserFriends,
    faGlobe,
    faEnvelope,
    faEdit,
    faPhoneAlt,
    faMapMarkerAlt,
    faPencilAlt,
    faCheck
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './user-profile-styles.css';
import UserProcessModal from "./user-process-modal";
import { USER_PROFILE_UPDATE_ACTION, RADIOS } from './constants';
import SearchAddressInput from "../../../utils/input/search-address-input";
import AddressAutoInput from "../../../utils/input/address-auto-input";
import { ShowError } from "../../../redux/ui/ui-actions";
import {AiOutlineClose} from "react-icons/ai";
import Email from '../../../icons/email';
import Phone from '../../../icons/phone';
import Home from '../../../icons/home';
import Delete from '../../../icons/delete';
import Header from '../../header/header';

const SubTitleComponent = ({title, onChange, onFinish}) => {
    const [initTitle, setInitTitle] = useState(title);
    const [editable, setEditable] = useState(false);
    const inputRef = useRef(null);
    useEffect(() => {
        if (editable && inputRef) {
            inputRef.current.focus();
        }
    }, [editable]);
    return (
        <div className='d-flex flex-row align-items-center ps-5 sub-title-margin'>
            <span className='sub-title-text'>Display Name: </span>
            <div className='d-flex flex-row align-items-center ps-3'>
                {
                    !editable ?
                    <span className='sub-title-text'>{title}</span>
                    :
                    <input ref={inputRef} className='sub-title-text' type="text" value={initTitle} onChange={ev => setInitTitle(ev.target.value)} 
                        onBlurCapture={(event) => {
                            setEditable(false);
                            if (event.target.value !== title) {
                                onFinish(event.target.value)
                            }
                        }}
                    />
                }
                {
                    !editable ?
                    <FontAwesomeIcon className="ms-3" icon={faPencilAlt} size={"2x"} onClick={() => {
                        setEditable(true)
                    }}/>
                    :
                    <></>
                    // <FontAwesomeIcon className="ms-3" icon={faCheck} size={"2x"} onClick={() => {
                    //     setEditable(false)
                    //     onFinish();
                    // }}/>
                }
            </div>
        </div>
    )
}

const UserProfilePage = () => {
    const userData = useSelector((state) => state.user.user);
    // eslint-disable-next-line no-unused-vars
    const BusySpinnerState = useSelector((state) => state.BusySpinner.value);

    const dispatch = useDispatch();
    const [updateInfo, setUpdateInfo] = useState(false);
    const [validated, setValidated] = useState(false);
    const [infoVisibility, setInfoVisibility] = useState(['publicCard', 'contactsOnly', 'private']);
    const [currentVisibility, setCurrentVisibility] = useState(0);
    const [radioValue, setRadioValue] = useState('1');

    const [modals, setModals] = useState({
        emailModalShow: false,
        phoneNumberModalShow: false,
        addressModalShow: false,
    })

    const [values, setValues] = useState({
        data: [],
        displayName: '',
        deleteAccount: false,
        firstName: '',
        lastName: '',
        selfieUrl: '',
        addNewInfo: {},
        serverPacket:{},
        visibility:0,
        cardId: ''
    })
    // Error Handling
    const [error, setError] = useState({
        displayName: '',
        email: '',
        countryCode: '',
        phoneNumber: '',
        address: '',
        serverResponse:''
    })

    const updateModalShow = (modalType) => {
        setModals({
            ...modals,
            [modalType]: false
        })
    }

    // Initial Profile Retrieval and value setup
    useEffect(() => {
        loadProfile();
    }, []);

    const loadProfile = () => {
        DataProfile(userData.sessionToken).then((r) => {
            console.log("Profile Data Loaded === ", r);
            setValues({
                data: r.contactInfo,
                displayName: r.displayName,
                deleteAccount: false,
                firstName: r.firstName,
                lastName: r.lastName,
                selfieUrl: r.selfieUrl,
                cardId: r.publicCardId,
            })
        });
    }

    const changeDisplayName = (val) => {
        setValues({
            ...values,
            displayName: val
        })
        // handleAction(USER_PROFILE_UPDATE_ACTION.DISPLAY_NAME, {value: val});
    }

    const changeContactInfo = (actionType, pkg) => {
        // const contactData = cloneDeep(values.data);
        let contactData = values.data;
        switch (actionType) {
            case USER_PROFILE_UPDATE_ACTION.ADD:
                contactData.push({
                    type: pkg.type,
                    value: pkg.value,
                    visibility: 'private',
                    index: contactData.filter(contact => contact.type === pkg.type).length
                })
                break;
            case USER_PROFILE_UPDATE_ACTION.UPDATE:
                if (!pkg.index) break;
                contactData = contactData.map(contact => {
                    if (contact.type === pkg.type && contact.index === pkg.index) {
                        return {
                            ...contact,
                            value: pkg.value
                        }
                    }
                    return contact;
                });
                break;
            case USER_PROFILE_UPDATE_ACTION.UPDATE_VISIBILITY:
                if (!pkg.index) break;
                contactData = contactData.map(contact => {
                    if (contact.type === pkg.type && contact.index === pkg.index) {
                        return {
                            ...contact,
                            visibility: pkg.visibility
                        }
                    }
                    return contact;
                });
                break;
            case USER_PROFILE_UPDATE_ACTION.DELETE:
                if (!pkg.index || !pkg.type) break;
                contactData = contactData.filter(contact => contact.type !== pkg.type || contact.index !== pkg.index);
                break;
            default:
                break;
        }
        setValues({
            ...values,
            data: contactData,
        })
    };

    const handleAction = (actionType, sendPkg) => {
        let prepObject = {};
        const changeValue = sendPkg.value ? sendPkg.value : sendPkg.primaryEmail ? sendPkg.primaryEmail : undefined;
        switch (actionType) {
            case USER_PROFILE_UPDATE_ACTION.DISPLAY_NAME:
                setValues({
                    ...values,
                    displayName: sendPkg.value
                })
                prepObject = {
                    [USER_PROFILE_UPDATE_ACTION.DISPLAY_NAME]: sendPkg.value
                };
                break;
            case USER_PROFILE_UPDATE_ACTION.DELETE_ACCOUNT:
                prepObject = {
                    [USER_PROFILE_UPDATE_ACTION.DELETE_ACCOUNT]: true
                };
                break;
            case USER_PROFILE_UPDATE_ACTION.ADD:
                changeContactInfo(USER_PROFILE_UPDATE_ACTION.ADD, sendPkg);
                prepObject = {
                    [USER_PROFILE_UPDATE_ACTION.ADD]: [{type: sendPkg.type, value: changeValue}]
                };
                break;
            case USER_PROFILE_UPDATE_ACTION.UPDATE:
                changeContactInfo(USER_PROFILE_UPDATE_ACTION.UPDATE, sendPkg);
                prepObject = {
                    [USER_PROFILE_UPDATE_ACTION.UPDATE]: [{type: sendPkg.type, index: sendPkg.index, value: changeValue}]
                };
                break;
            case USER_PROFILE_UPDATE_ACTION.DELETE:
                changeContactInfo(USER_PROFILE_UPDATE_ACTION.DELETE, sendPkg);
                prepObject = {
                   [USER_PROFILE_UPDATE_ACTION.DELETE]: [{type: sendPkg.type, index: sendPkg.index}]
                };
                break;
            case USER_PROFILE_UPDATE_ACTION.UPDATE_VISIBILITY:
                changeContactInfo(USER_PROFILE_UPDATE_ACTION.UPDATE_VISIBILITY, sendPkg);
                prepObject = {
                    [USER_PROFILE_UPDATE_ACTION.UPDATE_VISIBILITY]: [{type: sendPkg.type, index: sendPkg.index, visibility: sendPkg.visibility}]
                };
                break;
            default: break;
        }
        if (prepObject) {
            console.log("Packet ready for server: ", prepObject);
            let serverPacket = {
                ...prepObject,
                key: userData.sessionToken,
            };
            DataSubmitting(serverPacket).then((r) => {
                //TODO: Display error on form
                if (r.error) {
                    console.log("Data Submitting Error::: ", r);
                    setError({...values, [actionType]: r.error});
                    setValidated(false);
                }
                console.log(values.data);
                loadProfile();
                setUpdateInfo(true);
            });
        }
    }

    const getVisibility = (value) => {
        return RADIOS.find(radio => radio.value === value).name;
    }

    const preprocess = (actionType, item, value) => {
        if (actionType === USER_PROFILE_UPDATE_ACTION.UPDATE) {
            if (item.value === value) return;
            handleAction(actionType, {...item, value: value});
        }
        if (actionType === USER_PROFILE_UPDATE_ACTION.UPDATE_VISIBILITY) {
            if (item.visibility === value) return;
            console.log("Update Visibility Param ==== ", {...item, 'visibility': value});
            handleAction(actionType, {...item, 'visibility': value});
        }
    }

    const handleType = (type) => {
        if (!values.data) {
            console.log("false false");
            return
        }
        let newContactInfo = cloneDeep(values.data);
        return (newContactInfo.filter((item) => item.type === type)).map((item) => {

            return <InputGroup className="fs-2 mb-3 list-item" key={item.index}>
               {/*TODO: Add in a number to match the count of items as they are placed in for a group. As in - 1. email1 2. email2 3. email3 etc.*/}
               {
                   item.type !== 'address' ?
                   <FormControl
                        readOnly={item.primaryEmail}
                        defaultValue={item.value || item.primaryEmail}
                        onBlur={(event)=> {
                                preprocess(USER_PROFILE_UPDATE_ACTION.UPDATE, item, event.target.value);
                            }}
                        name={item.type}
                        aria-label={item.type}
                        aria-describedby="basic-addon2"
                        visibility={infoVisibility}
                        className="fs-4 no-border"
                    />
                   :
                   <AddressAutoInput onChange={(newAddress) => {
                        preprocess(USER_PROFILE_UPDATE_ACTION.UPDATE, item, newAddress);
                    }} address={() => {}} default={item.value} ignoreValidation={true} />
                }
                {/* {
                    !item.primaryEmail ?
                    <DropdownButton as={"ButtonGroup"}
                                    key={"updateVisibility"}
                                    id={`dropdownRadioGroup`}
                                    variant={"outline-secondary"}
                                    bsPrefix={'list-button list-dropdown'}
                                    title={getVisibility(item.visibility)}>
                        
                        {RADIOS.map((radio, idx) => (
                            <Dropdown.Item
                                onClick={() => {
                                        preprocess(USER_PROFILE_UPDATE_ACTION.UPDATE_VISIBILITY, item, radio.value);
                                    }
                                }
                                key={idx}
                                eventKey={idx}
                                id={`radio-${idx}`}
                                label={ radio.name }
                                bsPrefix={'dropdown-item list-button list-dropdown-item'}
                            >
                                {radio.name}
                            </Dropdown.Item>
                            // <Dropdown.Item href="#">Action</Dropdown.Item>
                        ))}
                    </DropdownButton>
                    : <></>
                } */}
                {
                    !item.primaryEmail ?
                    <Button className="fs-5 ps-5 pe-5 list-button no-border"
                            name={'delete'}
                            onClick={() => {
                                handleAction(USER_PROFILE_UPDATE_ACTION.DELETE, item, item.index)
                            }}
                            variant={"outline-secondary"}>
                        {/* <AiOutlineClose size={20}/> */}
                        <Delete />
                    </Button>
                    : <></>
                }
                
            </InputGroup>
        });
    }
    const openPublicCard = () => {
        if (values.cardId === '' || values.cardId === null) {
            dispatch(ShowError('Please edit visibility to make info sharable on your digital contact card'));
        } else {
            window.open(window.location.origin + '/public-card?cardId=' + values.cardId, '_blank');
        }
    }

    return (
        <Container fluid className={"max-limited-page pb-5 vh-100"}>
            <Header roleShow/>
            <div className='page-content-with-header'>
                <Row className='m-1' style={{maxWidth: '1000px'}}>
                    <Accordion defaultActiveKey="0" className={"p-3"} flush>
                        <Accordion.Item eventKey="0" className={"mx-4 bg-light"}>
                            <Accordion.Header className={"fs-1"}>
                                <div className={"fs-3 d-flex flex-row align-items-center flex-1"}>
                                    {/* <FontAwesomeIcon icon={faEnvelope} size={"1x"} className={"mr-2"}/> */}
                                    <Email />
                                    <div className="d-flex flex-row justify-content-between flex-1 section-title">
                                        <span>Emails</span>
                                        {
                                            values.data && values.data.filter(item => item.type === 'email').length > 0 ?
                                            <span className='section-action'>Delete</span>
                                            : undefined
                                        }
                                    </div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                {values?.data ?
                                    handleType('email')
                                    : "Emails Loading"}
                                <Button className={"fs-5 p-2 w-100 text-start mx-2 btn-light"}
                                        onClick={() => {
                                            setValues({
                                                ...values,
                                                addNewInfo: 'email',
                                            });
                                            setModals({
                                                ...modals,
                                                emailModalShow: true
                                            });
                                        }}
                                >Add New..
                                </Button>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Row>
                <Row className='m-1' style={{maxWidth: '1000px'}}>
                    <Accordion defaultActiveKey="0" className={"p-3"} flush>
                        <Accordion.Item eventKey="0" className={"mx-4 bg-light"}>
                            <Accordion.Header className={""}>
                                <div className={"fs-3 d-flex flex-row align-items-center flex-1"}>
                                    <Phone />
                                    <div className="d-flex flex-row justify-content-between flex-1 section-title">
                                        <span>Phone Numbers</span>
                                        {
                                            values.data && values.data.filter(item => item.type === 'phoneNumber').length > 0 ?
                                            <span className='section-action'>Delete</span>
                                            : undefined
                                        }
                                    </div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                {values?.data ?
                                    handleType('phoneNumber')
                                    : "Numbers Loading"}
                                <Button className={"fs-5 p-2 w-100 text-start mx-2 btn-light"}
                                        onClick={() => {
                                            setValues({
                                                ...values,
                                                addNewInfo: 'phoneNumber',
                                            })
                                            setModals({
                                                ...modals,
                                                phoneNumberModalShow: true
                                            })
                                        }}
                                >Add New..
                                </Button>
                            </Accordion.Body>
                        </Accordion.Item></Accordion>
                </Row>
                <Row className='m-1' style={{maxWidth: '1000px'}}>
                    <Accordion defaultActiveKey="0" className={"p-3"} flush>
                        <Accordion.Item eventKey="0" className={"mx-4 bg-light"}>
                            <Accordion.Header>
                                <div className={"fs-3 d-flex flex-row align-items-center flex-1"}>
                                    <Home />
                                    <div className="d-flex flex-row justify-content-between flex-1 section-title">
                                        <span>Addresses</span>
                                        {
                                            values.data && values.data.filter(item => item.type === 'address').length > 0 &&
                                            <span className='section-action'>Delete</span>
                                        }
                                    </div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                {values?.data ?
                                    handleType('address')
                                    : "Addresses Loading"}
                                <Button className={"fs-5 p-2 w-100 text-start mx-2 btn-light"}
                                        onClick={() => {
                                            setValues({
                                                ...values,
                                                addNewInfo: 'address',
                                                addressModalShow: true
                                            });
                                            setModals({
                                                ...modals,
                                                addressModalShow: true
                                            })
                                            setValidated(false)
                                        }}
                                >Add New..
                                </Button>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Row>
            </div>
            <ToastContainer className="p-4" position={"middle-center"}>
                <Toast
                    onClose={() => setUpdateInfo(false)}
                    show={updateInfo} delay={3000}
                    autohide
                    bg={"light"}
                    className={"text-center m-4 border-success border-1 w-100 toast-wrapper"}
                >
                    <Toast.Body className={"toast-custom"}>
                        <strong className="me-auto fs-3" style={{color: "white"}}>
                            Update successful.
                        </strong>
                    </Toast.Body>
                </Toast>
            </ToastContainer>

            {/*Email Input Modal*/}
            <UserProcessModal
                modalShow={modals.emailModalShow}
                handleShow={(type) => updateModalShow(type)}
                handleAction={handleAction}
                label={"Email Address"}
                errorText={"Please enter a valid email."}
                infoType={'email'}
            />
            {/*Phone Number Input Modal*/}
            <UserProcessModal
                modalShow={modals.phoneNumberModalShow}
                handleShow={updateModalShow}
                handleAction={handleAction}
                label={"Add Phone Number"}
                errorText={error.phoneNumber || error.serverResponse}
                infoType={"phoneNumber"}
            />
            {/*Address Input Modal*/}
            <UserProcessModal
                modalShow={modals.addressModalShow}
                handleShow={updateModalShow}
                handleAction={handleAction}
                label={"Address"}
                errorText={"Please enter an address."}
                infoType={'address'}
            />

        </Container>


    );
}

export default UserProfilePage;
