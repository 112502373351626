import React from 'react';
import Header from "../../header/header";
import {Container} from 'react-bootstrap';
import "./employee-management.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCircle} from "@fortawesome/free-solid-svg-icons";

/**
 * <h2>EmployeeManagement</h2>
 * <p>Class to remove, add, and display employees.</p>
 */
class EmployeeManagement extends React.Component {
    constructor(props) {
        super(props);
        this.handleRemoveEmployee = this.handleRemoveEmployee.bind(this);
        this.handleSearchUsers = this.handleSearchUsers.bind(this);
        this.toggleAddEmployee = this.toggleAddEmployee.bind(this);

        this.state = {
            employees: props.employees,
            users: props.users,
            // State used for displaying AddEmployee modal
            seen: false
        };
    }

    // Toggles the AddEmployee modal
    toggleAddEmployee() {
        this.setState({
            seen: !this.state.seen
        });
    }

    // Remove an employee
    handleRemoveEmployee(removeEmployee) {
        this.setState((prevState) => {
            return {
                employees: [...prevState.employees].filter((employee) => {
                    if (employee !== removeEmployee) {
                        return employee;
                    }
                    return null;
                })
            }
        });
    }

    // TODO: Add backend functionality to handleSearchUsers

    // Finds and adds the Employee to the Employees list
    handleSearchUsers(userInfo) {

        // Check if form data was filled
        if (!userInfo.email || !userInfo.title) {
            return "An email and Title are required.";
        }
        // Check if user exists
        if (this.state.employees.find((userInfo) => userInfo)) {
            return "Not Available";
        } else {
            const dummyEmployee = {
                name: userInfo.name || "JimBob Mgee",
                selfie: userInfo.selfie || "&#8709;",
                score: userInfo.score || "0%",
                title: userInfo.title || "Employee",
                valid: userInfo.valid || true
            };

            // Add to employees
            this.setState((prevState) => ({
                employees: [...prevState.employees, dummyEmployee]
            }));

            // Close AddEmployee modal
            this.toggleAddEmployee();
        }
    }

    render() {
        return (
            <Container fluid className={"vh-100 max-limited-page"}>
                <Header
                    roleShow
                />
                <Container className="page-content-with-header">
                    <div className={"employee-management-grid"}>
                        <button className={"btn btn-success btn-lg fw-bold pb-4 add-employee-btn"}
                                onClick={this.toggleAddEmployee}>Add Employee
                        </button>
                        {this.state.seen ? <InviteEmployee
                                seen={this.state.seen}
                                handleSearchUsers={this.handleSearchUsers}
                                onClose={this.toggleAddEmployee}
                            />
                            : null}
                        <EmployeeList
                            employeeList={this.state.employees}
                            handleRemoveEmployee={this.handleRemoveEmployee}
                        />
                    </div>
                </Container>
            </Container>
        );
    }
}

/**
 * <h2>EmployeeList</h2>
 * <p>Sorts through the employees by name and displays each employee</p>
 * @param props The current list of Employees
 * @returns {JSX.Element}
 * @constructor
 */
const EmployeeList = (props) => {

    const sortedList = [...props.employeeList].sort((a, b) => {
        return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
    });
    return (
        <section className={"employee-list-grid"}>
            <div className={"text-nowrap p-1 fs-2 employee-list-header"}>
                <div/>
                <div>Name</div>
                <div>Grape Score</div>
                <div>Title</div>
                <div>Action</div>
            </div>
            <div className={"employee-list-employees"}>
                {sortedList.map((employee, index) => (
                    <Employee
                        employee={employee}
                        key={index}
                        handleRemoveEmployee={props.handleRemoveEmployee}
                    />
                ))}
            </div>
        </section>
    );
};

/**
 * <h2>Employee</h2>
 * <p>Displays the employee details.</p>
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Employee = (props) => {
    // logic for grape score color variation
    return (
        <div className={"employee"}>
            <div className="employee-selfie">
                {
                    props.employee.selfieUrl
                    &&
                    <img src={props.employee.selfieUrl} alt={props.employee.name}/>
                }
                <div className={"mgs_fa-icons"}>
                    <FontAwesomeIcon
                        icon={faUserCircle}
                        size={"2x"}
                    />
                </div>
            </div>
            <div className={"employee-name"}>
                {props.employee.name}
            </div>
            <div className={"employee-score"} style={{color: MgsColor(props.employee.score)}}>{props.employee.score}%
            </div>
            <div className={"employee-title"}>{props.employee.title}</div>
            <div className={"employee-action"}>
                {props.employee.valid ? (
                    <div>
                        <i>Pending...</i>
                    </div>
                ) : (
                    <button className={"btn btn-secondary btn-lg"}
                            onClick={(e) => {
                                props.handleRemoveEmployee(props.employee);
                            }}
                    >
                        Remove
                    </button>
                )}
            </div>
        </div>
    );
};

/**
 * <h2>MgsColor</h2>
 * <p>Returns the color property value for a given Grape Score</p>
 * @param mgs User's Grape Score
 */
const MgsColor = (mgs) => {
    const score = parseInt(mgs);

    // Check score
    if (isNaN(score)) {
        console.log("Error in MgsColor: User's Grape Score is: " + score);
        return;
    }

    // Return the color property value
    if (score <= 35) {
        return "#c92d39";
    } else if (score <= 75) {
        return "var(--primary-orange1)";
    } else {
        return "var(--primary-green1)";
    }

}

/**
 * <h2>Invite Employee</h2>
 * <p>Class that uses form data to invite and add employee</p>
 */
class InviteEmployee extends React.Component {
    constructor(props) {
        super(props);
        this.handleSearchUsers = this.handleSearchUsers.bind(this);
        this.input = React.createRef();
        this.state = {
            error: undefined
        };
    }

    handleSearchUsers(e) {
        e.preventDefault();
        const email = e.target.userEmail.value.trim();
        const title = e.target.userTitle.value.trim();
        const error = this.props.handleSearchUsers({email, title});
        this.setState(() => ({error}));
    }

    render() {
        return (
            <section className={"add-employee-modal"}>
                <div className=" modal-dialog modal-dialog-centered" role={"document"}>
                    <div className={"modal-content bg-light p-4"}>
                        <div className={"modal-header"}>
                            <h3 className={"modal-title fw-bolder align-items-center"}>ADD EMPLOYEE</h3>
                            <button type="button" className="close btn-close" onClick={this.props.onClose}/>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={this.handleSearchUsers}>
                                {this.state.error && <p>{this.state.error}</p>}
                                <div className={"form-group p-2"}>

                                    <label className={"w-100"}>
                                        <div className={"fs-3 fw-bold"}>Step 1</div>
                                        <p className={"fs-5"}>What is this person's email that they used to register for
                                            Grape ID?</p>
                                        <input
                                            className={"form-control fs-4 fst-italic"}
                                            type="email"
                                            name="userEmail"
                                            placeholder={"Search"}
                                            autoFocus={true}
                                        />
                                    </label>
                                </div>
                                <div className={"form-group p-2 pb-5"}>
                                    <label className={"w-100"}>
                                        <h4 className={"fs-3 fw-bold"}>Step 2</h4>
                                        <p className={"fs-5"}>What is this person's title?</p>
                                        <input
                                            className={"form-control fs-4 fst-italic"}
                                            type="text"
                                            name="userTitle"
                                            placeholder={`eg. "Owner"`}
                                        />
                                    </label>
                                </div>
                                <div className={"modal-footer justify-content-center "}>
                                    <button
                                        type={"reset"}
                                        className={"btn btn-secondary btn-lg fw-bold"}
                                        onClick={this.props.onClose}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        value={"submit"}
                                        className={"btn btn-success btn-lg fw-bold add-employee-btn"}
                                    >
                                        Send Invite
                                    </button>
                                </div>
                                <blockquote className={"text-center fs-5"}><em>(The person will need to accept this
                                    role.)</em></blockquote>
                            </form>
                        </div>
                    </div>
                </div>
            </section>


        );
    }
}

// Default Dummy Data
EmployeeManagement.defaultProps = {
    employees: [
        {
            name: "Zreed Zreed",
            selfie: "&#8709;",
            score: "50",
            title: "Sprayer",
            valid: false
        },
        {
            name: "Noops",
            selfie: "&#8709;",
            score: "20",
            title: "Manager",
            valid: false
        },
        {
            name: "Kaqqwah baddiy",
            selfie: "&#8709;",
            score: "30",
            title: "Owner",
            valid: false
        }
    ],
    users: [
        {
            email: "hello@cow.com",
            owner: "Fello"
        },
        {
            email: "moo@cow.com",
            owner: "Fellee"
        },
        {
            email: "hoot@cow.com",
            owner: "Fellaa"
        }
    ]
};

export default EmployeeManagement;

