import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Container, Accordion, DropdownButton, Dropdown, InputGroup, FormControl, Button} from 'react-bootstrap';
import "./user-webpages-style.css";
import Header from "../../header/header";
import SearchBar from "../../common/search-bar/search-bar";
import ChromeIcon from "../../../assets/images/Chrome Extension Plugin Icon.png";
import { ConnectedDomains, DataWebPages, UpdateWebPages } from "./user-webpages-util";
import { USER_PROFILE_UPDATE_ACTION, RADIOS } from '../user-profile/constants';
import {AiOutlineClose} from "react-icons/ai";
import { ShowError } from "../../../redux/ui/ui-actions";
import Media from "../../../icons/media";
import Payment from "../../../icons/payment";
import NFT from "../../../icons/nft";
import Site from "../../../icons/site";
import Other from "../../../icons/other";
import SocialCheck from "../../../icons/socialCheck";
import Facebook from "../../../assets/images/facebook.png";
import Youtube from "../../../assets/images/youtube.png";
import Instagram from "../../../assets/images/instagram.png";
import Linkedin from "../../../assets/images/linkedin.png";
import Twitter from "../../../assets/images/twitter.png";

function UserWebPagesPage() {
    // eslint-disable-next-line no-unused-vars
    const userData = useSelector((state) => state.user.user);
    const [searchText, setSearchText] = useState('');
    const dispatch = useDispatch();
    const [data, setData] = useState({
        nft: [],
        other: [],
        payment: [],
        sm: [],
        domain: [],
        cardId: ''
    })
    const [domains, setDomains] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const BusySpinnerState = useSelector((state) => state.BusySpinner.value);

    const loadWebsitesData = () => {
        DataWebPages(userData.sessionToken).then(result => {
            console.log("Data Web Pages ==== ", result);
            setData({
                ...result,
                cardId: result.publicCardId
            })
        });
        ConnectedDomains(userData.sessionToken).then(result => {
            console.log("Connected Domains ==== ", result);
            setDomains(result);
        })
    }

    useEffect(() => {
        loadWebsitesData();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeContactInfo = (actionType, pkg) => {
        // const contactData = cloneDeep(values.data);
        // let contactData = values.data;
        let newData;
        switch (actionType) {
            // case USER_PROFILE_UPDATE_ACTION.ADD:
            //     contactData.push({
            //         type: pkg.type,
            //         value: pkg.value,
            //         visibility: 'private',
            //         index: contactData.filter(contact => contact.type === pkg.type).length
            //     })
            //     break;
            // case USER_PROFILE_UPDATE_ACTION.UPDATE:
            //     if (!pkg.index) break;
            //     contactData = contactData.map(contact => {
            //         if (contact.type === pkg.type && contact.index === pkg.index) {
            //             return {
            //                 ...contact,
            //                 value: pkg.value
            //             }
            //         }
            //         return contact;
            //     });
            //     break;
            case USER_PROFILE_UPDATE_ACTION.UPDATE_VISIBILITY:
                if (!pkg.index) break;
                newData = data[pkg.type].map(item => {
                    if (item.index === pkg.index) {
                        return {
                            ...item,
                            visibility: pkg.visibility
                        }
                    }
                    return item;
                });
                break;
            case USER_PROFILE_UPDATE_ACTION.DELETE:
                if (!pkg.index || !pkg.type) break;
                newData = data[pkg.type].filter(item => item.index !== pkg.index);
                break;
            default:
                break;
        }
        setData({
            ...data,
            [pkg.type]: newData,
        })
    };

    const handleAction = (actionType, sendPkg) => {
        let prepObject = {};
        const changeValue = sendPkg.value ? sendPkg.value : sendPkg.primaryEmail ? sendPkg.primaryEmail : undefined;
        switch (actionType) {
            // case USER_PROFILE_UPDATE_ACTION.UPDATE:
            //     changeContactInfo(USER_PROFILE_UPDATE_ACTION.UPDATE, sendPkg);
            //     prepObject = {
            //         [USER_PROFILE_UPDATE_ACTION.UPDATE]: [{type: sendPkg.type, index: sendPkg.index, value: changeValue}]
            //     };
            //     break;
            case USER_PROFILE_UPDATE_ACTION.DELETE:
                changeContactInfo(USER_PROFILE_UPDATE_ACTION.DELETE, sendPkg);
                prepObject = {
                   [USER_PROFILE_UPDATE_ACTION.DELETE]: [{type: sendPkg.type, id: sendPkg.id}]
                };
                break;
            case USER_PROFILE_UPDATE_ACTION.UPDATE_VISIBILITY:
                changeContactInfo(USER_PROFILE_UPDATE_ACTION.UPDATE_VISIBILITY, sendPkg);
                prepObject = {
                    [USER_PROFILE_UPDATE_ACTION.UPDATE_VISIBILITY]: [{type: sendPkg.type, id: sendPkg.id, visibility: sendPkg.visibility}]
                };
                break;
            default: break;
        }
        if (prepObject) {
            console.log("Packet ready for server: ", prepObject);
            let serverPacket = {
                ...prepObject,
                key: userData.sessionToken,
            };
            UpdateWebPages(serverPacket).then((r) => {
                //TODO: Display error on form
                
                loadWebsitesData();
                // setUpdateInfo(true);
            });
        }
    }

    const preprocess = (actionType, type, item) => {
        // if (actionType === USER_PROFILE_UPDATE_ACTION.UPDATE) {
        //     if (item.value === value) return;
        //     handleAction(actionType, {...item, value: value});
        // }
        if (actionType === USER_PROFILE_UPDATE_ACTION.UPDATE_VISIBILITY) {
            let newTypeData = data[type].map(pageItem => {
                if (pageItem.id === item.id) {
                    return{
                        ...pageItem,
                        visibility: item.visibility
                    }
                }
                return pageItem
            })

            setData({
                ...data,
                [type]: newTypeData
            })
            
            handleAction(actionType, {...item, 'visibility': item.visibility, type: type});
        }

        if (actionType === USER_PROFILE_UPDATE_ACTION.DELETE) {
            let newTypeData = data[type].filter(pageItem => pageItem.id !== item.id)
            setData({
                ...data,
                [type]: newTypeData
            })
            handleAction(actionType, {...item, type: type});
        }
    }

    const getVisibility = (value) => {
        return RADIOS.find(radio => radio.value === value).name;
    }

    const handleType = (type) => {
        if (!data[type]) return <></>
        let renderData = data[type];
        if (searchText !== '') {
            renderData = renderData.filter(item => item.url.toLowerCase().includes(searchText.toLowerCase()));
        }
        if (renderData.length === 0) {
            return <>Nothing to show</>
        }
        return renderData.map((item, index) => {
            return <div className="fs-2 connected-item me-4" key={index.toString()}>
               {/*TODO: Add in a number to match the count of items as they are placed in for a group. As in - 1. email1 2. email2 3. email3 etc.*/}
                {/* {
                   <FormControl
                        // readOnly
                        defaultValue={item.url}
                        aria-describedby="basic-addon2"
                        className="fs-4"
                        
                    >
                    </FormControl>
                } */}
                <div className="connected-item-check">
                    <SocialCheck />
                </div>
                {
                    item.logo && item.logo !== '' &&
                        <img className="webpage-logo" src={item.logo} alt="" />
                }
                <div className="connected-item-remove mt-4">Remove account</div>
                {/* <DropdownButton as={"ButtonGroup"}
                                key={"updateVisibility"}
                                id={`dropdownRadioGroup`}
                                variant={"outline-secondary"}
                                bsPrefix={'list-button list-dropdown'}
                                title={getVisibility(item.visibility)}
                >
                    
                    {RADIOS.map((radio, idx) => (
                        <Dropdown.Item
                            onClick={() => {
                                preprocess(USER_PROFILE_UPDATE_ACTION.UPDATE_VISIBILITY, type, {...item, 'visibility': radio.value, type: type})
                                    // handleAction(USER_PROFILE_UPDATE_ACTION.UPDATE_VISIBILITY, 
                                    //     {...item, 'visibility': radio.value, type: type});
                                }
                            }
                            key={idx}
                            eventKey={idx}
                            id={`radio-${idx}`}
                            label={ radio.name }
                            bsPrefix={'dropdown-item list-button list-dropdown-item'}
                        >
                            {radio.name}
                        </Dropdown.Item>
                    ))}
                </DropdownButton> */}
                {/* <Button className="fs-5 pt-3 pb-3 ps-5 pe-5 list-button"
                        name={'delete'}
                        onClick={() => {
                            // handleAction(USER_PROFILE_UPDATE_ACTION.DELETE, {
                            //     ...item, id: item.id, type: type})
                            preprocess(USER_PROFILE_UPDATE_ACTION.DELETE, type, {...item, type: type})
                        }}
                        variant={"outline-secondary"}>
                    <AiOutlineClose size={20}/>
                </Button> */}
            </div>
        });
    }

    const openPublicCard = () => {
        if (data.cardId === '' || data.cardId === null) {
            dispatch(ShowError('Please edit visibility to make info sharable on your digital contact card'));
        } else {
            window.open(window.location.origin + '/public-card?cardId=' + data.cardId, '_blank');
        }
    }

    const renderDomain = (domain, index) => {
        let domainIcon;
        switch (domain.domain) {
            case 'twitter.com':
                domainIcon = Twitter;
                break;
            case 'facebook.com':
                domainIcon = Facebook;
                break;
            case 'youtube.com':
                domainIcon = Youtube;
                break;
            case 'linkedin.com':
                domainIcon = Linkedin;
                break;
            case 'instagram.com':
                domainIcon = Instagram;
                break;
            default:
                break;
        }
        return (
            <div className="fs-2 connected-item me-4" key={index.toString()}>
                <div className="connected-item-check">
                    <SocialCheck />
                </div>
                <img src={domainIcon} className="social-media-icon" alt=""></img>
                <div className="connected-item-remove mt-4">Remove account</div>
            </div>
        )
    }

    return (
        <Container fluid className="vh-100 max-limited-page">
            <Header pluginShow searchChange={(text) => setSearchText(text)}/>
            <div className="page-content-with-header">
                <Accordion defaultActiveKey="0" className={"p-3"} flush>
                    <Accordion.Item eventKey="0" className={"mx-4 bg-light"}>
                        <Accordion.Header className={""}>
                            <div className={"fs-3"}>
                                <Media />
                                <span className="section-title">Social Media</span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className={"m-4"}>
                            {/* {
                                data['sm'] && data['sm'].length > 0 ?
                                <div className="d-flex flex-row">
                                    <div className="d-flex flex-1"></div>
                                    <div className="list-title">Visibility</div>
                                    <div className="list-title">Delete</div>
                                </div>
                                : <></>
                            } */}
                            <div className="d-flex flex-row flex-wrap">
                                {/* {
                                    handleType('sm')
                                } */}
                                {
                                    domains.map(renderDomain)
                                }
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Accordion defaultActiveKey="0" className={"p-3"} flush>
                    <Accordion.Item eventKey="0" className={"mx-4 bg-light"}>
                        <Accordion.Header className={""}>
                            <div className={"fs-3"}>
                                <Payment />
                                <span className="section-title">Payments</span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className={"m-4"}>
                            <div className="d-flex flex-row flex-wrap">
                                {/* {
                                    handleType('payment')
                                } */}
                                <>Nothing to show</>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Accordion defaultActiveKey="0" className={"p-3"} flush>
                    <Accordion.Item eventKey="0" className={"mx-4 bg-light"}>
                        <Accordion.Header className={""}>
                            <div className={"fs-3"}>
                                <NFT />
                                <span className="section-title">NFT</span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className={"m-4"}>
                            <div className="d-flex flex-row flex-wrap">
                                {/* {
                                    handleType('nft')
                                } */}
                                <>Nothing to show</>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Accordion defaultActiveKey="0" className={"p-3"} flush>
                    <Accordion.Item eventKey="0" className={"mx-4 bg-light"}>
                        <Accordion.Header className={""}>
                            <div className={"fs-3"}>
                                <Site />
                                <span className="section-title">Websites</span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className={"m-4"}>
                            <div className="d-flex flex-row flex-wrap">
                                {/* {
                                    handleType('domain')
                                } */}
                                <>Nothing to show</>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Accordion defaultActiveKey="0" className={"p-3"} flush>
                    <Accordion.Item eventKey="0" className={"mx-4 bg-light"}>
                        <Accordion.Header className={""}>
                            <div className={"fs-3"}>
                                <Other />
                                <span className="section-title">Other</span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className={"m-4"}>
                            <div className="d-flex flex-row flex-wrap">
                                {/* {
                                    handleType('other')
                                } */}
                                <>Nothing to show</>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </Container>
    );
}

export default UserWebPagesPage;
