// import {applyMiddleware, combineReducers, createStore} from "redux";
import {combineReducers, createStore} from "redux";
import {SpinnerReducer} from "./busySpinner/spinner-reducer";
import {ImageReducer} from "./image/image-reducer";
import {userReducer} from "./user/userReducer";
import {UIReducer} from "./ui/ui-reducer";
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// import logger from "redux-logger";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user']
}
const rootReducer = combineReducers({
    user: userReducer,
    BusySpinner: SpinnerReducer,
    image: ImageReducer,
    ui: UIReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

const configstore = () => {
    // let store = createStore(persistedReducer, applyMiddleware(logger));
    let store = createStore(persistedReducer);
    let persistor = persistStore(store);
    return {store, persistor};
};

export default configstore;
