const init_state = {
  show: false,
  url: null
};

export const ImageReducer = (state = init_state, action = {}) => {
  switch (action.type) {
      case "ZOOMIMAGE":
          return {
              ...state,
              show: true,
              url: action.payload
          };
      case "HIDEIMAGE":
          return {
              ...state,
              show: false,
              url: null
          };
      default:
          return state;
  }
};
