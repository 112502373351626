import Parse from "parse";


/**
 *  * <h2>DataVerifying</h2>
 * <p>
 *     Retrieves from the Server, the current list of profiles that need to be verified manually.
 *     The stats for each category of autoVerified, incompleteVerification, rejected,
 *     and totalProfilesToReview are also provided for display.
 * </p>
 * @param key
 * @returns {Promise<*>}
 * @constructor
 */
export const DataVerifying = async (key) => {
    try {
        return await Parse.Cloud.run("verifyUsers", {key: key}, {});
    } catch (err) {
        console.log("[debug] Data Verifying error : ", err);
    } 
};

/**
 *  * <h2>DataProfiling</h2>
 * <p>
 *     Retrieves from the Server, the current profile data for the amount of profiles requested.
 * </p>
 * @returns {Promise<*>}
 * @constructor
 */
export const DataProfiling = async (packet) => {
    try {
        return await Parse.Cloud.run("verifyUsersStart", packet, {});
    } catch (err) {
        console.log("[debug] Data Verifying error : ", err);
    }
}

/**
 *  * <h2>DataSubmitting</h2>
 * <p>
 *     Submit data to the Server, profiles data would be sent to the server.
 * </p>
 * @returns {Promise<*>}
 * @constructor
 */

 export const DataSubmitting = async (profiles, key, ignore = false) => {
    try {
        let submitProfiles = profiles.map(profile => {
            if (!!profile.modified) {
                return {
                    userId: profile.userId,
                    modified: !!profile.modified,
                    faceMatch: !!profile.faceMatch,
                    isAnId: !!profile.isAnId,
                    hasWords: !!profile.hasWords,
                    hasFirstName: !!profile.hasFirstName,
                    hasLastName: !!profile.hasLastName,
                    comments: profile.comments,
                    decision: profile.decision ? profile.decision : 'acceptBoth'
                }
            } else {
                return {
                    userId: profile.userId,
                    modified: !!profile.modified,
                    faceMatch: !!profile.faceMatch,
                    isAnId: !!profile.isAnId,
                    hasWords: !!profile.hasWords,
                    hasFirstName: !!profile.hasFirstName,
                    hasLastName: !!profile.hasLastName,
                    comments: profile.comments
                }
            }
            
        })
        if (ignore === true) {
            submitProfiles = submitProfiles.map(profile => {
                return {
                    ...profile,
                    modified: false
                }
            })
        }
        const data = {
            profiles: submitProfiles,
            key: key
        }
        return await Parse.Cloud.run("verifyUsersStop", data, {});
    } catch (err) {
        console.log("[debug] Data Submitting error : ", err);
    }
}

/**
 *  * <h2>VerifyUsersReleaseReviewers</h2>
 * <p>
 *     Retrieves from the Server, the current profile data for the amount of profiles requested.
 * </p>
 * @returns {Promise<*>}
 * @constructor
 */
 export const DataReleasing = async (profileIds, key) => {
    try {
        return await Parse.Cloud.run("verifyUsersReleaseReviewer", {key, profileIds}, {});
    } catch (err) {
        console.log("[debug] Data Releasing error : ", err);
    }
}
