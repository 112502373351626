import {useHistory, useLocation} from 'react-router-dom';
import React, {} from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { SetActiveNav } from '../../redux/ui/ui-actions';

/**
 * MenuItem returns a jsx NavLink element containing a customizable path, icon, and name
 * @param props - The Nav Object
 * @returns {JSX.Element}
 * @constructor
 */
const MenuItem = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [active, setActive] = useState(false);
    const activeNav = useSelector((state) => state.ui.activeNav);
    const renderNormalItem = (menuData) => (
        <div className="menu-content-normal">
            <div className='menu-svg-wrapper'>
                {menuData.svgIcon}
            </div>
            <div className="menu-item-title">{menuData.name}</div>
        </div>
    )
    useEffect(() => {
        if (activeNav === props.path) {
            setActive(true)
        } else {
            setActive(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeNav]);
    const renderActiveItem = (menuData) => (
        <div className="menu-content-active">
            <div className="middle">
                <div className='menu-svg-wrapper'>
                    {menuData.svgIcon}
                </div>
                <div className="menu-item-title">{menuData.name}</div>
            </div>
        </div>
    )
    const clickMenu = () => {
        dispatch(SetActiveNav(props.path));
        history.push(props.path);
    }
    return (
        <div className="menu-item-wrapper" onClick={clickMenu}>
            {
                active ?
                renderActiveItem(props)
                :
                renderNormalItem(props)
            }
        </div>
    )
}
export default MenuItem;
