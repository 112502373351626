import "./sidebar.css";
import React from "react";
import {useDispatch, useSelector} from "react-redux";


export const SidebarUtil = ({width, height, children}) => {
    // eslint-disable-next-line no-unused-vars
    const userData = useSelector((state) => state.user.user);
    // eslint-disable-next-line no-unused-vars
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <div
                className="side-bar"
            >
                <div className="content">{children}</div>
                {/* <div className="leaf-container">
                    <div id="sidebar-leaf"></div>
                </div> */}
            </div>
        </React.Fragment>
    );
};
