const init_state = {
    value: false,
};

export const SpinnerReducer = (state = init_state, action = {}) => {
    switch (action.type) {
        case "SHOWBUSY":
            return {
                ...state,
                value: true,
            };
        case "HIDEBUSY":
            return {
                ...state,
                value: false,
            };
        default:
            return state;
    }
};
