import React from 'react';

const Phone = () => {
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.353 1C17.054 1.411 19.978 4.331 20.393 8.032M13.353 4.543C15.124 4.887 16.508 6.272 16.853 8.043M10.0315 10.9724C14.0205 14.9604 14.9254 10.3467 17.4653 12.8848C19.9138 15.3328 21.3222 15.8232 18.2188 18.9247C17.8302 19.237 15.3613 22.9943 6.68447 14.3197C-1.99341 5.644 1.76157 3.17244 2.07394 2.78395C5.18377 -0.326154 5.66682 1.08938 8.11539 3.53733C10.6541 6.0765 6.04254 6.98441 10.0315 10.9724Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default Phone;