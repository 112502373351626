import React, {useEffect, useState} from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { PublicData } from './public-util';
import './user-public.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhoneAlt, faEnvelope, faGlobe} from "@fortawesome/free-solid-svg-icons";
import {AiOutlineCopy} from "react-icons/ai"
import PlaystoreDownload from '../../../assets/images/App Stores Android - Google Play - HORIZONTAL.png';
import AppstoreDownload from '../../../assets/images/App Stores Apple - HORIZONTAL.png';
import DefaultFace from '../../../assets/images/defaultFace.png';
import {useLocation} from 'react-router-dom';

const UserPublicCardPage = () => {

  const [data, setData] = useState(null);
  const location = useLocation();
  const loadData = (cardId) => {
    // sample Id === 'xo3k0aCE0C';
    PublicData(cardId).then(value => {
      if (value.success !== 0) {
        setData(value);
      }
    })
  }

  const openLink = (link) => {
    window.open(link);
  }

  useEffect(() => {
    const searchParam = location.search;
    const cardId = searchParam.split('=')[1];
    loadData(cardId);
  }, [location.search]);

  const copyClipboard = (text) => {
    navigator.clipboard.writeText(text);
  }

  return (
      <Container fluid className="wrapper wrapper-shadow">
        <div className="public-card-header">Digital Contact Card</div>
        {
          data &&
          <Container className="public-card-content">
            <Row>
              <Col className="d-flex flex-column align-items-center">
                <div className="public-card-row">
                  <div className="d-flex flex-row align-items-center justify-content-center">
                    {
                      data && data.selfieUrl ?
                      <img src={data.selfieUrl} className="img-avatar" alt="" />
                      :
                      <img src={DefaultFace} className="img-avatar" alt="" />
                    }
                    {
                      data ?
                      <div className="d-flex flex-column ms-3 flex-1">
                        <div className="user-name">{data.displayName}</div>
                        <div className="user-grape-score">{`${data.grapeScore}% Grape Score`}&trade;</div>
                      </div>
                      :
                      <div className="d-flex flex-column ms-3 flex-1">
                        <div className="user-grape-score">{`No personal info is currently shared.`}<sup>TM</sup></div>
                      </div>
                    }
                  </div>
                  <div className="section-title content-row">
                    Contact Info
                  </div>
                  <div className="content-bottom-border">
                  {
                    // eslint-disable-next-line array-callback-return
                    data.contactInfo.map((contact, index) => {
                      if (contact.type === 'address') {
                        return (
                          <div className="d-flex flex-row content-row align-items-center" key={index}>
                            <FontAwesomeIcon className={`m-2`}
                                    icon={faGlobe} size={"2x"} />
                            <div className="content-body">{contact.value}</div>
                          </div>
                        )
                      }
                      if (contact.type === 'website') {
                        return (
                          <div className="d-flex flex-row content-row align-items-center" key={index}>
                            <FontAwesomeIcon className={`m-2`}
                                    icon={faGlobe} size={"2x"} />
                            <div className="content-body">{contact.value}</div>
                          </div>
                        )
                      }
                      if (contact.type === 'phoneNumber') {
                        return (<div className="d-flex flex-row content-row align-items-center" key={index}>
                          <FontAwesomeIcon className={`m-2`}
                                  icon={faPhoneAlt} size={"2x"}/>
                          <div className="content-body">{contact.value}</div>
                        </div>)
                      }
                      if (contact.type === 'email') {
                        return (<div className="d-flex flex-row content-row align-items-center" key={index}>
                          <FontAwesomeIcon className={`m-2`}
                                  icon={faEnvelope} size={"2x"}/>
                          <div className="content-body">{contact.value}</div>
                        </div>)
                      }
                    })
                  }
                  </div>
                  <div className="content-row content-bottom-border">
                    <div className="section-title">
                      Social Media
                    </div>
                    {
                      data.links.sm && data.links.sm.length > 0 ?
                      // eslint-disable-next-line array-callback-return
                      data.links.sm.map((link, index) => {
                        return (
                          <img src={link.logo} className="content-logo" onClick={() => openLink(link.url)} alt="" key={index}/>
                        )
                      })
                      :
                      <h5>Nothing to show.</h5>
                    }
                  </div>
                  <div className="content-row content-bottom-border">
                    <div className="section-title">
                      Send Payment
                    </div> 
                    {
                      data.links.payment && data.links.payment.length > 0 ?
                      // eslint-disable-next-line array-callback-return
                      data.links.payment.map((link, index) => {
                        return (
                          <img src={link.logo} className="content-logo" onClick={() => openLink(link.url)} alt="" key={index}/>
                        )
                      })
                      :
                      <h5>Nothing to show.</h5>
                    }
                  </div>
                  <div className="content-row content-bottom-border">
                    <div className="section-title">
                      Websites
                    </div> 
                    {
                      data.links.payment && data.links.payment.length > 0 ?
                      // eslint-disable-next-line array-callback-return
                      data.links.payment.map((link, index) => {
                          return (
                            <div className="d-flex flex-row align-items-center" key={index}>
                              <AiOutlineCopy className={`m-2`} size={"20px"} style={{cursor: 'pointer'}} onClick={() => copyClipboard(link.url)} />
                              <div className="content-body flex-1">{link.url}</div>
                            </div>
                          )
                      })
                      :
                      <h5>Nothing to show.</h5>
                    }
                  </div>
                  <div className="content-row content-bottom-border">
                    <div className="section-title">
                      NFT
                    </div> 
                    {
                      data.links.nft && data.links.nft.length > 0 ?
                      // eslint-disable-next-line array-callback-return
                      data.links.nft.map((link, index) => {
                          return (
                            <div className="d-flex flex-row align-items-center" key={index}>
                              <AiOutlineCopy className={`m-2`} size={"20px"} style={{cursor: 'pointer'}} onClick={() => copyClipboard(link.url)} />
                              <div className="content-body flex-1">{link.url}</div>
                            </div>
                          )
                      })
                      :
                      <h5>Nothing to show.</h5>
                    }
                  </div>
                  <div className="content-row content-bottom-border">
                    <div className="section-title">
                      Other
                    </div> 
                    {
                      data.links.other && data.links.other.length > 0 ?
                      // eslint-disable-next-line array-callback-return
                      data.links.other.map((link, index) => {
                          return (
                            <div className="d-flex flex-row align-items-center" key={index}>
                              <AiOutlineCopy className={`m-2`} size={"20px"} style={{cursor: 'pointer'}} onClick={() => copyClipboard(link.url)} />
                              <div className="content-body flex-1">{link.url}</div>
                            </div>
                          )
                      })
                      :
                      <h5>Nothing to show.</h5>
                    }
                  </div>
                  <div className="download-title">
                    Use Grape ID&trade; for Free
                  </div>
                  <div className="d-flex flex-row justify-content-between">
                    <a href={"https://apps.apple.com/us/app/grape-id-inc/id1587046966"} target="_blank"> <img className="download-img" src={AppstoreDownload} alt="App Store link"/> </a>
                    <a href={"https://play.google.com/store/apps/details?id=com.grapeid.hybrid"} target="_blank"> <img className="download-img" src={PlaystoreDownload} alt=""/>  </a>
                  </div>
                </div>
                
              </Col>
            </Row>
          </Container>
        }
      </Container>
  )
}
export default UserPublicCardPage;