import { Route} from 'react-router-dom';
import {connect} from "react-redux";

export const GroupRoute = ({component: Component,...rest
                             }) => (
    <Route component={Component}/>
);

connect()(GroupRoute);

