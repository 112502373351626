import React from "react";
import "./header.css";
import {useDispatch, useSelector} from "react-redux";
import ServerImg from "../common/server-img";
import Cookies from "universal-cookie/es6";
import Parse from 'parse';
import {LogoutAction} from "../../redux/user/userActions";
import AccountRole from "./AccountRole/accountRole";
import ChromeIcon from "../../assets/images/Chrome Extension Plugin Icon.png";
import SearchBar from "../common/search-bar/search-bar";


/**
 * Basic Header component including title and subtitle.
 * @param props - title and subtitle
 * @returns {JSX.Element}
 * @constructor
 */
const Header = (props) => {
    const userData = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const createCookie = new Cookies();

    const logOut = async () => {
        try {
            await Parse.Cloud.run('logout',{key: userData.sessionToken}, {});
            localStorage.clear();
            createCookie.remove('grapeIdOrg');
            createCookie.remove('grapeIdOrgSessionId');
            dispatch(LogoutAction());
            console.log("User Logged out");
        } catch (e) {
            console.log(e);
        }

    }

    const changeSelector = (value) => {
        switch (value) {
            case 'logout':
                logOut();
                break;
        
            default:
                break;
        }
    }

    const installPlugin = () => {
        window.open('https://chrome.google.com/webstore/detail/grape-id/llaccemgfgnklplhffhenenjakaimmkj', '_blank');
    }

    return (
        <header className={`page-header-wrapper content-container-width`}>
            <div className="d-flex flex-row align-items-center flex-1">
                <div className="flex-1">
                    {props.pluginShow && (
                        <div className="sub-header-wrapper d-flex flex-row justify-content-between align-items-center pe-5">
                            <div className="d-flex flex-row justify-content-center align-items-center">
                                <div className="d-flex flex-row justify-content-center align-items-center header-button" onClick={() => installPlugin()}>
                                    <span className="plugin-install-title">INSTALL CHROME PLUGIN</span>
                                    <img src={ChromeIcon} style={{width: '50px', objectFit: 'contain'}} alt=""/>
                                </div>
                            </div>
                            <div>
                                <SearchBar className="header-title" onChange={(text) => {
                                    props.searchChange(text)
                                }}></SearchBar>
                            </div>
                        </div>
                    )}
                    {props.roleShow && <div className="d-flex flex-row"><div className="flex-1"><span></span></div><AccountRole /></div>}
                </div>
                <div className="d-flex flex-row justify-content-end align-items-center account-action">
                    <ServerImg src={userData.selfie} cacheKey="selfie-img" alt={userData.name} className="header-avatar ms-4"/>
                    <div className="mgs_itemText ms-4">
                        <select className="form-control data-input input-control header-action-selector" onChange={e => changeSelector(e.target.value)}>
                            <option value="name">{userData.name}</option>
                            <option value="logout">Log out</option>
                        </select>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
