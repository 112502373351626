import {useEffect, useState} from "react";
import Parse from 'parse'
import {LoginAction} from "../../redux/user/userActions";
import {useDispatch} from "react-redux";
// import {useHistory} from 'react-router-dom';
import {HideBusy, ShowBusy} from "../../redux/busySpinner/spinner-actions";
import {io} from "socket.io-client";
import Cookies from "universal-cookie/es6";

/**
 * <h2>LoginPage</h2>
 * <p> Displays the LoginPage Page, and creates a two-way connection the Server.</p>
 * @returns {JSX.Element}
 * @constructor
 */
// TODO: Make login.utils file for the login logistics and have the html pages grab it
const LoginPageTelegram = () => {
    const [qrCodeUrl, setqrCodeUrl] = useState("");
    const dispatch = useDispatch();
    // const history = useHistory();
    const createCookie = new Cookies();

    // k12 Login
    const k12Login = async () => {
        dispatch(ShowBusy());

        // await Parse.Cloud.run('k12Api', { apikey: process.env.REACT_APP_APIKEY, command: "getK12", action_id: 1 }).then((response) => {
        //   setqrCodeUrl(response.qrUrl)
        //   dispatch(HideBusy());
        // })

        // remove this return statement if you want to execute the socket code.
        // return;


        let socket;
        // Connect to createSocketKey via api key to obtain one-time token to connect to socket
        await Parse.Cloud.run('createSocketKey', {apikey: process.env.REACT_APP_APIKEY})
            .then(
                // Save key, and Access the Server
                function (res) {
                    const {socketConnectionKey} = res;
                    // console.log('res', socketConnectionKey);

                    // Connect to Socket
                    if (process.env.REACT_APP_ENVIRONMENT == "production") {
                        socket = io(process.env.REACT_APP_PROD_SERVER_URL,
                            {
                                extraHeaders: {
                                    "api-key": socketConnectionKey,  // only for orgs
                                }
                            });
                    } else {
                        socket = io(process.env.REACT_APP_DEV_SERVER_URL,

                            {
                                extraHeaders: {
                                    "api-key": socketConnectionKey,  // only for orgs
                                }
                            }
                        );
                    }

                    window.socket = socket;

                    // Testing socket connection
                    socket.on("connect", function () {
                        // console.log('Socket Connected');

                        const data = {
                            action_id: 1
                        }
                        socket.emit('getK12', data);
                        socket.on('k12Result', function (event) {
                            // console.log(event + "K12 qr code EVENT Fired");

                            dispatch(HideBusy());
                            setqrCodeUrl(event.data.qrUrl)
                        });


                        // Receive incoming 'k12Scanned' data
                        socket.on('k12Scanned', function (event) {
                            // alert('k12 Scanned result received (See Console Log)');
                            // console.log(event);

                            // Passing user data from the socket
                            let temp = {};

                            // TODO: Is there a purpose to saving two copies of data in temp?
                            const profile = event.data;
                            temp.user = profile;
                            temp.profile = profile;
                            temp.profileImage = profile.selfie;
                            temp.displayName = profile.name;
                            temp.userId = profile.userId;
                            temp.acctLevel = 'Free';

                            // Storing Details in Redux
                            dispatch(LoginAction(temp));

                            createCookie.set('grapeIdOrg', {
                                url: '',
                                value: profile.sessionToken
                            }, {path: '/', secure: true, maxAge: 600, sameSite: "none"});

                            // console.log(createCookie.get('grapeIdOrg'));

                            //TODO: Take out SessionToken details from userProfile
                            localStorage.setItem(
                                "session",
                                JSON.stringify({
                                    userProfile: temp.user,
                                }),
                            );
                            // console.log(localStorage.getItem('session'));
                            window.location = "https://telegram.org/";
                        });

                    });
                }, function (error) {
                    console.log("Error: " + JSON.stringify(error));
                }
            )
    };

    // Display busySpinner if there isn't a qr code
    useEffect(() => {
        k12Login().then(r => {
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Display Login Page
    return (
        <div id="loginPage" style={{backgroundColor: "#783995"}} className="showPage">
            {/*Logo*/}
            <div id="logoContainer">
                <div id="grapeLoginLogoTelegram"/>
                <div id="telegramLogo"/>
            </div>
            {/*Login Container*/}
            <div id="grapeLoginContent">
                <div id="loginOuter">
                    <span className="loginBoxTelegramTitle">
                        Scan Now to enter Grape ID&trade; Telegram Group
                    </span>
                    {/*QR Code*/}
                    <div className="loginBoxQR"
                         style={{
                             backgroundImage: "url(" + qrCodeUrl + ")",
                             backgroundPosition: "center",
                             backgroundSize: "31em"

                         }}
                    />
                    <div className="loginLargeText">
                        OR
                    </div>
                    {/*Absence of Chrome Plugin*/}
                    <div className="loginBoxTitle2">
                        Install the Chrome Plugin
                    </div>
                    <div id="login-cPlugin"/>
                    <div id="login-error-message"/>
                    {/*Footer*/}
                    <div id="loginBottomText">
                        <div id="loginBottomMsg1">
                            Don't have an account? &nbsp;&nbsp;Get the app for free:
                        </div>
                    </div>
                    <div id="loginLinkBox1"/>
                    <div id="loginLinkBox2"/>
                </div>
            </div>
        </div>
    );
};

export default LoginPageTelegram;
