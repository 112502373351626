import React from 'react';

const Delete = () => {
  return (
    <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5747 7.7175C16.5747 7.7175 16.0317 14.4525 15.7167 17.2895C15.5667 18.6445 14.7297 19.4385 13.3587 19.4635C10.7497 19.5105 8.1377 19.5135 5.5297 19.4585C4.2107 19.4315 3.3877 18.6275 3.2407 17.2965C2.9237 14.4345 2.3837 7.7175 2.3837 7.7175M17.958 4.489H1M14.6904 4.489C13.9054 4.489 13.2294 3.934 13.0754 3.165L12.8324 1.949C12.6824 1.388 12.1744 1 11.5954 1H7.3624C6.7834 1 6.2754 1.388 6.1254 1.949L5.8824 3.165C5.7284 3.934 5.0524 4.489 4.2674 4.489" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default Delete;