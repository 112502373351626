import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLongArrowAltLeft, faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons";
import {DataSubmitting} from "./verify-data-util";
import {cloneDeep} from 'lodash';
import {useDispatch, useSelector} from "react-redux";
import {HideBusy, ShowBusy} from "../../../../../redux/busySpinner/spinner-actions";
import {CloseButton, Modal} from 'react-bootstrap';
import './../admin-verify.css';
import { ZoomImage, HideImage } from "../../../../../redux/image/image-actions";
import fakeId from "../../../../../assets/images/fake_id.jpg"
import { confirmAlert } from 'react-confirm-alert';
import ReactImageMagnify from 'react-image-magnify';
import 'react-confirm-alert/src/react-confirm-alert.css';

const VerifyProcessPage = (props) => {
    const userData = useSelector((state) => state.user.user);
    console.log(userData);
    // User defined lists to manage their selections and finished values
    const [Selected, setSelected] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [index, setIndex] = useState(0);
    const [total, setTotal] = useState(0);
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const [rejects, setRejects] = useState(0);

    useEffect(() => {
        loading ? dispatch(HideBusy()) : dispatch(ShowBusy());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log("profiles === ", index, profiles)
        const lastProfile = profiles[profiles.length - 1];
        let rejects = 0;
        profiles.forEach(profile => profile.modified &&
            (profile.decision === 'rejectBoth' || profile.decision === 'rejectSelfie' || profile.decision === 'rejectID') ? rejects += 1 : rejects += 0);
        if (rejects > 2) {
            setRejects(rejects);
        }
        if (lastProfile && lastProfile.modified) {
            reachEndProfiles();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profiles]);

    const reachEndProfiles = () => {
        confirmAlert({
            title: 'You\'ve finished reviewing profiles. Do you want to close & send to the server?',
            message: ``,
            buttons: [
                {
                    label: 'Cancel',
                    className: 'confirm-cancel',
                    onClick: () => {
                        saveProfiles(true);
                        props.onHide();
                    }
                },
                {
                    label: 'OK',
                    className: 'confirm-ok',
                    onClick: () => {
                        saveProfiles();
                        props.onHide();
                    }
                }
            ]
          });
    }

    const loadData = () => {
        console.log("Load Data === ", props.data)
        setProfiles(props.data);
        setTotal(props.data.length);
        setIndex(0);
    }

    const handleImprove = (option) => {
        if (!profiles || !profiles[index]) return
        let newProfiles = cloneDeep(profiles);
        newProfiles = newProfiles.map((profile, idx) => {
            if (idx !== index) return profile; 
            const originProfile = props.data[idx];
            let newItem = {...profile}
            newItem[option] = !profile[option];

            newItem.modified = !!originProfile[option] !== newItem[option];
            return newItem;
        })
        setProfiles(newProfiles);
        console.log("Before change profile === ", profiles)
        console.log("After change profiles === ", newProfiles)
    }

    const changeComment = (comment) => {
        setComment(comment);
        if (!profiles || !profiles[index]) return
        // let newProfiles = cloneDeep(profiles);
        let newProfiles = profiles.map((profile, idx) => {
            if (idx !== index) return profile; 
            const originProfile = props.data[idx];
            let newItem = {...profile}
            newItem.comments = comment;

            newItem.modified = originProfile.comments !== newItem.comments;
            return newItem;
        })
        setProfiles(newProfiles);
    }

    const handleDecision = (option) => {
        if (total === 0 && option === 'acceptBoth') {
            props.onHide();
            changeIndex(1);
            return;
        }
        if (!profiles || !profiles[index]) return
        let newProfiles = cloneDeep(profiles);
        newProfiles = newProfiles.map((profile, idx) => {
            if (idx !== index) return profile; 
            const originProfile = props.data[idx];
            let newItem = {...profile};
            newItem.decision = option;
            newItem.modified = originProfile.decision !== newItem.decision;
            changeIndex(1);
            console.log("Clicked bottom button:", newItem.decision);
            return newItem;
        })
        setProfiles(newProfiles);
        // console.log("After Bottom Button clicked === ", profiles);
        // reviewComplete(index);
    }

    const reviewComplete = (index) => {
        if (index === profiles.length - 1) {
            if (window.confirm("You have reviewed all profiles, do you want to Close the Batch?")) {
                saveProfiles();
            }
        }
    }

    const getToggleClass = (option, subOption) => {
        const curProfile = profiles[index];
        if (curProfile === undefined || curProfile === {}) return '';
        if (option !== 'decision') {
            if (curProfile[option] === null) {
                return 'btn-gray-active';
            }
            if (!curProfile[option])
                return 'btn-red-active';
            else
                return 'btn-green-active';
        }
        else {
            if (subOption === 'acceptBoth' && (!curProfile[option] || curProfile[option] === subOption)) return 'btn-green-active';
            if (subOption !== 'acceptBoth' && curProfile[option] === subOption) return 'btn-red-active';
            return ''
        }
    }

    const getButtonClass = (direction) => {
        if (direction === 'left') {
            if (index > 0 && profiles.length > 1) return 'font-active';
            return 'font-deactive';
        }
        if (direction === 'right') {
            if (index < profiles.length - 1) return 'font-active';
            return 'font-deactive';
        }
    }

    const changeIndex = (increment) => {
        if (index === 0 && increment === -1) return;
        if (index === profiles.length - 1 && increment === 1) return
        if (total === 0) return
        setComment(profiles[index + increment].comments || '');
        setIndex(index + increment);
    }

    const saveProfiles = (ignore = false) => {
        if (total > 0) {
            dispatch(ShowBusy())
            DataSubmitting(profiles, userData.sessionToken, ignore).then(r => {
                dispatch(HideBusy())
                props.dataSent();
                props.onHide();
            })
        } else {
            props.dataSent();
            props.onHide();
        }
    }

    const confirmHide= () => {
        let changedProfiles = 0;
        profiles.forEach(profile => profile.modified ? changedProfiles += 1 : changedProfiles += 0);
        confirmAlert({
            title: 'You are closing your batch. Do you want to send your changes to the server?',
            message: `You changed ${changedProfiles} profiles`,
            buttons: [
                {
                    label: 'Ignore all changes',
                    className: 'confirm-cancel',
                    onClick: () => {
                        saveProfiles(true);
                        props.onHide();
                    }
                },
                {
                    label: 'Send my changes',
                    className: 'confirm-ok',
                    onClick: () => {
                        saveProfiles();
                        props.onHide();
                    }
                }
            ]
          });
        // if (window.confirm("Exit without Saving?")) {
            
        // }
    }

    const zoomImage = (url) => {
        if (!url) return
        dispatch(ZoomImage(url));
    }


    const closeZoom = () => {
        dispatch(HideImage());
    }
    
    return (
        <Modal
            {...props}
            onShow={() => loadData()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={"static"}
            bsPrefix={"modal process-modal"}
            >
            <Modal.Header>
                <CloseButton onClick={confirmHide}/>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-row justify-content-between w-100">
                    <div className={"fw-bolder font-28"}>Here's the auto-verification data:</div>
                    <div className={"d-flex justify-content-end align-items-center"}>
                        <div className={"fs-1 fw-bold me-3"} >{`${total > 0 ? index + 1 : index} of ${total}`}</div>
                        <FontAwesomeIcon className={`mgs_fa-icons m-2 ${getButtonClass('left')}`}
                            icon={faLongArrowAltLeft} size={"5x"}
                            onClick={() => changeIndex(-1)}/>
                        <FontAwesomeIcon className={`mgs_fa-icons m-2 ${getButtonClass('right')}`}
                            icon={faLongArrowAltRight} size={"5x"}
                            onClick={() => changeIndex(1)}/>
                    </div>
                </div>
                <div className={"p-3 row"}>
                    <button 
                        className={`btn btn-secondary btn-lg fw-bold fs-2 me-md-4 p-3 col ${getToggleClass('faceMatch')}`}
                        onClick={() => {handleImprove('faceMatch')}}
                        name={"faceMatch"}>Face Match
                    </button>
                    <button className={`btn btn-secondary btn-lg fw-bold fs-2 me-md-4 p-3 col ${getToggleClass('isAnId')}`}
                        onClick={() => {handleImprove('isAnId')}}
                        name={"isAnId"}>
                        Is it an ID?
                    </button>
                    <button className={`btn btn-secondary btn-lg fw-bold fs-2 me-md-4 p-3 col ${getToggleClass('hasWords')}`}
                        onClick={() => {handleImprove('hasWords')}}
                        name={"hasWords"}>
                        Words
                    </button>
                    <button className={`btn btn-secondary btn-lg fw-bold fs-2 me-md-4 p-3 col ${getToggleClass('hasFirstName')}`}
                        onClick={() => {handleImprove('hasFirstName')}}
                            name={"hasFirstName"}
                    > {profiles[index]?.firstName || "User First Name"}
                    </button>
                    <button className={`btn btn-secondary btn-lg fw-bold fs-2 me-md-4 p-3 col ${getToggleClass('hasLastName')}`}
                        onClick={() => {handleImprove('hasLastName')}}
                            name={"hasLastName"}
                    > {profiles[index]?.lastName || "User Last name"}
                    </button>
                </div>
                <div className={"d-flex flex-row modal-body main-body"}>
                    <div style={{width: '33%'}} className="d-flex justify-content-center align-items-center" onClick={() => zoomImage(profiles[index]?.selfie)}>
                        <ReactImageMagnify
                            imageClassName={"img-source"} enlargedImageClassName={"img-viewer"} {...{
                            smallImage: {
                                alt: 'Wristwatch by Ted Baker London',
                                isFluidWidth: true,
                                src: profiles[index]?.selfie
                            },
                            largeImage: {
                                src: profiles[index]?.selfie,
                                width: 600,
                                height: 450
                            }
                        }} />
                    </div>
                    <div style={{width: '33%'}} className="d-flex justify-content-center align-items-center" onClick={() => zoomImage(profiles[index]?.govId)}>
                        <ReactImageMagnify
                            imageClassName={"img-source"} {...{
                            smallImage: {
                                alt: 'Wristwatch by Ted Baker London',
                                isFluidWidth: true,
                                src: profiles[index]?.govId
                            },
                            largeImage: {
                                src: profiles[index]?.govId,
                                width: 600,
                                height: 450
                            }
                        }} />
                    </div>
                    {/* <img src={profiles[index]?.selfie} alt={""} className={"rounded mx-auto d-block p-1"}
                            style={{width: "33%", objectFit: 'contain', height: '180px',}} onClick={() => zoomImage(profiles[index]?.selfie)} onMouseMove={() => zoomImage(profiles[index]?.selfie)} /> */}
                    {/* <img src={profiles[index]?.govId} alt={""} className={"rounded mx-auto d-block p-1"} */}
                            {/* style={{width: "33%", objectFit: 'contain', height: '180px',}} onClick={() => zoomImage(profiles[index]?.govId)} onMouseMove={() => zoomImage(profiles[index]?.govId)} /> */}

                    <div className={"d-flex flex-column p-2  align-items-start gap-3"} style={{width: "33%"}}>
                        {
                            rejects !== 0 &&
                            <button className={"btn btn-lg fw-bold fs-2 me-md-4 p-2 border-1 w-100 btn-red-active white-pencil"}>
                                {`${rejects} Rejections`}
                            </button>
                        }
                        
                        <label htmlFor={"ver-comment"} className={"fs-4"}>Comment (internal use only):</label>
                        <div>
                            <textarea id={"ver-comment"} value={comment} onChange={(e) => {changeComment(e.target.value)}} placeholder={"Special comments?"} className={"me-md-4 fs-3 form-control border-1 border-dark rounded-4"} style={{height: "75px", width: "240px"}}/>
                        </div>
                    </div>
                </div>
                <div className={"modal-footer  container-fluid gap-4"}>
                    <h1 className={"modal-title fw-bolder row w-100"}>Do these images appear legitimate?</h1>
                    <button className={`btn btn-secondary btn-lg fw-bold fs-2 me-md-2 p-4 col`}
                            onClick={() => handleDecision('rejectSelfie')}>Reject Only the SELFIE
                    </button>
                    <button className={`btn btn-secondary btn-lg fw-bold fs-2 me-md-2 p-4 col`}
                            onClick={() => handleDecision('rejectID')}>Reject only the ID
                    </button>
                    <button className={`btn btn-secondary btn-lg fw-bold fs-2 me-md-2 p-5 col btn-red-active`}
                            onClick={() => handleDecision('rejectBoth')}>Reject
                    </button>
                    <button className={`btn btn-secondary btn-lg fw-bold fs-2 me-md-2 p-5 col btn-green-active`}
                            onClick={() => handleDecision('acceptBoth')}>Yes
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}


export default VerifyProcessPage;
