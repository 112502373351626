import React from 'react';

const SocialCheck = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8.02832" cy="8.10828" r="7.9336" fill="#3DB54A"/>
      <path d="M11.1817 6.13354L7.48031 9.83496L5.79785 8.1525" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default SocialCheck;