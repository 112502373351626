import React from 'react';

const Payment = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.1389 12.3958H16.0906C14.6042 12.3949 13.3994 11.1909 13.3985 9.70449C13.3985 8.21805 14.6042 7.01413 16.0906 7.01322H20.1389M16.5486 9.64288H16.2369M5.53561 5.53816H10.9346M6.24766 1H14.8911C17.7892 1 20.1388 3.34951 20.1388 6.24766V13.4247C20.1388 16.3229 17.7892 18.6724 14.8911 18.6724H6.24766C3.34951 18.6724 1 16.3229 1 13.4247V6.24766C1 3.34951 3.34951 1 6.24766 1Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default Payment;