import Parse from "parse";


/**
 *  * <h2>DataVerifying</h2>
 * <p>
 *     Retrieves from the Server, the current list of profiles that need to be verified manually.
 *     The stats for each category of autoVerified, incompleteVerification, rejected,
 *     and totalProfilesToReview are also provided for display.
 * </p>
 * @param key
 * @returns {Promise<*>}
 * @constructor
 */
export const DataProfile = async (key) => {

const keyId = {key: key};
console.log(keyId);
    try {
        return await Parse.Cloud.run("userProfileGet",keyId, {});
    } catch (err) {
        console.log("[debug] Data Verifying error : ", err);
    }
};

/**
 *  * <h2>DataSubmitting</h2>
 * <p>
 *     Submit data to the Server, profiles data would be sent to the server.
 * </p>
 * @returns {Promise<*>}
 * @constructor
 */

export const DataSubmitting = async (sendPacket) => {
    try {
        return await Parse.Cloud.run("userProfileUpdate", sendPacket);
    } catch (err) {
        console.log("[debug] Data Submitting error : ", err);
    }
}
