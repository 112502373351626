import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import BusySpineer from './utils/busy-spinner-modal'

export const Layout = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className='page-layout'>
        {children}
      </div>
      <BusySpineer />
    </>
  );
};