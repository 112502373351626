import React from 'react';

const Credential = () => {
  return (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7021 17C19.5242 17 21 15.5426 21 13.7431V11.1506C21 9.95283 20.999 10.1917 20.999 9.00103C20.999 7.81035 21 7.89115 21 6.85039L20.999 4.25686C20.999 2.45745 19.5221 1 17.7011 1H4.29892C2.47789 1 1.00105 2.45745 1.00105 4.25686L1 6.93485C1 7.95146 1 7.81035 1 9.00103C1 10.1917 1 9.00103 1 11.1506V13.7431C1 15.5426 2.47581 17 4.29788 17H17.7021Z" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.75 7C3.75 6.30964 4.30964 5.75 5 5.75H8C8.69036 5.75 9.25 6.30964 9.25 7V10C9.25 10.6904 8.69036 11.25 8 11.25H5C4.30964 11.25 3.75 10.6904 3.75 10V7Z" stroke="black" stroke-width="1.5"/>
    </svg>
  )
}

export default Credential;