import {useEffect, useState} from "react";
import Parse from 'parse'
import {LoginAction} from "../../redux/user/userActions";
import {useDispatch} from "react-redux";
import {useHistory} from 'react-router-dom';
import {HideBusy, ShowBusy} from "../../redux/busySpinner/spinner-actions";
import {io} from "socket.io-client";
import Cookies from "universal-cookie/es6";
import Logo from "../../icons/Logo"
import BusySpinner from "../../utils/busy-spinner";

/**
 * <h2>LoginPage</h2>
 * <p> Displays the LoginPage Page, and creates a two-way connection the Server.</p>
 * @returns {JSX.Element}
 * @constructor
 */
const LoginPage = () => {
    const [qrCodeUrl, setqrCodeUrl] = useState("");
    const [busyShow, setBusyShow] = useState(false)
    const dispatch = useDispatch();
    const history = useHistory();
    const createCookie = new Cookies();

    // k12 Login
    const k12Login = async () => {
        // dispatch(ShowBusy());
        setBusyShow(true);
        // await Parse.Cloud.run('k12Api', { apikey: process.env.REACT_APP_API_KEY, command: "getK12", action_id: 1 }).then((response) => {
        //   setqrCodeUrl(response.qrUrl)
        //   dispatch(HideBusy());
        // })

        // remove this return statement if you want to execute the socket code.
        // return;


        let socket;
        // Connect to createSocketKey via api key to obtain one-time token to connect to socket
        await Parse.Cloud.run('createSocketKey', {apikey: process.env.REACT_APP_APIKEY})
            .then(
                // Save key, and Access the Server
                function (res) {
                    console.log("Socket Res ------ ", res)
                    const {socketConnectionKey} = res;
                    // console.log('res', socketConnectionKey);

                    // Connect to Socket
                    if (process.env.REACT_APP_ENVIRONMENT == "production") {
                        socket = io(process.env.REACT_APP_PROD_SERVER_URL,
                            {
                                extraHeaders: {
                                    "api-key": socketConnectionKey,  // only for orgs
                                }
                            });
                            console.log("******************************** production ********************************")
                    } else {
                        socket = io(process.env.REACT_APP_DEV_SERVER_URL,

                            {
                                extraHeaders: {
                                    "api-key": socketConnectionKey,  // only for orgs
                                }
                            }
                        );
                        console.log("******************************** development ********************************")
                    }

                    window.socket = socket;

                    // Testing socket connection
                    socket.on("connect", function () {
                        // console.log('Socket Connected');

                        const data = {
                            action_id: 1
                        }
                        socket.emit('getK12', data);
                        socket.on('k12Result', function (event) {
                            // console.log(event + "K12 qr code EVENT Fired");

                            // dispatch(HideBusy());
                            setBusyShow(false);
                            setqrCodeUrl(event.data.qrUrl)
                        });


                        // Receive incoming 'k12Scanned' data
                        socket.on('k12Scanned', function (event) {
                            // alert('k12 Scanned result received (See Console Log)');
                            console.log(event);

                            // Passing user data from the socket
                            let temp = {};

                            // TODO: Is there a purpose to saving two copies of data in temp?
                            const profile = event.data;
                            temp.user = profile;
                            temp.profile = profile;
                            temp.profileImage = profile.selfie;
                            temp.displayName = profile.name;
                            temp.userId = profile.userId;
                            temp.acctLevel = 'Free';
                            temp.roles = profile.roles;

                            // Storing Details in Redux
                            dispatch(LoginAction(temp));

                            createCookie.set('grapeIdOrg', {
                                url: '',
                                value: profile.sessionToken
                            }, {path: '/', secure: true, maxAge: 600, sameSite: "none"});

                            localStorage.setItem(
                                "session",
                                JSON.stringify({
                                    userProfile: profile ,
                                }),
                            );

                            history.push("/my-profile");
                            window.postMessage('logged_in');
                            socket.disconnect();
                        });

                    });
                }, function (error) {
                    console.log("Error: " + JSON.stringify(error));
                }
            )
    };

    // Display busySpinner if there isn't a qr code
    useEffect(() => {
        k12Login().then(r => {
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const gotoApp = (store) => {
        if (store === 'play') {
            window.open('https://play.google.com/store/apps/details?id=com.grapeid.hybrid', '_blank');
        } else {
            window.open('https://apps.apple.com/us/app/grape-id-inc/id1587046966', '_blank');
        }
    }

    // Display Login Page
    return (
        <div id="loginPage" className="showPage">
            {/*Logo*/}
            {/* <div id="grapeLoginLogo"/> */}
            <Logo />
            {/*Login Container*/}
            
            <div id="loginOuter">
                <div className="loginBoxTitle1">
                    Scan With Your Grape ID<sup>TM</sup> 
                </div>
                <div className="loginBoxTitle1">
                    Mobile App To Log In.
                </div>
                {/*QR Code*/}
                <div className="qr-wrapper">
                    <div className="loginBoxQR"
                            style={{
                                backgroundImage: "url(" + qrCodeUrl + ")",
                                backgroundPosition: "center",
                                backgroundSize: 'contain'
                            }}
                    />
                    { busyShow && (
                        <div className="login-busy-wrapper">
                            <BusySpinner />
                        </div>
                    )}
                </div>
            </div>
            <div id="loginBottomText">
                <div id="loginBottomMsg1">
                    Don't Have The App?
                </div>
                <div id="loginBottomMsg2">
                    Get the app for free:
                </div>
            </div>
            <div className="login-apps">
                <div id="loginLinkBox2" onClick={() => gotoApp('app')}/>
                <div id="loginLinkBox1" onClick={() => gotoApp('play')}/>
            </div>
        </div>
    );
};

export default LoginPage;

