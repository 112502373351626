import React from 'react';
import ReactDom from 'react-dom';
import {Modal} from 'react-bootstrap';
import {useSelector} from "react-redux";
import BusySpinner from './busy-spinner';

const JSX_MODAL = () => {
  const displaySpinner = useSelector((state) => state.BusySpinner.value);
  return (
    <Modal
      show={displaySpinner}
      centered
      bsPrefix={"modal loading-modal"}
    >
      <BusySpinner />
    </Modal>
  )
}


export default JSX_MODAL;