import {Button, Form, FormControl, FormLabel, InputGroup, Modal, ModalBody, ModalFooter} from "react-bootstrap";
import React, {useState} from "react";
import EmailInput from "../../../utils/input/email-input";
// import PhoneInput from "../../../utils/input/phone-input";
import SearchAddressInput from "../../../utils/input/search-address-input";
import AddressAutoInput from '../../../utils/input/address-auto-input';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { USER_PROFILE_UPDATE_ACTION } from './constants';

const UserProcessModal = (props) => {
    const [validated, setValidated] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [values, setValues] = useState({
        email: '',
        phoneNumber: '',
        address: '',
        modalShow:true,
        addNewInfo: {},
    })
    // const [errors, setErrors] = useState({
    //     email: '',
    //     phoneNumber: '',
    //     address: '',
    //     inValidForm:''
    // })

    const resetModalFormValues = () => {
        props.handleShow(props.infoType + "ModalShow")
        setValues({
            ...values,
            email: '',
            phoneNumber: '',
            address: '',
        });
        setValidated(false)
        // setErrors({
        //     ...values,
        //     email: '',
        //     phoneNumber: '',
        //     address: '',
        //     inValidForm: '',
        // })
    }


    function handleAddInfo(newInfo, infoType, valid) {
        if (newInfo && newInfo !== '' && valid) {
            // Check for duplicates
            if (values.data && values.data.find(
                (infoItem) => {
                    return (infoItem.value || infoItem.primaryEmail === newInfo)
                })) {
                setValidated(false);
            }

            // Make the new info ready to send to server
            else {
                setValues({...values, addNewInfo: { type: infoType, value: newInfo}})
                setValidated(valid);
            }
        }
        // Not valid data
        else {
            setValidated(valid);
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (values.addNewInfo) {
            props.handleAction(USER_PROFILE_UPDATE_ACTION.ADD, values.addNewInfo);
            setValidated(true);
            props.handleShow(props.infoType + "ModalShow")
        }
        else {
            setValidated(false);
        }
    }

    return (
        <Modal
            show={props.modalShow}
            size={"lg"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={"static"}
        >
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <ModalBody className={"pe-auto"}>
                    <FormLabel className={"fs-3"}>{props.label}</FormLabel>
                    <InputGroup className={"ps-3 pe-3"} hasValidation>
                        {props.infoType === 'email' && <EmailInput onChange={() => null} email={handleAddInfo}/>}
                        {props.infoType === 'phoneNumber' && 
                            // <PhoneInput onChange={() => null} phone={handleAddInfo}/>
                            <PhoneInput containerClass={'phone-input-container'} country={'us'} placeholder="Enter phone number" onChange={(val) => {
                                handleAddInfo('+' + val.toString(), 'phoneNumber', true);
                                setValidated(true);
                            }}/>
                        }
                        {/* {props.infoType === 'address' &&
                            <SearchAddressInput onChange={() => null} address={handleAddInfo}/>
                        } */}
                        {props.infoType === 'address' &&
                            <AddressAutoInput onChange={(value) => handleAddInfo(value, 'address', true)} />
                        }
                        <FormControl.Feedback type={"invalid"} className={"fs-5"}>
                            {props.errorText}
                        </FormControl.Feedback>
                        {
                            props.infoType === 'phoneNumber' &&
                            <Form.Text muted className={"fs-5 p-1"}>
                                Please include a country code and phone number.
                            </Form.Text>
                        }
                    </InputGroup>
                </ModalBody>

                <ModalFooter>
                    <Button className={"fs-5"} variant={"outline-dark"} onClick={resetModalFormValues}>
                        Cancel
                    </Button>
                    <Button type={"submit"} className={"fs-5"} variant={"outline-dark"}
                            disabled={!validated && props.infoType !== 'address'}>Save</Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

export default UserProcessModal;
