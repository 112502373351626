import React, {useEffect, useRef, useState} from "react";
import Parse from 'parse';
import {useDispatch, useSelector} from "react-redux";
import RegistrationOrganizationUtil from "./register-organization-util";
import {HideBusy, ShowBusy} from "../../../redux/busySpinner/spinner-actions";
import { Container } from "react-bootstrap";
import "./business-register.css";
import PhoneInput from 'react-phone-input-2'
import ServerImg from "../../common/server-img";
import Role from "../../../icons/role";
import Upload from "../../../icons/upload";
import Organization from "../../../icons/organization";
import Credential from "../../../icons/credential";
import Email from "../../../icons/email";
import Address from "../../../icons/address";
import Verify from "../../../icons/verify";
import Comment from "../../../icons/comment";
import Header from "../../header/header";

const RegisterOrganizationPage = () => {
    // eslint-disable-next-line no-unused-vars
    const [user, setUser] = useState(useSelector((state) => state.user.user));
    const [Industry, setIndustry] = useState({});
    const [NumOfUsers, setNumOfUsers] = useState({});
    const [NumOfEmployees, setNumOfEmployees] = useState({});
    const [AnnualRevenue, setAnnualRevenue] = useState({});
    const [NumOfPhysicalLocation, setNumOfPhysicalLocation] = useState({});
    const [OrgFileTypes, setOrgFileTypes] = useState({});
    const [Roles, setRoles] = useState({});
    const [OrganizationTypes, setOrganizationTypes] = useState({});
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const logoRef = useRef(null);
    const [logoFile, setLogoFile] = useState();
    const [logoPreview, setLogoPreview] = useState()


    const [values, setValues] = useState({
        userName: "",
        workEmail: "",
        role: "",
        registerName: "",
        doingBusinessAs: "",
        typeOfOrganization: "",
        industry: "",
        websiteUrl: "",

        workPhone: "",
        logo: "",

        sameAsMailingAddress: false,
        mainNumber: "",
        mainCompanyEmail: "",
        mailingAddressFirst: "",
        mailingAddressSecond: "",
        mailingAddressCity: "",
        mailingAddressZip: "",
        mailingAddressState: "",
        mailingCountry: "",

        physicalAddressFirst: "",
        physicalAddressSecond: "",
        physicalAddressCity: "",
        physicalAddressZip: "",
        physicalAddressState: "",
        physicalCountry: "",

        annualRevenue: "",
        numberOfEmployees: "",
        numberOfUsers: "",
        numberOfPhysicalLocation: "",
        comment: "",

        uploadFiles: [],
    });

/////////////////////////////////
// add state for error handling
/////////////////////////////////
    const [error, setError] = useState({

        workEmail: "",
        workPhone: "",
        role: "",
        registerName: "",
        doingBusinessAs: "",
        typeOfOrganization: "",
        industry: "",
        websiteUrl: "",
        mainNumber: "",
        mainCompanyEmail: "",
        mailingAddressFirst: "",
        mailingAddressSecond: "",
        mailingCountry: "",
        mailingAddressCity: "",
        mailingAddressState: "",
        mailingAddressZip: "",
        physicalAddressFirst: "",
        physicalAddressSecond: "",
        physicalCountry: "",
        physicalAddressCity: "",
        physicalAddressState: "",
        physicalAddressZip: "",
        annualRevenue: "",
        numberOfEmployees: "",
        numberOfUsers: "",
        numberOfPhysicalLocation: "",
        uploadFiles: "",
    });

    useEffect(() => {
        setValues({
            ...values,
            industry: Object.keys(Industry).length ? "0" : undefined,
            annualRevenue: Object.keys(Industry).length ? "0" : undefined,
            numberOfEmployees: Object.keys(Industry).length ? "0" : undefined,
            numberOfPhysicalLocation: Object.keys(Industry).length ? "0" : undefined,
            typeOfOrganization: Object.keys(Industry).length ? "0" : undefined,
            numberOfUsers: Object.keys(Industry).length ? "0" : undefined,
            role: Object.keys(Roles).length ? "0" : undefined,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Industry, NumOfUsers, NumOfEmployees, AnnualRevenue, NumOfPhysicalLocation, OrgFileTypes, Roles, OrganizationTypes]);

    /**
     *
     * Failed to load resource: the server responded with a status of 400 (Bad Request) at getTextLists async
     * TODO: [debug] Data Populating error -
     Error: Cannot read property 'success' of undefined at handleError (http://localhost:3000/static/js/vendors~main.chunk.js:75060:17) at async DataPopulating (http://localhost:3000/static/js/main.chunk.js:1871:19) {message: "Cannot read property 'success' of undefined", code: 141, stack: "Error: Cannot read property 'success' of undefined…//localhost:3000/static/js/main.chunk.js:1871:19)"}
     message = "Cannot read property 'success' of undefined"
     code = 141
     stack = "Error: Cannot read property 'success' of undefined\n at handleError (http://localhost:3000/static/js/vendors~main.chunk.js:75060:17)\n at async DataPopulating (http://localhost:3000/static/js/main.chunk.js:1871:19)"
     */
    const DataPopulating = async () => {
        try {
            const res = await Parse.Cloud.run("getTextLists", {
                list_names: [
                    "OrgIndustries",
                    "OrgNumOfCustomers",
                    "OrgNumOfEmployees",
                    "OrgRevenueTypes",
                    "OrgNumOfLocations",
                    "OrgFileTypes",
                    "OrgTypes",
                    "OrgRoleTypes",
                ], key: user.sessionToken,
            }, {});
            setIndustry({...res.OrgIndustries});
            setNumOfUsers({...res.OrgNumOfCustomers});
            setNumOfEmployees(res.OrgNumOfEmployees);
            setAnnualRevenue({...res.OrgRevenueTypes});
            setNumOfPhysicalLocation(res.OrgNumOfLocations);
            setOrgFileTypes({...res.OrgFileTypes});
            setOrganizationTypes({...res.OrgTypes});
            setRoles({...res.OrgRoleTypes});
            //Updating values
            setLoading(false);
        } catch (err) {
            console.log("[debug] Data Populating error : ", err);
        }
    };

    const handleChange = (e) => {
        if (e.preventDefault) {
            e.preventDefault();
        }
        setValues({...values, [e.target.name]: e.target.value});
        setError({...error, [e.target.name]: ""});  // set error state empty when onchange trigger on that field
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // adding validations
        if (values.workEmail === "") {
            setError((pre) => ({...pre, workEmail: 'Please enter work email!'}))
        } else if (!/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(values.workEmail)) {
            setError((pre) => ({...pre, workEmail: "Please enter valid email!"}))
        } else if (values.workPhone === "") {
            setError((pre) => ({...pre, workPhone: "Please enter work phone!"}))
        } else if (values.role === "") {
            setError((pre) => ({...pre, role: "Please select role!"}))
        } else if (values.registerName === "") {
            setError((pre) => ({...pre, registerName: "Please enter register name!"}))
        } else if (values.doingBusinessAs === "") {
            setError((pre) => ({...pre, doingBusinessAs: "Please enter doing business as!"}))
        } else if (values.typeOfOrganization === "") {
            setError((pre) => ({...pre, typeOfOrganization: "Please select type of organization!"}))
        } else if (values.industry === "") {
            setError((pre) => ({...pre, industry: "Please select industry!"}))
        } else if (values.websiteUrl === "") {
            setError((pre) => ({...pre, websiteUrl: "Please enter website url!"}))
        } else if (values.mainNumber === "") {
            setError((pre) => ({...pre, mainNumber: "Please enter main number!"}))
        } else if (values.mainCompanyEmail === "") {
            setError((pre) => ({...pre, mainCompanyEmail: "Please enter main company mail!"}))
        } else if (values.mailingAddressFirst === "") {
            setError((pre) => ({...pre, mailingAddressFirst: "Please enter address!"}))
        } else if (values.mailingAddressSecond === "") {
            setError((pre) => ({...pre, mailingAddressSecond: "Please enter address!"}))
        } else if (values.mailingCountry === "") { 
            setError((pre) => ({...pre, mailingCountry: "Please enter country!"}))
        } else if (values.mailingAddressCity === "") {
            setError((pre) => ({...pre, mailingAddressCity: "Please enter city!"}))
        } else if (values.mailingAddressState === "") {
            setError((pre) => ({...pre, mailingAddressState: "Please enter state!"}))
        } else if (values.mailingAddressZip === "") {
            setError((pre) => ({...pre, mailingAddressZip: "Please enter zipcode!"}))
        } else if (values.physicalAddressFirst === "" && !values.sameAsMailingAddress) {
            setError((pre) => ({...pre, physicalAddressFirst: "Please enter address!"}))
        } else if (values.physicalAddressSecond === "" && !values.sameAsMailingAddress) {
            setError((pre) => ({...pre, physicalAddressSecond: "Please enter address!"}))
        } else if (values.physicalCountry === "" && !values.sameAsMailingAddress) {
            setError((pre) => ({...pre, physicalCountry: "Please enter country!"}))
        } else if (values.physicalAddressCity === "" && !values.sameAsMailingAddress) {
            setError((pre) => ({...pre, physicalAddressCity: "Please enter city!"}))
        } else if (values.physicalAddressState === "" && !values.sameAsMailingAddress) {
            setError((pre) => ({...pre, physicalAddressState: "Please enter state!"}))
        } else if (values.physicalAddressZip === "" && !values.sameAsMailingAddress) {
            setError((pre) => ({...pre, physicalAddressZip: "Please enter zipcode!"}))
        } else if (values.annualRevenue === "") {
            setError((pre) => ({...pre, annualRevenue: "Please enter select annual revenue!"}))
        } else if (values.numberOfEmployees === "") {
            setError((pre) => ({...pre, numberOfEmployees: "Please select number of employees!"}))
        } else if (values.numberOfUsers === "") {
            setError((pre) => ({...pre, numberOfUsers: "Please select number of users!"}))
        } else if (values.numberOfPhysicalLocation === "") {
            setError((pre) => ({...pre, numberOfPhysicalLocation: "Please select no. of locations!"}))
        } else if (values.uploadFiles.length < 3) {
            setError((pre) => ({...pre, uploadFiles: "Please upload at least three document!"}))
        } else {
            RegistrationOrganizationUtil({
                ...values, key: user.sessionToken}).then(r => {
            });
        }
    };

    // Form Validation
    // eslint-disable-next-line no-unused-vars
    const phoneValidation = (e) => {
        e.preventDefault();
        const phone = e.target.value;
        if (e.key === 8) {
            return;
        } else if (!Number(phone) && String(phone) && !phone.includes("-")) {
            return;
        }
        if (e.target.value.length === 3 || e.target.value.length === 7) {
            setValues({...values, [e.target.name]: (e.target.value += "-")});
        } else {
            setValues({...values, [e.target.name]: e.target.value});
        }
    };

    const zipCodeValidation = (e) => {
        const phone = e.target.value;
        if (e.keyCode === 8) {
            return false;
        } else if (!Number(phone)) {

        } else {
            e.preventDefault();
            setValues({...values, [e.target.name]: e.target.value});
            setError({...error, [e.target.name]: ""});  // set error state empty when onchange trigger on that field

        }
    };

    const duplicateMailingAddress = () => {
        setValues({
            ...values,
            physicalAddressFirst: values.mailingAddressFirst,
            physicalAddressSecond: values.mailingAddressSecond,
            physicalAddressCity: values.mailingAddressCity,
            physicalAddressState: values.mailingAddressState,
            physicalAddressZip: values.mailingAddressZip,
            physicalCountry: values.mailingCountry,
        });
    };

    const handleSameAsMailingAddress = (e) => {
        setValues({...values, sameAsMailingAddress: e.target.checked});
    };

    const manageFiles = (files) => {
        setError((...pre) => ({...pre, uploadFiles: ""}));  // set error state empty when onchange trigger on that field
        const initialFilesLength = values.uploadFiles.length;
        let filesLength = files.length;
        if (filesLength + initialFilesLength > 5) {
            alert("Cannot upload more than 5 file");
        } else {
            setValues({
                ...values,
                uploadFiles: [...values.uploadFiles, ...files],
            });
        }
    }

    const handleFileUploads = (e) => {
        e.preventDefault();
        manageFiles(e.target.files)
    };
    const handleFileDelete = (fileName) => {
        setValues({
            ...values,
            uploadFiles: values.uploadFiles.filter((item) => item.name !== fileName),
        });
    };


    useEffect(() => {
        DataPopulating().then(r => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (values.sameAsMailingAddress) {
            duplicateMailingAddress();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        values.mailingAddressFirst,
        values.mailingAddressSecond,
        values.mailingAddressCity,
        values.mailingAddressState,
        values.mailingAddressZip,
        values.sameAsMailingAddress]);

    useEffect(() => {
        if (values.sameAsMailingAddress) {
            duplicateMailingAddress();
        } else {
            setValues({
                ...values,
                physicalAddressFirst: "",
                physicalAddressSecond: "",
                physicalAddressCity: "",
                physicalAddressState: "",
                physicalAddressZip: "",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.sameAsMailingAddress]);

    useEffect(() => {
        if (!logoFile) {
            setLogoPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(logoFile)
        setLogoPreview(objectUrl)
        setValues({
            ...values,
            logo: objectUrl
        });
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoFile]);

    const handleLogoUpload = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setLogoFile(undefined)
            return
        }
        setLogoFile(e.target.files[0])
    }

    const selectLogo = () => {
        document.getElementById('selectedLogo').click();
    }

    const selectFiles = () => {
        document.getElementById('selectedFiles').click();
    }

    const handleDrag = (e, photoId) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            e.target.classList.add('active-drag')
        } else if (e.type === "dragleave") {
            e.target.classList.remove('active-drag')
        }
    }

    const handleDrop = (e, photoId) => {
        e.preventDefault();
        e.stopPropagation();
        if (photoId === 'logo') {
            e.target.classList.remove('active-drag')
        }
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            // at least one file has been dropped so do something
            if (photoId === 'logo') {
                setLogoFile(e.dataTransfer.files[0]);
            } else {
                manageFiles(e.dataTransfer.files);
            }
        }
    }

    return (
        <Container fluid className={"max-limited-page vh-100"}>
            <Header title="testHeader" roleShow/>
            <div className="page-content-with-header mx-5 pb-5">
                <form onSubmit={handleSubmit} className="form-info">
                    <div>
                        <div className="rsection-header">
                            <Role />
                            <span className="rsection-title">WHAT IS YOUR ROLE IN ORGANIZATION?</span>
                        </div>
                        <div className="d-flex flex-row justify-content-center align-items-center pt-3">
                            <div className="d-flex flex-row justify-content-center align-items-start flex-1 p-4">
                                <div className="d-flex align-items-start">
                                    <ServerImg className="avatar-image"
                                        src = {
                                            user.selfie
                                                ? user.selfie
                                                : "https://via.placeholder.com/95x95.png?"
                                        }
                                        alt={user.name}
                                        cacheKey="selfie-img"
                                    />
                                </div>
                                <div className="d-flex flex-column flex-1 justify-content-center align-items-start ps-4">
                                    <div className="rsection-sub-title mb-4">{user.name}</div>
                                    <select
                                        className="form-control data-input input-control ms-4 mb-3"
                                        name="role"
                                        id="role-in-organization__select"
                                        onChange={handleChange}
                                        // value={Roles[values.role]}
                                    >
                                        <option disabled defaultValue={true}>
                                            Select Role
                                        </option>
                                        {Object.keys(Roles).map((item, key) => (
                                            <option value={key} key={key}>
                                                {Roles[item]}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="error-text">{error.role}</span>
                                    <div className="form-group mb-3 ms-4">
                                        <input
                                            type="email"
                                            name="workEmail"
                                            onChange={handleChange}
                                            value={values.workEmail}
                                            className="brp-form-control form-control data-input input-control"
                                            placeholder="Work Email (if different)"
                                        />
                                        <span className="error-text">{error.workEmail}</span>
                                    </div>
                                    <div className="form-group ms-4">
                                        <PhoneInput 
                                            country={'us'}
                                            value={values.workPhone}
                                            placeholder="Work Phone Number"
                                            onChange={(val) => {
                                                console.log("Phone Input change ==== ", val);
                                                handleChange({
                                                    target: {
                                                        name: 'workPhone',
                                                        value: '+' + val.toString(),
                                                    }
                                                })
                                            }}
                                            containerClass={'data-input'}
                                            inputClass={'phone-input'}
                                        />
                                        <span className="error-text">{error.workPhone}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column justify-content-center align-items-center flex-1">
                                <div className="rsection-sub-title mb-3">UPLOAD YOUR BUSINESS LOGO</div>
                                <div className="d-flex flex-1"
                                    onDragEnter={(e) => handleDrag(e, 'logo')}
                                    onDragOver={(e) => handleDrag(e, 'logo')}
                                    onDragLeave={(e) => handleDrag(e, 'logo')}
                                    onDrop={(e) => handleDrop(e, 'logo')}
                                >
                                    <div className="d-flex flex-column justify-content-center align-items-center click-to-upload">
                                        {
                                          logoPreview ? <img className="no-pointer-events image-preview" src={logoPreview} alt=""></img> :  <Upload className="no-pointer-events" />
                                        }
                                        <div className="mt-4 file-click">
                                            <span className="click-text" onClick={() => selectLogo()}>Click To Upload</span> or drag and drop
                                            <input type="file" hidden id="selectedLogo" onChange={handleLogoUpload}/>
                                        </div>
                                        <div className="file-click no-pointer-events">
                                            SVG, PNG, JPG or GIF (Max 95 * 95px)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="rsection-header">
                            <Credential />
                            <span className="rsection-title">ORGANIZATION'S CREDENTIALS</span>
                        </div>
                        <div className="d-flex flex-row pt-4">
                            <div className="d-flex flex-column align-items-center flex-1">
                                <div className="form-group mb-3">
                                    <input
                                        type="text"
                                        placeholder="Registered Name"
                                        className="br-form-control form-control data-input input-control"
                                        name="registerName"
                                        maxLength="25"
                                        onChange={handleChange}
                                        value={values.registerName}
                                    />
                                    <span className="error-text">{error.registerName}</span>
                                </div>
                                <div className="form-group mb-3">
                                    <select
                                        name="industry"
                                        id="industry__select"
                                        className="br-form-control form-control data-input input-control"
                                        onChange={handleChange}
                                        value={values.industry}
                                    >

                                        {Object.keys(Industry).map((item, key) => (
                                            <option key={key} value={item}>{Industry[item]}</option>
                                        ))}
                                    </select>
                                    <span className="error-text">{error.industry}</span>
                                </div>
                                <div className="form-group mb-3">
                                    <input
                                        type="text"
                                        className="br-form-control form-control data-input input-control"
                                        name="websiteUrl"
                                        placeholder="example.com"
                                        onChange={handleChange}
                                        value={values.websiteUrl}
                                    />
                                    <span className="error-text">{error.websiteUrl}</span>
                                </div>
                            </div>
                            <div className="d-flex flex-column align-items-center flex-1">
                                <div className="form-group mb-3">
                                    <input
                                        type="text"
                                        className="br-form-control form-control data-input input-control"
                                        name="doingBusinessAs"
                                        placeholder="Doing Business As (optional)"
                                        onChange={handleChange}
                                        value={values.doingBusinessAs}
                                        maxLength="25"
                                    />
                                    <span className="error-text">{error.doingBusinessAs}</span>
                                </div>
                                <div className="form-group mb-3">
                                    <PhoneInput 
                                        country={'us'}
                                        value={values.mainNumber}
                                        placeholder="Main Phone: XXX - XXX - XXXX"
                                        onChange={(val) => {
                                            handleChange({
                                                target: {
                                                    name: 'mainNumber',
                                                    value: '+' + val.toString(),
                                                }
                                            })
                                        }}
                                        containerClass={'data-input org-phone-container'}
                                        inputClass={'phone-input org-phone'}
                                    />
                                    <span className="error-text">{error.mainNumber}</span>
                                </div>
                               
                            </div>
                            <div className="d-flex flex-column align-items-center flex-1">
                                <div className="form-group mb-3">
                                    <select
                                        name="typeOfOrganization"
                                        id="type-of-organization__select"
                                        className="br-form-control form-control input-control"
                                        value={values.typeOfOrganization}
                                        onChange={handleChange}
                                    >

                                        {Object.keys(OrganizationTypes).map((item, key) => (
                                            <option key={key} value={item}>{OrganizationTypes[item]}</option>
                                        ))}
                                    </select>
                                    <span className="error-text">{error.typeOfOrganization}</span>
                                </div>
                                <div className="form-group mb-3">
                                    <input
                                        type="email"
                                        className="br-form-control form-control data-input input-control"
                                        name="mainCompanyEmail"
                                        placeholder="example@gmail.com"
                                        maxLength="25"
                                        onChange={handleChange}
                                        value={values.mainCompanyEmail}
                                    />
                                    <span className="error-text">{error.mainCompanyEmail}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="rsection-header">
                            <Email />
                            <span className="rsection-title">MAILING ADDRESS</span>
                        </div>
                        <div className="d-flex flex-column pt-4">
                            <div className="d-flex flex-row mb-3 ps-4 pe-4">
                                <div className="d-flex flex-column ps-3 pe-3 flex-1">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="w-form-control form-control input-control w-100"
                                            name="mailingAddressFirst"
                                            placeholder="Address 1"
                                            onChange={handleChange}
                                            value={values.mailingAddressFirst}
                                        />
                                        <span className="error-text">{error.mailingAddressFirst}</span>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between mt-3">
                                        <div className="form-group flex-1 pe-3">
                                            <input
                                                type="text"
                                                className="w-form-control w-100 form-control input-control"
                                                placeholder="City"
                                                name="mailingAddressCity"
                                                onChange={handleChange}
                                                value={values.mailingAddressCity}
                                            />
                                            <span className="error-text">{error.mailingAddressCity}</span>
                                        </div>
                                        <div className="form-group flex-1 ps-3">
                                            <input
                                                type="text"
                                                className="w-form-control w-100 form-control zip-number-input input-control"
                                                placeholder="State"
                                                maxLength="6"
                                                name="mailingAddressState"
                                                onChange={handleChange}
                                                value={values.mailingAddressState}
                                            />
                                            <span className="error-text">{error.mailingAddressState}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column ps-3 pe-3 flex-1">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="w-form-control form-control input-control w-100"
                                            name="mailingAddressSecond"
                                            onChange={handleChange}
                                            value={values.mailingAddressSecond}
                                            placeholder="Address 2"
                                        />
                                        <span className="error-text">{error.mailingAddressSecond}</span>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between mt-3">
                                        <div className="form-group flex-1 pe-3">
                                            <input
                                                maxLength="6"
                                                type="text"
                                                name="mailingAddressZip"
                                                className="w-form-control w-100 form-control zip-number-input input-control"
                                                placeholder="Zip"
                                                onChange={zipCodeValidation}
                                                value={values.mailingAddressZip}
                                            />
                                            <span className="error-text">{error.mailingAddressZip}</span>
                                        </div>
                                        <div className="form-group flex-1 ps-3">
                                            <input
                                                type="text"
                                                className="w-form-control w-100 form-control input-control"
                                                name="mailingCountry"
                                                onChange={handleChange}
                                                value={values.mailingCountry}
                                                placeholder="country"
                                            />
                                            <span className="error-text">{error.mailingCountry}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="rsection-header">
                            <Address />
                            <span className="rsection-title">Physical ADDRESS</span>
                        </div>
                        <div className="d-flex flex-column pt-4">
                            <div className="d-flex flex-row mb-3 ps-4 pe-4">
                                <div className="d-flex flex-column ps-3 pe-3 flex-1">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="w-form-control form-control input-control w-100"
                                            name="mailingAddressFirst"
                                            placeholder="Address 1"
                                            onChange={handleChange}
                                            value={values.physicalAddressFirst}
                                            disabled={values.sameAsMailingAddress}
                                        />
                                        <span className="error-text">{error.physicalAddressFirst}</span>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between mt-3">
                                        <div className="form-group flex-1 pe-3">
                                            <input
                                                type="text"
                                                className="w-form-control w-100 form-control input-control"
                                                placeholder="City"
                                                name="mailingAddressCity"
                                                onChange={handleChange}
                                                disabled={values.sameAsMailingAddress}
                                                value={values.physicalAddressCity}
                                            />
                                            <span className="error-text">{error.physicalAddressCity}</span>
                                        </div>
                                        <div className="form-group flex-1 ps-3">
                                            <input
                                                type="text"
                                                className="w-form-control w-100 form-control zip-number-input input-control"
                                                placeholder="State"
                                                maxLength="6"
                                                name="mailingAddressState"
                                                onChange={handleChange}
                                                disabled={values.sameAsMailingAddress}
                                                value={values.physicalAddressState}
                                            />
                                            <span className="error-text">{error.physicalAddressState}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column ps-3 pe-3 flex-1">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="w-form-control form-control input-control w-100"
                                            name="mailingAddressSecond"
                                            onChange={handleChange}
                                            disabled={values.sameAsMailingAddress}
                                            value={values.physicalAddressSecond}
                                            placeholder="Address 2"
                                        />
                                        <span className="error-text">{error.physicalAddressSecond}</span>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between mt-3">
                                        <div className="form-group flex-1 pe-3">
                                            <input
                                                maxLength="6"
                                                type="text"
                                                name="mailingAddressZip"
                                                className="w-form-control w-100 form-control zip-number-input input-control"
                                                placeholder="Zip"
                                                onChange={zipCodeValidation}
                                                value={values.physicalAddressZip}
                                                disabled={values.sameAsMailingAddress}
                                            />
                                            <span className="error-text">{error.physicalAddressZip}</span>
                                        </div>
                                        <div className="form-group flex-1 ps-3">
                                            <input
                                                type="text"
                                                className="w-form-control w-100 form-control input-control"
                                                name="mailingCountry"
                                                onChange={handleChange}
                                                disabled={values.sameAsMailingAddress}
                                                value={values.physicalCountry}
                                                placeholder="country"
                                            />
                                            <span className="error-text">{error.physicalCountry}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ps-5 mb-3">
                                <label className="mb-0 same-as-emailing-address d-flex flex-row align-items-center"> 
                                    <input
                                        type="checkbox"
                                        name="sameAsMailingAddress"
                                        className="me-2"
                                        onChange={handleSameAsMailingAddress}
                                        value={values.sameAsMailingAddress}
                                    />
                                    <span>Same as Mailing Address</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="rsection-header">
                            <Organization />
                            <span className="rsection-title">ORGANIZATION'S OPERATIONS</span>
                        </div>
                        <div className="d-flex flex-row ps-4 pe-4 mt-4">
                            <div className="d-flex flex-column flex-1">
                                <div className="form-group mb-3 ps-2 pe-2">
                                    <select
                                        name="annualRevenue"
                                        id="annual-revenue__select"
                                        className="form-control input-control"
                                        onChange={handleChange}
                                        value={values.annualRevenue}
                                    >
                                        {Object.keys(AnnualRevenue).map((item, key) => (
                                            <option key={key} value={item}>
                                                {AnnualRevenue[item]}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="error-text">{error.annualRevenue}</span>
                                </div>
                                <div className="form-group mb-3 ps-2 pe-2">
                                    <select
                                        name="numberOfPhysicalLocation"
                                        id="number-of-physical-locations__select"
                                        className="form-control input-control"
                                        onChange={handleChange}
                                        value={values.numberOfPhysicalLocation}
                                    >
                                        {Object.keys(NumOfPhysicalLocation).map((item, key) => (
                                            <option key={key} value={item}>
                                                {NumOfPhysicalLocation[item]}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="error-text">{error.numberOfPhysicalLocation}</span>
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-1">
                                <div className="form-group ps-2 pe-2">
                                    <select
                                        name="numberOfEmployees"
                                        id="number-of-employees__select"
                                        className="form-control input-control"
                                        onChange={handleChange}
                                        value={values.numberOfEmployees}
                                    >
                                        {Object.keys(NumOfEmployees).map((item, key) => (
                                            <option key={key} value={item}>
                                                {NumOfEmployees[item]}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="error-text">{error.numberOfEmployees}</span>
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-1">
                                <div className="form-group ps-2 pe-2">
                                    <select
                                        name="numberOfUsers"
                                        id="number-of-customers__select"
                                        className="form-control input-control"
                                        onChange={handleChange}
                                        value={values.numberOfUsers}
                                    >
                                        {Object.keys(NumOfUsers).map((item, key) => (
                                            <option key={key} value={item}>
                                                {NumOfUsers[item]}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="error-text">{error.numberOfUsers}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="rsection-header">
                            <Verify />
                            <span className="rsection-title">VERIFY YOUR ORGANIZATION</span>
                        </div>
                        <div className="d-flex flex-row ps-4 pe-4 mt-4 verify-subtitle">
                            To verify your organization's authenticity, please upload at least 3 of the following documents:
                        </div>
                        <div className="d-flex flex-row ps-4 pe-4 mt-4 mb-4">
                            <div className="verify-subtitle">These files are queued for upload:</div>
                            <div className="d-flex flex-column flex-1 ps-5 pe-5">
                                <div className="d-flex flex-column justify-content-center align-items-center click-to-upload-verify p-3"
                                    onDragEnter={(e) => handleDrag(e, 'files')}
                                    onDragOver={(e) => handleDrag(e, 'files')}
                                    onDragLeave={(e) => handleDrag(e, 'files')}
                                    onDrop={(e) => handleDrop(e, 'files')}
                                >
                                    {
                                      values.uploadFiles && values.uploadFiles.length > 0 ? <span className="file-click">You selected {values.uploadFiles.length} files.</span> :  <Upload className="no-pointer-events" />
                                    }
                                    <div className="mt-4 file-click">
                                        <span className="click-text" onClick={selectFiles}>Click To Upload</span>
                                        <input type="file" hidden id="selectedFiles" multiple onChange={handleFileUploads}/>
                                    </div>
                                </div>
                                <span className="error-text">{error.uploadFiles}</span>
                                <div className="d-flex flex-column verify-types flex-1 mt-5 ps-5 pe-5 no-pointer-events">
                                    <div className="d-flex flex-row">
                                        <div className="flex-1 p-1">Original EIN Notice</div>
                                        <div className="flex-1 p-1">Business license</div>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <div className="flex-1 p-1">Certificate of good standing</div>
                                        <div className="flex-1 p-1">Professional license</div>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <div className="flex-1 p-1">Utility bill in business name</div>
                                        <div className="flex-1 p-1">EIN verification letter</div>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <div className="flex-1 p-1">Articles of incorporation</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="rsection-header">
                            <Comment />
                            <span className="rsection-title">COMMENTS/REQUESTS</span>
                        </div>
                        <div className="d-flex flex-row ps-4 pe-4 mt-4 comment-title">
                            Are you looking for a specific solution ? Trying to solve a specific problem ? Please specify below
                        </div>
                        <div className="d-flex flex-row ps-4 pe-4 mt-4 comment-title">
                            <textarea
                                name="comment"
                                id="comments-questions-textarea"
                                className="comments-questions-textarea input-control"
                                placeholder="Type your questions & comments here"
                                onChange={handleChange}
                                value={values.comment}
                            />
                        </div>
                        
                    </div>
                    {/* <div className="d-flex flex-row">
                        <article className="flex-1">
                            <h2 className="subtitle-1">What is your role in organization?</h2>
                            <div className="your-role__info">
                                <div className="d-flex flex-row justify-content-start align-items-center">
                                    <div className="your-role__info-img d-flex flex-column align-items-start">
                                        <ServerImg style={{
                                                height: "95px",
                                                objectFit: "contain"
                                            }}
                                            src={
                                                // user.selfie && user.profileImage.length
                                                user.selfie
                                                    ? user.selfie
                                                    : "https://via.placeholder.com/95x95.png?"
                                            }
                                            alt={user.name}
                                            cacheKey="selfie-img"
                                        />
                                        <span><i>{user.name}</i></span>
                                    </div>
                                    <div className="your-role__info-inputs ms-4">
                                        <select
                                            className="form-control data-input input-control"
                                            name="role"
                                            id="role-in-organization__select"
                                            onChange={handleChange}
                                            // value={Roles[values.role]}
                                        >
                                            <option disabled defaultValue={true}>
                                                Select Role
                                            </option>
                                            {Object.keys(Roles).map((item, key) => (
                                                <option value={key} key={key}>
                                                    {Roles[item]}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="error-text">{error.role}</span>
                                        <h4 className="mt-3">
                                            <b>Work Email:</b>
                                        </h4>
                                        <div className="form-group mb-2">
                                            <input
                                                type="email"
                                                name="workEmail"
                                                onChange={handleChange}
                                                value={values.workEmail}
                                                className="form-control data-input input-control"
                                                placeholder="Work Email (if different)"
                                            />
                                            <span className="error-text">{error.workEmail}</span>
                                        </div>
                                        <h4 className="mt-3">
                                            <b>Work Phone Number:</b>
                                        </h4>
                                        <div className="form-group">
                                            <PhoneInput 
                                                country={'us'}
                                                value={values.workPhone}
                                                placeholder="Work Phone Number"
                                                onChange={(val) => {
                                                    console.log("Phone Input change ==== ", val);
                                                    handleChange({
                                                        target: {
                                                            name: 'workPhone',
                                                            value: '+' + val.toString(),
                                                        }
                                                    })
                                                }}
                                                containerClass={'data-input'}
                                                inputClass={'phone-input'}
                                            />
                                            <span className="error-text">{error.workPhone}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <article className="flex-1">
                            <h2 className="subtitle-1">Upload your Business Logo</h2>
                            <div className="your-role__info mt-5">
                                <div className="d-flex flex-row justify-content-start align-items-center">
                                    <div className="your-role__info-img d-flex flex-column align-items-start">
                                        <img
                                            style={{
                                                height: "95px",
                                                objectFit: "contain"
                                            }}
                                            src={
                                                logoPreview
                                                    ? logoPreview
                                                    : "https://via.placeholder.com/95x95.png?"
                                            }
                                            alt={user.name}
                                        />
                                    </div>
                                    <div className="your-role__info-inputs ms-5">
                                        <div className="upload-files">
                                            <input
                                                id="selectedLogo"
                                                type="file"
                                                onChange={handleLogoUpload}
                                                style={{display: 'none'}}
                                            />
                                            <input type="button" value="Choose Logo..." onClick={() => {document.getElementById('selectedLogo').click()}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div> */}

                    {/* <article className="organization-credentials pt-5">
                        <h2 className="subtitle-1">Organization's Credentials</h2>
                        <div className="row">
                            <div className="col-12">
                                <label className="group-input-item">
                                    <span className="group-input-title">Registered Name:</span>
                                    <input
                                        type="text"
                                        placeholder="Registered Name"
                                        className="form-control input-control"
                                        name="registerName"
                                        maxLength="25"
                                        onChange={handleChange}
                                        value={values.registerName}
                                    />
                                    <span className="error-text">{error.registerName}</span>
                                </label>

                                <label className="group-input-item">
                                    <span className="group-input-title">Doing Business As <i>(optional)</i></span>
                                    <input
                                        type="text"
                                        className="form-control input-control"
                                        name="doingBusinessAs"
                                        onChange={handleChange}
                                        value={values.doingBusinessAs}
                                        maxLength="25"
                                    />
                                    <span className="error-text">{error.doingBusinessAs}</span>

                                </label>

                                <label className="group-input-item">
                                    <span className="group-input-title">Type of Organization:</span>
                                    <select
                                        name="typeOfOrganization"
                                        id="type-of-organization__select"
                                        className="form-control input-control"
                                        value={values.typeOfOrganization}
                                        onChange={handleChange}
                                    >

                                        {Object.keys(OrganizationTypes).map((item, key) => (
                                            <option key={key} value={item}>{OrganizationTypes[item]}</option>
                                        ))}
                                    </select>
                                    <span className="error-text">{error.typeOfOrganization}</span>

                                </label>
                                <label className="group-input-item">
                                    <span className="group-input-title">Industry:</span>
                                    <select
                                        name="industry"
                                        id="industry__select"
                                        className="form-control input-control"
                                        onChange={handleChange}
                                        value={values.industry}
                                    >

                                        {Object.keys(Industry).map((item, key) => (
                                            <option key={key} value={item}>{Industry[item]}</option>
                                        ))}
                                    </select>
                                    <span className="error-text">{error.industry}</span>

                                </label>
                                <label className="group-input-item">
                                    <span className="group-input-title">Website URL:</span>
                                    <input
                                        type="text"
                                        className="form-control input-control"
                                        name="websiteUrl"
                                        placeholder="example.com"
                                        onChange={handleChange}
                                        value={values.websiteUrl}
                                    />
                                    <span className="error-text">{error.websiteUrl}</span>

                                </label>
                                <label className="group-input-item">
                                    <span className="group-input-title">Main Phone Number:</span>
                                    <PhoneInput 
                                        country={'us'}
                                        value={values.mainNumber}
                                        placeholder="Main Phone: XXX - XXX - XXXX"
                                        onChange={(val) => {
                                            handleChange({
                                                target: {
                                                    name: 'mainNumber',
                                                    value: '+' + val.toString(),
                                                }
                                            })
                                        }}
                                        containerClass={'data-input'}
                                        inputClass={'phone-input group-phone-input'}
                                    />
                                    <span className="error-text">{error.mainNumber}</span>

                                </label>
                                <label className="group-input-item">
                                    <span className="group-input-title">Main Company Email:</span>
                                    <input
                                        type="email"
                                        className="form-control input-control"
                                        name="mainCompanyEmail"
                                        placeholder="example@gmail.com"
                                        maxLength="25"
                                        onChange={handleChange}
                                        value={values.mainCompanyEmail}
                                    />
                                    <span className="error-text">{error.mainCompanyEmail}</span>

                                </label>
                            </div>
                            <div className="col-6 pt-5">
                                <h2 className="subtitle-1">Mailing Address</h2>
                                <div className="mailing-address__inputs form-card">
                                    <div className="address__inputs-wrap">
                                        <label className="group-input-item">
                                            <input
                                                type="text"
                                                className="form-control input-control"
                                                name="mailingAddressFirst"
                                                placeholder="Address 1"
                                                onChange={handleChange}
                                                value={values.mailingAddressFirst}
                                            />
                                            <span className="error-text">{error.mailingAddressFirst}</span>
                                        </label>
                                        <label className="group-input-item">
                                            <input
                                                type="text"
                                                className="form-control input-control"
                                                name="mailingAddressSecond"
                                                onChange={handleChange}
                                                value={values.mailingAddressSecond}
                                                placeholder="Address 2"
                                            />
                                            <span className="error-text">{error.mailingAddressSecond}</span>

                                        </label>
                                        <label className="group-input-item">
                                                <input
                                                    type="text"
                                                    className="form-control input-control"
                                                    placeholder="City"
                                                    name="mailingAddressCity"
                                                    onChange={handleChange}
                                                    value={values.mailingAddressCity}
                                                />
                                                <span className="error-text">{error.mailingAddressCity}</span>

                                            </label>
                                        <div className="d-flex inputs-row">
                                            

                                            <label className="group-input-item">
                                                <input
                                                    type="text"
                                                    className="form-control zip-number-input input-control"
                                                    placeholder="State"
                                                    maxLength="6"
                                                    name="mailingAddressState"
                                                    onChange={handleChange}
                                                    value={values.mailingAddressState}
                                                />
                                                <span className="error-text">{error.mailingAddressState}</span>
                                            </label>


                                            <label className="group-input-item">
                                                <input
                                                    maxLength="6"
                                                    type="text"
                                                    name="mailingAddressZip"
                                                    className="form-control zip-number-input input-control"
                                                    placeholder="Zip"
                                                    onChange={zipCodeValidation}
                                                    value={values.mailingAddressZip}
                                                />
                                                <span className="error-text">{error.mailingAddressZip}</span>

                                            </label>
                                            <label className="group-input-item">
                                                <input
                                                    type="text"
                                                    className="form-control input-control"
                                                    name="mailingCountry"
                                                    onChange={handleChange}
                                                    value={values.mailingCountry}
                                                    placeholder="country"
                                                />
                                                <span className="error-text">{error.mailingCountry}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 pt-5">
                                <div className="physical-address__inputs form-card" style={{display: "inline-block"}}>
                                    <div className="d-flex flex-row justify-content-between">
                                        <h2 className="subtitle-1">
                                            Physical Address:
                                        </h2>
                                        
                                        <label className="mb-0 same-as-emailing-address d-flex flex-row align-items-center">
                                            <span>Same as Mailing Address</span>
                                            <input
                                                type="checkbox"
                                                name="sameAsMailingAddress"
                                                className="ms-2"
                                                onChange={handleSameAsMailingAddress}
                                                value={values.sameAsMailingAddress}
                                            />
                                        </label>
                                    </div>
                                    <div className="address__inputs-wrap">
                                        <label className="group-input-item">
                                            <input
                                                type="text"
                                                className="form-control input-control"
                                                name="physicalAddressFirst"
                                                onChange={handleChange}
                                                value={values.physicalAddressFirst}
                                                disabled={values.sameAsMailingAddress}
                                                placeholder="Address 1"
                                            />
                                            <span className="error-text">{error.physicalAddressFirst}</span>

                                        </label>
                                        <label className="group-input-item">
                                            <input
                                                type="text"
                                                className="form-control input-control"
                                                name="physicalAddressSecond"
                                                placeholder="Address 2"
                                                onChange={handleChange}
                                                disabled={values.sameAsMailingAddress}
                                                value={values.physicalAddressSecond}
                                            />
                                            <span className="error-text">{error.physicalAddressSecond}</span>

                                        </label>
                                        <label className="group-input-item">
                                            <input
                                                type="text"
                                                className="form-control input-control"
                                                placeholder="City"
                                                name="physicalAddressCity"
                                                onChange={handleChange}
                                                disabled={values.sameAsMailingAddress}
                                                value={values.physicalAddressCity}
                                            />
                                            <span className="error-text">{error.physicalAddressCity}</span>

                                        </label>
                                        
                                        <div className="d-flex inputs-row">
                                            
                                            <label className="group-input-item">
                                                <input
                                                    type="text"
                                                    className="form-control zip-number-input input-control"
                                                    placeholder="State"
                                                    maxLength="6"
                                                    name="physicalAddressState"
                                                    onChange={handleChange}
                                                    disabled={values.sameAsMailingAddress}
                                                    value={values.physicalAddressState}
                                                />
                                                <span className="error-text">{error.physicalAddressState}</span>

                                            </label>
                                            <label className="group-input-item">
                                                <input
                                                    type="text"
                                                    name="physicalAddressZip"
                                                    className="form-control zip-number-input input-control"
                                                    placeholder="Zip"
                                                    maxLength="6"
                                                    onChange={zipCodeValidation}
                                                    value={values.physicalAddressZip}
                                                    disabled={values.sameAsMailingAddress}
                                                />
                                                <span className="error-text">{error.physicalAddressZip}</span>

                                            </label>
                                            <label className="group-input-item">
                                                <input
                                                    type="text"
                                                    className="form-control input-control"
                                                    name="physicalCountry"
                                                    onChange={handleChange}
                                                    disabled={values.sameAsMailingAddress}
                                                    value={values.physicalCountry}
                                                    placeholder="country"
                                                />
                                                <span className="error-text">{error.physicalCountry}</span>

                                            </label>
                                        </div>
                                        </div>
                                    </div>
                            </div>
                            </div>
                    </article> */}

                    {/* <article className="organization-operations pt-5">
                        <h2 className="subtitle-1">Organization's Operations</h2>
                        <div className="organization-operations__inputs form-card">
                            <div className="inputs-column">
                                <label className="group-input-item">
                                    <span className="group-input-title">Annual Revenue:</span>
                                    <select
                                        name="annualRevenue"
                                        id="annual-revenue__select"
                                        className="form-control input-control"
                                        onChange={handleChange}
                                        value={values.annualRevenue}
                                    >
                                        {Object.keys(AnnualRevenue).map((item, key) => (
                                            <option key={key} value={item}>
                                                {AnnualRevenue[item]}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="error-text">{error.annualRevenue}</span>

                                </label>
                                <label className="group-input-item">
                                    <span className="group-input-title">Number of Employees:</span>
                                    <select
                                        name="numberOfEmployees"
                                        id="number-of-employees__select"
                                        className="form-control input-control"
                                        onChange={handleChange}
                                        value={values.numberOfEmployees}
                                    >
                                        {Object.keys(NumOfEmployees).map((item, key) => (
                                            <option key={key} value={item}>
                                                {NumOfEmployees[item]}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="error-text">{error.numberOfEmployees}</span>

                                </label>
                                <label className="group-input-item">
                                    <span className="group-input-title">Number of Customers/Users:</span>
                                    <select
                                        name="numberOfUsers"
                                        id="number-of-customers__select"
                                        className="form-control input-control"
                                        onChange={handleChange}
                                        value={values.numberOfUsers}
                                    >
                                        {Object.keys(NumOfUsers).map((item, key) => (
                                            <option key={key} value={item}>
                                                {NumOfUsers[item]}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="error-text">{error.numberOfUsers}</span>

                                </label>
                                <label className="group-input-item">
                                    <span className="group-input-title">Number of Physical Locations:</span>
                                    <select
                                        name="numberOfPhysicalLocation"
                                        id="number-of-physical-locations__select"
                                        className="form-control input-control"
                                        onChange={handleChange}
                                        value={values.numberOfPhysicalLocation}
                                    >
                                        {Object.keys(NumOfPhysicalLocation).map((item, key) => (
                                            <option key={key} value={item}>
                                                {NumOfPhysicalLocation[item]}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="error-text">{error.numberOfPhysicalLocation}</span>

                                </label>
                            </div>
                        </div>
                    </article> */}

                    {/* <article className="verify-your-organization pt-5">
                        <h2 className="subtitle-1">Verify Your Organization</h2>
                        <div className="form-card">
                            <div className="row">
                                <div className="col-6" style={{fontSize: '15px'}}>
                                    <b>
                                        To verify your organization's authenticity, please upload
                                        <br/> <u>at least 3</u> of the following documents:
                                    </b>
                                    <ul className="//reuired-files-list">

                                        {Object.keys(OrgFileTypes).map((item, key) => (
                                            <li key={key}>{OrgFileTypes[item]}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="col-6">
                                    <div className="upload-files">
                                        <div className="upload-area">
                                            <input
                                                id="selectedFile"
                                                type="file"
                                                multiple
                                                onChange={handleFileUploads}
                                                style={{display: 'none'}}
                                            />
                                            <input type="button" value="Choose Files..." onClick={() => {document.getElementById('selectedFile').click()}}/>
                                        </div>
                                    </div>
                                    <div className="uploaded-files">
                                        <div className="d-flex uploaded-files__heading">
                                            <span>These files are queued for upload:</span>
                                        </div>
                                        <div className="uploaded-files__list">
                                            {values.uploadFiles.length
                                                ? values.uploadFiles.map((item, key) => (
                                                    <li onClick={() => handleFileDelete(item.name)}
                                                        className="uploaded-files__file" key={key}>
                                                        <span className="uploaded-files__file-name">
                                                            {item.name}
                                                        </span>
                                                        <div className="uploaded-files__file-delete-btn">
                                                        </div>
                                                    </li>
                                                ))
                                                : null}
                                        </div>
                                    </div>
                                    <span className="error-text">{error.uploadFiles}</span>

                                </div>
                            </div>
                        </div>
                    </article> */}

                    {/* <article className="comments-requests pt-5">
                        <h2 className="subtitle-1">Comments / Requests</h2>
                        <div className="form-card">
                            <div className="row">
                                <div className="col-12">
                                    <p style={{fontSize: '15px'}}q>
                                        Are you looking for a specific solution ? Trying to solve a
                                        <br/> specific problem ? Please specify below
                                    </p>
                                    <textarea
                                        name="comment"
                                        id="comments-questions-textarea"
                                        className="comments-questions-textarea input-control"
                                        placeholder="Type your questions & comments here"
                                        onChange={handleChange}
                                        value={values.comment}
                                    />
                                </div>
                            </div>
                        </div>
                    </article> */}

                        <div className="d-flex justify-content-end justify-content-start mt-5 pe-5">
                            <button className="btn btn-success submit-btn reg-submit" onClick={handleSubmit}>
                                SUBMIT REGISTRATION
                            </button>
                        </div>
                </form>
            </div>
        </Container>
    );
};

export default RegisterOrganizationPage;
