import React from "react";

const navRolesBusiness = [
    {
        path: "/comp-profile",
        name: "Company Profile",
        // faIcon: <FontAwesomeIcon icon={faIdCardAlt} size={"2x"}/>,
        svgIcon: <div className="menu-svg-icon" id="connect-icon"></div>
    }
]


export default navRolesBusiness;
