import React from 'react';
import {Modal} from 'react-bootstrap';
import {useSelector, useDispatch} from "react-redux";
import { HideImage } from '../redux/image/image-actions';

const IMAGE_MODAL = () => {
  const image = useSelector((state) => state.image);
  const dispatch = useDispatch();
  return (
    <Modal
      show={image.show}
      onHide={() => dispatch(HideImage())}
      centered
      bsPrefix={"modal loading-modal"}
    >
      <img src={image.url} alt="" />
    </Modal>
  )
}


export default IMAGE_MODAL;