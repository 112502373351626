export const S3Upload = async (signedUrl, imgdata) => {
    try {
        const ret = await fetch(signedUrl, {
            method: "PUT",
            timeout: 0,
            headers: {
                "Content-Type": imgdata.type,
            },
            processData: false,
            body: imgdata,
        });
        if (ret.ok) {
            return {success: 1};
        } else {
            return {success: 0};
        }
    } catch (err) {
        console.log("[debug] s3upload ", err);
        return {success: 0};
    }
};
