import React from 'react';

const Logo = ({width, height}) => {
  return (
    <svg width={width || 155} height={height || 68} viewBox="0 0 155 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.6072 14.5992C34.6072 14.5992 31.946 0 49.5603 0C49.5603 0 51.395 14.5992 34.6072 14.5992Z" fill="#3DB54A"/>
      <path d="M37.8429 30.1667V30.108C37.6319 17.8559 28.1653 11.4424 18.9215 11.4424C9.67761 11.4424 0.205158 17.8559 0 30.108V30.1667V30.2253C0.205158 42.4774 9.67761 48.8909 18.9215 48.8909C21.7937 48.8909 24.6835 48.269 27.3154 47.0543C28.9215 56.5485 17.6436 65.5204 8.49355 59.2125C4.91794 56.8184 1.16061 62.5396 4.80657 64.875C8.03048 67.0108 11.8523 68.114 15.8441 67.9908C28.5815 67.9556 37.1805 54.1016 33.1594 42.7825C35.9379 39.7195 37.755 35.5181 37.8429 30.2312V30.1725V30.1667ZM18.9156 42.1312C12.9719 42.1312 6.9109 38.4286 6.74091 30.1667C6.9109 21.8989 12.9719 18.1963 18.9156 18.1963C24.8593 18.1963 30.9203 21.8989 31.0903 30.1667C30.9203 38.4345 24.8593 42.1312 18.9156 42.1312Z" fill="#783995"/>
      <path d="M53.9747 24.5157C53.9747 24.4747 53.9454 24.4453 53.9043 24.4453H46.6359C46.6359 24.4453 46.6241 24.4395 46.6183 24.4395C43.6992 24.4395 41.3311 26.8101 41.3311 29.7322V41.8728C41.3311 41.9139 41.3604 41.9432 41.4014 41.9432C44.3205 41.9432 46.6886 39.5726 46.6886 36.6504V32.6662H46.6945C46.6945 29.9611 49.5491 29.744 49.5491 29.744C52.0638 29.3274 53.9805 27.1504 53.9805 24.5216" fill="#783995"/>
      <path d="M80.7207 42.0138V40.7874C81.9341 41.4035 83.3116 41.7497 84.7711 41.7497C89.7594 41.7497 93.7981 37.7009 93.7981 32.7132C93.7981 27.7256 89.7535 23.6709 84.7711 23.6709C83.8098 23.6709 82.8895 23.8235 82.022 24.0993C78.3819 25.2611 75.7442 28.6762 75.7442 32.7132L75.6797 47.377C78.6398 47.377 80.7266 44.977 80.7266 42.0138M84.777 28.3417C87.1861 28.3417 89.1439 30.3016 89.1439 32.7132C89.1439 35.1249 87.1861 37.0848 84.777 37.0848C83.0595 37.0848 81.5765 36.0931 80.8614 34.6496C80.5742 34.0687 80.4159 33.4115 80.4159 32.7191C80.4159 31.9504 80.6152 31.2287 80.9669 30.6008C81.7113 29.2571 83.1416 28.3476 84.7829 28.3476" fill="#783995"/>
      <path d="M72.2571 41.1043L72.1926 32.7132C72.1926 28.682 69.5548 25.267 65.9148 24.0993C65.0531 23.8235 64.1269 23.6709 63.1656 23.6709C58.1774 23.6709 54.1387 27.7197 54.1387 32.7074C54.1387 37.695 58.1832 41.7439 63.1656 41.7439C64.6252 41.7439 65.9968 41.3976 67.216 40.7815V40.7522C68.2536 41.738 69.6135 41.9023 72.2571 41.0984M66.9816 30.5891C67.3333 31.2169 67.5267 31.9387 67.5267 32.7074C67.5267 33.3998 67.3626 34.057 67.0812 34.6379C66.3661 36.0814 64.8831 37.073 63.1656 37.073C60.7565 37.073 58.7987 35.1191 58.7987 32.7015C58.7987 30.284 60.7565 28.33 63.1656 28.33C64.8069 28.33 66.2372 29.2395 66.9816 30.5832" fill="#783995"/>
      <path d="M123.811 41.0048V25.5019C123.811 21.77 126.835 18.7422 130.563 18.7422C130.61 18.7422 130.651 18.7833 130.651 18.8302V34.333C130.651 38.065 127.626 41.0928 123.898 41.0928C123.852 41.0928 123.811 41.0517 123.811 41.0048Z" fill="#3DB54A"/>
      <path d="M134.068 18.6484H142.597C150.434 18.6484 155 23.1843 155 29.5509V29.6154C155 35.982 150.376 40.7057 142.474 40.7057H134.068V18.6484ZM140.176 24.0351V35.2838H142.662C146.343 35.2838 148.769 33.2652 148.769 29.7035V29.6389C148.769 26.1123 146.343 24.0292 142.662 24.0292H140.176V24.0351Z" fill="#3DB54A"/>
      <path d="M108.517 35.8762C107.732 36.6214 106.676 37.0791 105.51 37.0791C104.215 37.0791 103.048 36.5099 102.251 35.6122L107.233 34.3036V34.2919L109.777 33.6288L110.885 33.3413L112.778 32.8425C113.857 32.5433 114.513 31.4284 114.22 30.3487C113.165 26.429 109.537 23.5655 105.264 23.677C100.639 23.8002 96.782 27.5087 96.4947 32.1325C96.1665 37.3843 100.328 41.7499 105.504 41.7499C109.93 41.7499 113.617 38.5578 114.385 34.3447L108.511 35.8762H108.517ZM101.155 32.4142C101.166 32.203 101.196 31.9917 101.237 31.7922C101.278 31.5868 101.342 31.3873 101.407 31.1996C101.524 30.8768 101.682 30.5776 101.864 30.2959C102.649 29.1223 103.986 28.3536 105.504 28.3536C105.979 28.3536 106.43 28.4299 106.858 28.5649C107.79 28.87 108.587 29.4861 109.127 30.2842L101.149 32.4259L101.155 32.4142Z" fill="#783995"/>
    </svg>
  )
}

export default Logo;