export const ShowError = (message) => ({
  type: "SHOWERROR",
  payload: message
});

export const HideError = () => ({
  type: "HIDEERROR",
});

export const SetNavRoles = (roles) => ({
  type: "SETNAVROLES",
  payload: roles
});

export const SetActiveNav = (nav) => ({
  type: "ACTIVENAV",
  payload: nav
})

export const SetAccountRole = (role) => ({
  type: "ACCOUNTROLE",
  payload: role
})