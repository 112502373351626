import {Container, Row, Col} from 'react-bootstrap';
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter,} from "@fortawesome/free-solid-svg-icons";
import {DataReleasing} from './verify-data-util';
import './../admin-verify.css';
import { useEffect, useState } from 'react';
/**
 * <h2>AutoVerifiedUserList</h2>
 * <p>
 *     Sorts through a list of profiles and display's their name, status, and date.
 *      The list is sorted by oldest date.
 *  </p>
 * @returns {JSX.Element}
 * @constructor
 * @param profiles
 */
const VerifyUserList = (profiles) => {
    const userData = useSelector((state) => state.user.user);
    const [displayList, setDisplayList] = useState([]);

    useEffect(() => {
        setDisplayList(profiles.profiles)
    }, [profiles]);

    const releaseUser = (user) => {
        DataReleasing(
            [user.userId], userData.sessionToken
        ).then(r => {
            const filteredList = displayList.filter(item => item.userId !== user.userId);
            setDisplayList(filteredList)    
        })
    }

    const releaseAll = () => {
        const profileIds = displayList.map(item => item.userId);
        DataReleasing(
            profileIds, userData.sessionToken
        ).then(r => {
            setDisplayList([])    
        })
    }

    return (
        <Container>
            <Row>
                <Col className="fs-2 fw-bold"><span className="text-decoration-underline">Name</span></Col>
                <Col className="fs-2 fw-bold text-center"><span className="text-decoration-underline">User Email</span></Col>
                <Col className="fs-2 fw-bold text-center">
                    <span className="text-decoration-underline">Status</span>
                </Col>
                <Col className="fs-2 fw-bold text-center"><span className="text-decoration-underline">Date</span></Col>
                <Col className="fs-2 fw-bold text-center"><span className="text-decoration-underline">Reviewer</span></Col>
                <Col md={3} className="fs-2 fw-bold text-center">
                    {
                        displayList.length > 0 &&
                        <button onClick={() => releaseAll()} className="release-btn fw-bold all">Release all</button>
                    }
                </Col>
            </Row>
            {
                displayList.map((user,index) => (
                    <Row key={index}>
                        <Col className="fs-2">{user.firstName} {user.lastName}</Col>
                        <Col className="fs-2">{user.email}</Col>
                        <Col className="fs-2 text-center">{user.status}</Col>
                        <Col className="fs-2 text-center">{user.date && user.date}</Col>
                        <Col className="fs-2 text-center">{user.reviewerName}</Col>
                        <Col md={3} className="fs-2 text-center"><button onClick={() => releaseUser(user)}className="release-btn individual">Release Reviewer</button></Col>
                    </Row>
                ))
            }
            {
                displayList.length === 0 &&
                <Row>
                    <Col className="fs-2">No profiles in review.</Col>
                </Row>
            }
        </Container>
        // <section className={"li-mgmt-main"}>
        //     <div className={"text-nowrap p-1 fs-2 li-mgmt-header"}>
        //         <div>Name</div>
        //         <div>Status</div>
        //         <div>Date</div>
        //     </div>
        //     <div className={"li-mgmt-rows"}>
        //         {profiles.profiles.map((user, index) => (
        //             <label
        //                 htmlFor={index.toString()}
        //                 onClick={showSingleUser}
        //                 key={index}
        //                 className={"btn btn-outline-success"}>
        //                 <div className={"li-mgmt-row"} id={index.toString()}>
        //                     <div className={"li-mgmt-user-name"}>
        //                         {user.firstName} {user.lastName}
        //                     </div>
        //                     <div className={"li-mgmt-user-score"}>{user.status}
        //                     </div>
        //                     {/*TODO: Change to actual Date*/}
        //                     <div className={"li-mgmt-user-title"}>{user.date}</div>
        //                 </div>

        //             </label>
        //         ))}
        //     </div>
        // </section>
    );
};

export default VerifyUserList;



