import React from "react";

// TODO: Move the "register a business nav object" to the business role, after it's implemented

const navRolesBase = [
    {
        path: "/my-profile",
        name: "My Profile",
        active: false,
        // faIcon: <FontAwesomeIcon icon={faUser} size={"2x"}/>
        svgIcon: <div className="menu-svg-icon" id="profile-icon"></div>
    },
    {
        path: "/my-webpages",
        name: "Connected Accounts",
        active: true,
        // faIcon: <FontAwesomeIcon icon={faNetworkWired} size={"2x"}/>
        svgIcon: <div className="menu-svg-icon" id="connect-icon"></div>
    },
    {
        path: "/affiliates",
        name: "Affiliate Commission",
        active: false,
        // faIcon: <FontAwesomeIcon icon={faUserCheck} size={"2x"}/>
        svgIcon: <div className="menu-svg-icon" id="money-icon"></div>
    },
    {
        path: "/contact",
        name: "Contact Cards",
        active: false,
        // faIcon: <FontAwesomeIcon icon={faUserCheck} size={"2x"}/>
        svgIcon: <div className="menu-svg-icon" id="contact-icon"></div>
    },
    {
        path: "/register",
        name:"Register a Business",
        active: false,
        // faIcon:<FontAwesomeIcon icon={faBuilding} size={"2x"}/>
        svgIcon: <div className="menu-svg-icon" id="register-icon"></div>
    },
]
//*************NOT-IMPLEMENTED NAV OBJECTS****************
/* {
     path: "/wallet",
     name: "Wallet",
     faIcon: <FontAwesomeIcon icon={faWallet} size={"2x"}/>
 },
 {
     path: "/contacts",
     name: "Contacts",
     faIcon: <FontAwesomeIcon icon={faAddressBook} size={"2x"}/>
 },
 {
     path: "/polls",
         name: "Polls",
     faIcon: <FontAwesomeIcon icon={faChartBar} size={"2x"}/>
 },
 {
     path: "/web-pages",
     name: "My Web Pages",
     faIcon: <FontAwesomeIcon icon={faNetworkWired} size={"2x"}/>
 },
 {
     path: "/businesses",
     name: "My Businesses",
     faIcon: <FontAwesomeIcon icon={faBuilding} size={"2x"}/>
 },
*/
export default navRolesBase;
