import {LogoutAction} from "../../redux/user/userActions";
import {useDispatch, useSelector} from "react-redux";
import React from "react";
import Menu from "./menu";
// import navRolesBusiness from "./nav-roles/nav-roles-business";
// import navRolesAdmin from "./nav-roles/nav-roles-admin";
// import navRolesBase from "./nav-roles/nav-roles-base";
import Cookies from "universal-cookie/es6";
import Parse from 'parse';
// import MenuItem from './menu-item';
import ServerImg from "../common/server-img";

export default function Sidebar() {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.user);
    const navRoles = useSelector((state) => state.ui.navRoles)
    // const [role, setRole] = useState(navRolesBase);
    const createCookie = new Cookies();

    const dumpData = async () => {
        try {
            await Parse.Cloud.run('logout',{key: userData.sessionToken}, {});
            localStorage.clear();
            createCookie.remove('grapeIdOrg');
            createCookie.remove('grapeIdOrgSessionId');
            dispatch(LogoutAction());
            console.log("User Logged out");
        } catch (e) {
            console.log(e);
        }

    }
    // Extension fired event
    document.addEventListener('csEvent', async function (event) {
        console.log('csEvent',event)
        console.log(event.detail)
        if (event.detail === "logout") {
            await dumpData();
        }
    });


    return (
        <div className={"content"}>
            {/*GrapeID Logo*/}
            <div className={"mgs_logo-container"}>
                <div id="mgs_logo"/>
            </div>
            <div className={"side-bar-profile"}>
                <ServerImg className="side-bar-avatar" src={userData.selfie} cacheKey="selfie-img"
                            id="mgs_photoImg" alt={userData.name}/>
                <div className="side-bar-content">
                    <div className="side-bar-profile-name">{userData.name}</div>
                    <div className="side-bar-profile-property">Personal Account</div>
                </div>
                {/* <div className={"mgs_profile"}>
                    <div id="mgs_photo" className={"mx-auto d-block"}>
                        
                    </div>
                    <div id={"userRole"} className={""}>
                        {userData.name}
                        <select id={"mgs_userDropdown"} className={""} name={"roleOptions"} onChange={e => {
                            switch (e.target.value) {
                                case "companyRole":
                                    if (e.target.selectedOptions[0].dataset.admin) {
                                        setRole([...navRolesBusiness, ...navRolesAdmin]);
                                    } else {
                                        setRole(navRolesBusiness)
                                    }
                                    break;
                                default:
                                    setRole(navRolesBase);
                                    break;
                            }
                        }}>
                            <option className={"mgs_optionDropdown"} value={"userRole"}>Personal Account</option>
                            {userData.roles.map((companyRole, key) => {
                                return <option key={key} className={"mgs_optionDropdown"} value={"companyRole"}
                                               data-admin={(companyRole.roleName === "admin")}>
                                    {companyRole.orgName}
                                </option>
                            })}
                        </select>

                    </div>
                </div> */}
            </div>

            {/*Menu Navigation*/}
            <div id="mgs_menu">
                <Menu menu={navRoles}/>
            </div>
        </div>

    )
}

