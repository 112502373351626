import React from 'react';

const Role = () => {
  return (
    <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.68584 0.102189C5.16243 0.433826 3.87572 1.7162 3.51029 3.26716C3.08805 5.05879 3.90412 6.95894 5.49977 7.89963C7.90423 9.31707 11.0493 8.02262 11.7512 5.32656C12.5524 2.24889 9.81136 -0.578235 6.68584 0.102189ZM8.80339 1.97238C9.63179 2.37784 10.0859 3.08962 10.1447 4.07457C10.1977 4.96144 10.0235 5.44089 9.43401 6.03067C8.84453 6.62045 8.36532 6.79472 7.47889 6.74172C6.49909 6.68316 5.78293 6.22829 5.38305 5.41068C4.68444 3.98205 5.38411 2.30508 6.88876 1.8017C7.37858 1.63788 8.28517 1.71865 8.80339 1.97238ZM3.95873 10.3135C3.25824 10.4673 2.22538 10.988 1.69768 11.4535C1.10902 11.9727 0.499453 12.9167 0.231565 13.7238L0.00897789 14.3947L0.0044899 17.1574L0 19.9201L0.266581 20.1585L0.533165 20.397H7.59627C15.2666 20.397 14.9961 20.4129 15.2068 19.9501C15.3579 19.6185 15.3382 14.8945 15.1827 14.1602C14.9313 12.9733 14.0753 11.7244 13.043 11.039C12.7869 10.8689 12.2633 10.6172 11.8798 10.4797L11.1824 10.2298L7.83583 10.2134C5.19222 10.2005 4.37786 10.2215 3.95873 10.3135ZM10.906 11.9868C11.7957 12.1546 12.666 12.7738 13.1333 13.5717C13.5231 14.2372 13.5903 14.7089 13.5903 16.7801V18.6821H7.63177H1.67328L1.67466 16.865C1.67621 14.901 1.76274 14.2271 2.08653 13.6578C2.52126 12.8932 3.38141 12.2257 4.18172 12.0317C4.75888 11.8918 10.2089 11.8554 10.906 11.9868Z" fill="black"/>
    </svg>
  )
}

export default Role;