import React from 'react';

const Other = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.584 1H5.915C2.894 1 1 3.139 1 6.166V14.334C1 17.361 2.884 19.5 5.915 19.5H14.583C17.614 19.5 19.5 17.361 19.5 14.334V6.166C19.5 3.139 17.614 1 14.584 1Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.77002 11.4472C5.10902 11.4472 4.57202 10.9102 4.57202 10.2502C4.57202 9.59018 5.10902 9.05219 5.77002 9.05219C6.43102 9.05219 6.96802 9.59018 6.96802 10.2502C6.96802 10.9102 6.43102 11.4472 5.77002 11.4472Z" fill="#130F26"/>
      <path d="M10.2494 11.4472C9.58842 11.4472 9.05142 10.9102 9.05142 10.2502C9.05142 9.59018 9.58842 9.05219 10.2494 9.05219C10.9104 9.05219 11.4474 9.59018 11.4474 10.2502C11.4474 10.9102 10.9104 11.4472 10.2494 11.4472Z" fill="#130F26"/>
      <path d="M14.7284 11.4472C14.0674 11.4472 13.5304 10.9102 13.5304 10.2502C13.5304 9.59018 14.0674 9.05219 14.7284 9.05219C15.3894 9.05219 15.9264 9.59018 15.9264 10.2502C15.9264 10.9102 15.3894 11.4472 14.7284 11.4472Z" fill="#130F26"/>
    </svg>
  )
}

export default Other;