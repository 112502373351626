import React, {useEffect, useRef, useState} from "react";
import {FormControl, FormLabel, InputGroup} from "react-bootstrap";
import loadScript from "../load-script";

let autoComplete;

/**
 * <h3>Search Address Input</h3>
 * By use of the Google Maps API and its places library, the user can autocomplete their address details.
 * @returns {JSX.Element} The FormControl input
 * @constructor
 */
const SearchAddressInput = (props) => {
    const [query, setQuery] = useState('');
    const autoCompleteRef = useRef(null);
    const [addressObject, setAddressObject] = useState('');

    useEffect(() => {
        if (query) {
            if (addressObject && query === addressObject.formatted_address) {
                props.address(query, 'address', true);
            } else {
                props.address(query, 'address', false);
                setAddressObject('');
            }
        }
    }, [query])

    useEffect(() => {
        if (props.default) {
            setQuery(props.default);
        }
    }, [props.default])

    /**
     * <h4>Handle Place Select</h4>
     * This function handles the user's selected address suggestion by retrieving, storing, and formatting it.
     * @param updateQuery
     */
    function handlePlaceSelect(updateQuery) {
        // Retrieve and store the place from the google api
        const addressObject = autoComplete.getPlace();
        if (addressObject) {
            const query = addressObject.formatted_address;
            setAddressObject(addressObject);
            updateQuery(query);
            props.address(query, 'address', true);
            props.onChange(query);
        }
        // props.address(query, 'address', true);
        
    }

    /**
     * <h4>Handle Script Call</h4>
     * A callback function that takes the reference of an input, and a query string in
     * order to access the Google Maps Places AutoComplete API.
     * @param updateQuery The current query string details
     * @param autoCompleteRef The input element reference
     */
    function handleScriptCall(updateQuery, autoCompleteRef) {
        // Define the Google Maps places autoComplete to the address type
        autoComplete = new window.google.maps.places.Autocomplete(
            autoCompleteRef.current,
            {types: ["address"], strictBounds: false}
        );
        autoComplete.setFields(["formatted_address", "address_components", "geometry", "name", "icon"]);
        autoComplete.addListener("place_changed", (value) => {
            handlePlaceSelect(updateQuery);
        })
    }

    const handleBlur = () => {
        
    }

    // load the google api for the places library
    useEffect(() => {
        loadScript(
            (`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`),
            () => handleScriptCall(setQuery, autoCompleteRef)
        );
    }, []);


    return (
        <FormControl
            type={"text"}
            className={"fs-3"}
            name={"address"}
            ref={autoCompleteRef}
            placeholder={""}
            defaultValue={query}
            onChange={e => setQuery(e.target.value)}
            onKeyDown={e => {
                if (e.key === "Backspace" || e.key === "Delete") {
                    props.address(e.target.value, 'address', false);
                    setAddressObject('')
                }
            }}
            isInvalid={props.ignoreValidation ? false : !addressObject}
        />

    )
}

export default SearchAddressInput;
