import React, {useEffect, useMemo, useRef, useState} from "react";

const ServerImg = (props) => {
  // Required Props 'src', 'cacheKey'
  const [src, setSrc] = useState();

  useEffect(() => {
    if (props.src && props.cacheKey) {
      var request = new XMLHttpRequest();
      request.open('GET', props.src, true);
      request.responseType = 'blob';
      request.onload = function() {
          var reader = new FileReader();
          reader.readAsDataURL(request.response);
          reader.onload =  function(e){
              localStorage.setItem(props.cacheKey, e.target.result);
              setSrc(e.target.result);
          };
          reader.onerror = function(e) {
            setSrc(null);
          }
      };
      request.send();
      // const img = new Image()
    }
  }, [props]);

  const imageSrc = useMemo(() => {
    if (src) {
      return localStorage.getItem(props.cacheKey);
    } 
    const cached = localStorage.getItem(props.cacheKey);
    if (cached) return cached;
    else return props.src;
  }, [src, props.cacheKey, props.src]);
  
  return (
    <img
      alt=""
      {...props}
      src={imageSrc}
    />
  )
}

export default ServerImg