import {FormControl} from "react-bootstrap";
import React, {useEffect, useState} from "react";

const EmailInput = (props) => {
    const [email, setEmail] = useState('');
    const [valid, setValid] = useState(false);

    useEffect(() => {
        props.email(email, 'email', false);

        // Validate email
        if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
            props.email(email, 'email', true);
            setValid(true);
        } else {
            setValid(false);
        }
    }, [email])

    return (
        <FormControl
            type={"email"}
            className={"fs-3"}
            name={'email'}
            placeholder={"example@grapeid.com"}
            defaultValue={email}
            aria-valuemax={"25"}
            maxLength={"25"}
            onChange={e => setEmail(e.target.value)}
            onKeyDown={e => {
                if ((e.key === "Backspace" || e.key === "Delete") && (e.target.value === '')) {
                    setEmail('')
                }
            }}
            isInvalid={!valid}
        />
    )
}

export default EmailInput;
