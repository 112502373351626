import {Route} from 'react-router-dom';
import {connect, useSelector, useDispatch} from "react-redux";
import Sidebar from "../components/sidebar/sidebar";
import {SidebarUtil} from "../components/sidebar/sidebar-util";
import UserProfilePage from "../components/users/user-profile/user-profile-page";
import UserWalletPage from "../components/users/user-wallet/user-wallet-page";
import UserContactsPage from "../components/users/user-contacts/user-contacts-page";
import UserPollsPage from "../components/users/user-polls/user-polls-page";
import UserWebPagesPage from "../components/users/user-webpages/user-webpages-page";
import BusinessPage from "../components/businesses/business-page";
import RegisterOrganizationPage from "../components/businesses/business-register/register-organization-page";
import EmployeeManagementPage from "../components/businesses/business-employees/employee-management-page";
import CompanyProfile from "../components/businesses/company-profile/company-profile";
import Affiliate from "../components/businesses/affiliates/affiliates-page";
import UserAdminPage from "../components/users/user-admin/user-admin-page";
import ReviewOrganizationPage
    from "../components/users/user-admin/admin-review/review-organization/review-organization-page";
// import UserPage from "../components/users/user"
import {Switch} from "react-router";
import VerifyDashboardPage from "../components/users/user-admin/admin-verify/verify-profile/verify-dashboard-page";
import LoadingModal from '../utils/busy-spinner-modal';
import ImageModal from '../utils/image-modal';
import { Toast, ToastContainer } from 'react-bootstrap';
import { HideError } from "../redux/ui/ui-actions";
import BusinessApplication from '../components/businesses/business-applications/business-applications';

// TODO: Have business routes be a nested path from the company name

/*
TODO: When adding a menu item(route) back in:
   1. un-comment the desired route below
    2. In component/sidebar/nav-roles/*the route's role group file*
       -> un-comment the route's nav object
        -> add it into the main array of this file *the order of the objects designates which menu items show first*
*/

export const PrivateDashboard = () => {
    const UI = useSelector(state => state.ui);
    const dispatch = useDispatch();
    return (
        <main style={{display: "flex", width: '100%'}}>
            <SidebarUtil height={"100vh"}>
                <Sidebar/>
            </SidebarUtil>
            <div className={"mgs_main overflow-auto content-container-width"} style={{flex: 1}}>
                <Switch>
                    {/*Base Role Routes*/}
                    <Route exact path={"/my-profile"} component={UserProfilePage}/>
                    {/*<Route path={"/wallet"} component={UserWalletPage}/>*/}
                    {/*<Route path={"/contacts"} component={UserContactsPage}/>*/}
                    {/*<Route path={"/polls"} component={UserPollsPage}/>*/}
                    <Route path={"/my-webpages"} component={UserWebPagesPage}/>
                    <Route path={"/affiliates"} component={Affiliate}/>

                    {/*Public Routes*/}
                    {/*<Route path={"/reviews"} component={ReviewsPage}/>*/}
                    {/*<Route path={"/contact-card"} component={ContactCardPage}/>*/}

                    {/*Business Role Routes*/}
                    <Route path={"/businesses"} component={BusinessPage}/>
                    <Route path={"/register/business"} component={RegisterOrganizationPage}/>
                    <Route path={"/register"} component={BusinessApplication} />
                    <Route path={"/my-employees"} component={EmployeeManagementPage}/>
                    <Route path={"/comp-profile"} component={CompanyProfile}/>

                    {/*Business Admin Routes*/}
                    {/*<Route path={"/admin"} component={UserAdminPage}/>*/}
                    <Route path={"/verify-profiles"} component={VerifyDashboardPage}/>
                    <Route path={"/review-org"} component={ReviewOrganizationPage}/>
                </Switch>
                <LoadingModal />
                <ImageModal />
                <ToastContainer className="p-4" position={"middle-center"}>
                    <Toast
                        onClose={() => dispatch(HideError())}
                        show={UI.showError} delay={3000}
                        autohide
                        bg={"danger"}
                        className={"text-center m-4 border-1 w-100 toast-wrapper"}
                    >
                        <Toast.Body>
                            <strong className="me-auto fs-3" style={{color: "white"}}>
                                {UI.errorMessage}
                            </strong>
                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>
        </main>
    )
}

const mapStateToProps = (state) => ({
    hasUserData: !!state.user.user
});
export default connect(mapStateToProps)(PrivateDashboard);
