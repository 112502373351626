import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Parse from 'parse'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import configstore from './redux/rootReducer';
import AppRouter from "./routers/app-router";
import { Layout } from './layout';


// Setup Grape App namespace
window._GrapeApp = window._GrapeApp || {};

// TODO: Instantiate the Socket connection here

// TODO: set the _GrapeApp socket for use with chat and messaging
// window._GrapeApp.socket = [socket instance]

Parse.initialize('com.grapeid.GrapeID');
// Add header 
// Parse.CoreManager.set("REQUEST_HEADERS", {
//     "Accept":"*/*",
//     "Accept-Encoding":"gzip, deflate, br",
//     "Connection":"keep-alive",
//     "X-Parse-Application-Id":"com.grapeid.GrapeID",
//     "Content-Type":"application/json",
//     "X-Parse-Session-Token":"r:4a20e23cdbf053661aa3b4f96455b4d2"
// });

Parse.CoreManager.set("REQUEST_HEADERS", {
    // "Accept":"*/*",
    // "Accept-Encoding":"gzip, deflate, br",
    // "Connection":"keep-alive",
    "X-Parse-Application-Id": "com.grapeid.GrapeID",
    "Content-Type": "application/json"
});

// ui_getDashboard - endpoint for refresh stuff - photo

if (process.env.REACT_APP_ENVIRONMENT === "production") {
    Parse.serverURL = `${process.env.REACT_APP_PROD_SERVER_URL}/parse`;
    console.log("******************************** production ********************************")
} else if (process.env.REACT_APP_ENVIRONMENT === "development") {
    console.log("******************************** development ********************************")
    Parse.serverURL = `${process.env.REACT_APP_DEV_SERVER_URL}/parse`;
}


const {store, persistor} = configstore();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter id={"appContent"}>
                    <Layout>
                        <AppRouter/>
                    </Layout>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
