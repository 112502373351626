import React from "react";
import MenuItem from './menu-item';

/**
 * Menu returns a list of functional NavLinks
 * @param props - The array of Nav Objects
 * @returns {JSX.Element}
 * @constructor
 */
const Menu = (props) => {
return (
    <div className="mgs_menu">
        {props.menu.map((menuItem, index) => (
            <MenuItem
                {...menuItem}
                key={index}
            />
        ))}
    </div>
)
}
export default Menu;
