import React from "react";

const navRolesAdmin =  [
    {
        path: "/verify-profiles",
        name: "Verify Users",
        // faIcon: <FontAwesomeIcon icon={faUserCheck} size={"2x"}/>
        svgIcon: <div className="menu-svg-icon" id="profile-icon"></div>
    },
    {
        path: "/my-employees",
        name: "My Employees",
        svgIcon: <div className="menu-svg-icon" id="connect-icon"></div>
    },
]

//*************NOT-IMPLEMENTED NAV OBJECTS****************
/*
    {
        path: "/admin",
        name: "Admin",
        faIcon: <FontAwesomeIcon icon={faUserCog} size={"2x"}/>
    },
    {
        path: "/my-employees",
        name: "My Employees",
        faIcon: <FontAwesomeIcon icon={faIdBadge} size={"2x"}/>
    },
    {
        path: "/review-cred",
        name: "Review Credentials",
        faIcon: <FontAwesomeIcon icon={faCreditCard} size={"2x"}/>
    },
        {
        path: "/review-org",
        name: "Review Organization Applications",
        faIcon: <FontAwesomeIcon icon={faFileAlt} size={"2x"}/>
    },
 */
export default navRolesAdmin;
